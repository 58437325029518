import {MenuProps} from "antd";
import React from "react";

type MenuItem = Required<MenuProps>['items'][number];

export default function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
    onClick?: (e: React.MouseEvent) => void,
    className?: string
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
        onClick,
        className,
    } as MenuItem;
}

export function getNavItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
    onClick?: (e: React.MouseEvent) => void,
    popupOffset?: [number, number],
    popupClassName?: string,
    className?: string
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
        popupOffset,
        onClick,
        popupClassName,
        className,
    } as MenuItem;
}

export const NavMap: any = {
    '/': 'Aktualności',
    '/admin': 'Administracja',
    '/admin/': 'Administracja',
    '/admin/news': 'Administracja - Aktualności',
    '/admin/news/new': 'Administracja - Aktualności - Dodaj',
    '/admin/news/edit': 'Administracja - Aktualności - Edytuj',
    '/admin/users': 'Administracja - Użytkownicy',
    '/admin/users/new': 'Administracja - Użytkownicy - Dodaj',
    '/admin/users/edit': 'Administracja - Użytkownicy - Edytuj',
    '/admin/signalist': 'Administracja - Zgłoszenia',
    '/admin/signalist/config': 'Administracja - Konfiguracja',
    '/admin/signalist/new': 'Administracja - Zgłoszenia - Dodaj',
    '/admin/software': 'Administracja - Oprogramowanie',
    '/admin/software/new': 'Administracja - Oprogramowanie - Dodaj',
    '/admin/software/edit': 'Administracja - Oprogramowanie - Edytuj',
    '/admin/training': 'Administracja - Poradniki',
    '/admin/training/new': 'Administracja - Poradniki - Dodaj',
    '/admin/training/edit': 'Administracja - Poradniki - Edytuj',
    '/admin/info': 'Administracja - Informacje',
    '/admin/info/new': 'Administracja - Informacje - Dodaj',
    '/admin/info/edit': 'Administracja - Informacje - Edytuj',
    '/admin/aiassistant': 'Administracja - Asystent AI',
    '/admin/aiassistant/config': 'Administracja - Asystent AI - Konfiguracja',
    '/news/new': 'Aktualności - Dodaj',
    '/information': 'Informacje',
    '/aiassistant': 'Asystent AI',
    '/manager': 'Panel menadżera',
    '/manager/': 'Panel menadżera',
    '/manager/holidays': 'Panel menadżera - Urlopy',
    '/modules': 'Moduły',
    '/modules/holiday': 'Urlop',
    '/modules/software': 'Oprogramowanie',
    '/modules/delegations': 'Delegacje',
    '/modules/in-development/signalizer': 'Sygnalizer - W budowie',
    '/modules/in-development/kanban': 'Kanban - W budowie',
    '/modules/in-development/geotool': 'Geotool - W budowie',
    '/calendar': 'Kalendarz',
    '/modules/in-development/delegations': 'Delegacje - W budowie',
    '/modules/in-development/orders': 'Zamówienia - W budowie',
    '/modules/in-development/tasks': 'Zadania - W budowie',
    '/training/new': 'Poradniki - Dodaj',
    '/training/view': 'Poradnik',
    '/training': 'Poradniki',
    '/profile': 'Ustawienia użytkownika',
};
