import {BACKEND_BASE, GetToken} from "./LoginService";
import {ErrorMessages} from "../extensions/ErrorMessages";
import {HolidayUser, RedactedUser, User} from "../types/AuthState";
import {HolidayStatus} from "../extensions/DateFormatter";
import dayjs from "dayjs";

const ManagerService = {
    getDirectReports: async (): Promise<User[]> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Manager/users`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + GetToken()
                },
            });

            if (response.status === 401) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 403) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status >= 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return await response.json();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    getHolidayRequests: async (status: number, upcomingOnly: boolean): Promise<HolidayUser[]> => {
        try {
            let url = `${BACKEND_BASE}/Manager/holiday-requests?upcomingOnly=${upcomingOnly}`;

            if (status !== -1) {
                url += `&status=${status}`;
            }

            const response = await fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + GetToken()
                },
            });

            if (response.status === 401) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 403) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status >= 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return await response.json();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    getHolidayByPeriod: async (from: dayjs.Dayjs, to: dayjs.Dayjs): Promise<HolidayUser[]> => {
        try {
            let url = `${BACKEND_BASE}/Manager/holiday-requests/period?from=${from.toISOString()}&to=${to.toISOString()}`;

            const response = await fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + GetToken()
                },
            });

            if (response.status === 401) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 403) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status >= 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return await response.json();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    updateHolidayStatus: async (id: string, status: HolidayStatus): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Manager/holiday-requests/${id}?status=${status}`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 403) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            if (response.status >= 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    downloadForm: (id: string): void => {
        fetch(`${BACKEND_BASE}/Manager/holiday-request/PDF/${id}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': BACKEND_BASE,
                'Access-Control-Allow-Credentials': 'true',
                'Authorization': 'Bearer ' + GetToken()
            }
        })
            .then(response => response.blob())
            .then(result => {
                const file = window.URL.createObjectURL(result);
                window.open(file, '_blank');
            })
            .catch(error => console.log('error', error));
    }
}

export default ManagerService;
