import RetoricCard from "../RetoricCard/RetoricCard";
import React from "react";

export interface RetoricModuleCardProps {
    title: string,
    description: string,
    icon: JSX.Element,
    onClick?: () => void
}

export default function RetoricModuleCard({title, description, icon, onClick}: RetoricModuleCardProps) {

    return (<RetoricCard
        bordered
        hoverable
        onClick={onClick}
        className="bg-offwhite drop-shadow-sm lg:p-8 p-2"
    >
        {icon}
        <h3 className="text-center text-xl">{title}</h3>
        <span className="text-md text-center block">{description}</span>
    </RetoricCard>)
}
