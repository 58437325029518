import React, {useEffect, useState} from 'react';
import './NewsRedaction.css';
import {Empty, Pagination} from "antd";
import NewsCard, {NewsCardProps} from "../../../../components/NewsCard/NewsCard";
import {NewsService} from "../../../../services/NewsService";
import {useAuth} from "../../../../extensions/Auth";
import {AdminScopes} from "../../../../types/Scopes";
import RetoricBreadcrumbs from "../../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../../extensions/Locations";
import BlankNewsCard from "../../../../components/BlankNewsCard/BlankNewsCards";
import RetoricButton from "../../../../designsystems/RetoricButton/RetoricButton";
import NewsCategoriesModal from "../NewsCategoriesModal/NewsCategoriesModal";
import {EntryCategory} from "../../Info/InfoCategoriesModal/InfoCategoriesModal";

function NewsRedaction() {
    const auth = useAuth();
    const [page, setPage] = useState(1);
    const [data, setData] = useState([] as NewsCardProps[]);
    const [newsData, setNewsData] = useState([] as NewsCardProps[]);
    const [loading, setLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [categories, setCategories] = useState([] as EntryCategory[]);

    const filter = (p = 1, input = data) => setNewsData(input.filter((_, index) => index >= (p - 1) * 10 && index < p * 10));

    const refreshData = () => {
        setLoading(true);
        NewsService.getPendingNews().then((r) => {
            setData(r);
            filter(page, r);
            setLoading(false);
        }).catch((e) => {
            setLoading(false);
            auth.handleLogout();
        });

        NewsService.getCategories().then((r) => {
            setCategories(r);
        });
    }

    useEffect(() => {
        auth.verifyAuth(AdminScopes.NEWS);

        refreshData();
    }, []);

    const updatePagination = (page: number) => {
        setPage(page);
        filter(page);
    }

    return (
        <div className="content">
            <RetoricBreadcrumbs locations={Locations['admin/news']}>Zarządzaj aktualnościami</RetoricBreadcrumbs>
            <div className="flex flex-row lg:justify-start justify-center lg:self-start self-stretch">
                <RetoricButton type={'primary'} outline onClick={() => setModalOpen(true)}
                               className="mr-4 min-w-[150px]">Zarządzaj kategoriami</RetoricButton>
                <RetoricButton type={'primary'} outline onClick={() => auth.navigate('/admin/news/new')}
                               className="mr-4 min-w-[150px] lg:hidden">Dodaj aktualność</RetoricButton>
            </div>
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 self-stretch">
                {!loading && newsData.map((news, index) => <NewsCard
                    key={`news-redaction-${news.id}`} {...news}
                    categories={categories}
                    categoryName={categories.find((x) => x.id === news.categoryId)?.name} isRedact
                    withApproval={true}
                    refresh={() => refreshData()}/>)}
                {!loading && newsData.length === 0 &&
                    <Empty className="lg:col-span-2" description="Brak wyników"/>}
                {loading && [null, null, null, null].map((item, index) => <BlankNewsCard
                    key={`placeholder-news-${index}`}/>)}
            </div>
            <Pagination defaultCurrent={1} total={data.length} onChange={updatePagination}
                        showSizeChanger={false}/>
            <NewsCategoriesModal open={modalOpen} setOpen={setModalOpen}
                                     refresh={() => refreshData()}/>
        </div>
    );
}

export default NewsRedaction;
