import React, {useEffect, useState} from 'react';
import './TrainingList.css';
import {useAuth} from "../../../../extensions/Auth";
import NewsCard, {NewsCardProps} from "../../../../components/NewsCard/NewsCard";
import {Empty, Input, Menu, MenuProps, Pagination} from "antd";
import {TrainingService} from "../../../../services/TrainingService";
import BlankNewsCard from "../../../../components/BlankNewsCard/BlankNewsCards";
import RetoricButton from "../../../../designsystems/RetoricButton/RetoricButton";
import RetoricBreadcrumbs from "../../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../../extensions/Locations";
import getItem from "../../../../extensions/Menu";
import {EntryCategory} from "../../../AdminView/Training/TrainingCategoriesModal/TrainingCategoriesModal";

type MenuItem = Required<MenuProps>['items'][number];

function TrainingList() {
    const auth = useAuth();
    const [page, setPage] = useState(1);
    const [data, setData] = useState([] as NewsCardProps[]);
    const [trainingData, setTrainingData] = useState([] as NewsCardProps[]);
    const [loading, setLoading] = useState(true);
    const [searchFilter, setSearchFilter] = useState('' as string);
    const [total, setTotal] = useState(0);
    const [categoryMenuItems, setCategoryMenuItems] = useState([] as MenuItem[]);
    const [categories, setCategories] = useState([] as EntryCategory[]);
    const [category, setCategory] = useState('all' as string);

    let searchTimeout = setTimeout(() => {
    }, 1000);

    const refreshCategories = (c = categories) => {
        const items = [] as MenuItem[];

        items.push(getItem(`Wszystkie poradniki`, `category-all`, null, undefined, undefined,
            () => null))

        items.push(...c.filter((x) => x.entries && x.entries.length > 0).map((category) => {
            return getItem(`${category.name}`, `category-${category.id}`, null, undefined, undefined,
                () => null)
        }))

        items.push(getItem(`Pozostałe`, `category-others`, null, undefined, undefined,
            () => null))

        const cat = getItem('Kategorie', `grp-0`, null, items, 'group');

        setCategoryMenuItems([cat]);
    }

    const filter = (p = 1, f = '', input = data, c = category, cats = categories) => {
        const search = input.filter((news) => news.title.toLowerCase().includes(f.toLowerCase()) || news.description.toLowerCase().includes(f.toLowerCase()) || news.shortDescription?.toLowerCase().includes(f.toLowerCase()));
        const categoryResult = search.filter((x) => {
            if (c === 'all')
                return true;
            else if (c === 'others')
                return x.categoryId === null;
            else
                return x.categoryId === c;
        });

        const pagination = categoryResult.filter((_, index) => index >= (p - 1) * 6 && index < p * 6);

        setTrainingData(pagination);
        setTotal(categoryResult.length);
        refreshCategories(cats);
    }

    useEffect(() => {
        setLoading(true);

        TrainingService.getCategories().then((c) => {
            setCategories(c);

            TrainingService.getTrainingCourses().then((r) => {
                setData(r);
                filter(page, searchFilter, r, category, c);
                setLoading(false);
            }).catch((e) => {
                setLoading(false);
                auth.handleLogout();
            });
        });

        return () => {
        };
    }, []);

    const updatePagination = (page: number) => {
        setPage(page);
        filter(page);
    }

    const filterBySearch = (event: any) => {
        clearTimeout(searchTimeout);
        setTimeout(() => {
            setSearchFilter(event.target.value);
            filter(1, event.target.value, data);
        }, 200);
    }

    const selectCategory = (selectedCategory: { key: string }) => {
        const key = selectedCategory.key.replaceAll('category-', '');

        setCategory(key);
        setPage(1);
        filter(1, searchFilter, data, key);
    }

    return (
        <div className="content">
            <RetoricBreadcrumbs locations={Locations['training']}>Poradniki</RetoricBreadcrumbs>
            <div className="flex flex-row justify-stretch gap-4 self-stretch">
                <div className={'hidden lg:block'}>
                    <Menu
                        style={{width: 300}}
                        className="side-menu"
                        defaultSelectedKeys={['category-all']}
                        onSelect={selectCategory}
                        defaultOpenKeys={['sub1']}
                        mode="inline"
                        items={categoryMenuItems}
                    />
                </div>
                <div className="flex flex-col justify-stretch gap-4 self-stretch flex-1">
                    <div className="flex flex-row justify-start lg:self-start self-stretch">
                        <RetoricButton type={'primary'} outline onClick={() => auth.navigate('/training/new')}
                                       className="mr-4 min-w-[150px]">Dodaj</RetoricButton>
                        <Input.Search placeholder={'Wpisz frazę do wyszukania'} allowClear size={'large'}
                                      onChange={filterBySearch} className="lg:min-w-[500px] search"/>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 self-stretch">
                        {!loading && trainingData.map((news, index) => <NewsCard key={`training-${news.id}`} {...news}
                                                                                 className={'training__card bg-offwhite'}
                                                                                 isTraining
                                                                                 onClick={() => auth.navigate(`/training/view/${news.id}`)}/>)}
                        {!loading && trainingData.length === 0 && <Empty className="col-span-2"
                                                                         description="Brak wyników spełniających kryteria wyszukiwania"/>}
                        {loading && [null, null, null, null].map((item, index) => <BlankNewsCard
                            key={`placeholder-training-${index}`}/>)}
                    </div>
                    <Pagination defaultCurrent={1} current={page} total={total} pageSize={6} onChange={updatePagination}
                                showSizeChanger={false}
                                className="mx-auto"/>
                </div>
            </div>
        </div>
    );
}

export default TrainingList;
