import React, {useEffect, useState} from 'react';
import './InfoEdit.css';
import {useAuth} from "../../../../extensions/Auth";
import {Form, Input, notification, Radio, Select} from "antd";
import {AdminScopes} from "../../../../types/Scopes";
import RetoricButton from "../../../../designsystems/RetoricButton/RetoricButton";
import RetoricBreadcrumbs from "../../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../../extensions/Locations";
import {InfopageService} from "../../../../services/InfopageService";
import {Infopage, InfopageCategory, InfopageType} from "../../../../types/InfopageModels";
import {InfopageTypeMapper} from "../InfopageList/InfopageList";
import RetoricCard from "../../../../designsystems/RetoricCard/RetoricCard";
import Infobox from "../../../../designsystems/Infobox/Infobox";
import {ErrorMessages} from "../../../../extensions/ErrorMessages";
import {useParams} from "react-router-dom";
import BasicInfopageEdit from "./BasicInfopageEdit";
import TrainingInfopageEdit from "./TrainingInfopageEdit";
import ChangelogInfopageEdit from "./ChangelogInfopageEdit";
import PhoneNumbersInfopageEdit from "./PhoneNumbersInfopageEdit";

const InfopageEditorMapper = (type: InfopageType, infopage: Infopage) => {
    switch (type) {
        case InfopageType.Basic:
            return <BasicInfopageEdit infopage={infopage}/>;
        case InfopageType.Training:
            return <TrainingInfopageEdit infopage={infopage}/>;
        case InfopageType.Changelog:
            return <ChangelogInfopageEdit infopage={infopage}/>;
        case InfopageType.PhoneExtensions:
            return <PhoneNumbersInfopageEdit infopage={infopage}/>;
    }
}

export interface InfopageEditProps {
    infopage: Infopage;
}

function InfoEdit() {
    const auth = useAuth();
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm();
    const [categories, setCategories] = useState([] as InfopageCategory[]);
    const [locations, setLocations] = useState([] as { title: string, path: string }[]);
    const [infopage, setInfopage] = useState({} as Infopage);
    const {id} = useParams();

    useEffect(() => {
        auth.verifyAuth(AdminScopes.INFOPAGE);

        if (!id)
            auth.navigate('/info/manage');

        InfopageService.getInfopageCategories()
            .then((r) => {
                setCategories(r);
            })
            .finally(() => {
                InfopageService.getInfopageById(id!)
                    .then((r) => {
                        form.setFieldsValue({
                            title: r.title,
                            isHidden: r.isHidden,
                            categoryId: r.categoryId,
                            type: r.type
                        })

                        setInfopage(r);
                        setLocations([
                            ...Locations['admin/info/new'],
                            {
                                title: `Edytuj (${r.title})`,
                                path: `/admin/info/edit/${id}`
                            }
                        ])

                    })
                    .catch((e) => {
                        auth.navigate('/info/manage');
                    });
            });

        return () => {
        };
    }, [auth, id, form]);

    const publish = () => {
        const formValues = form.getFieldsValue();

        if (!formValues.title) {
            api.error({
                message: `Uzupełnij wymagane pola`,
                description: 'Tytuł nie może być pusty!',
                placement: 'top'
            });
            return;
        }

        if (formValues.title.length > 50) {
            api.error({
                message: `Zbyt długi tytuł`,
                description: 'Tytuł musi mieć maksymalnie 50 znaków!',
                placement: 'top'
            });
            return;
        }

        const payload: Infopage = {
            createdAt: infopage.createdAt,
            updatedAt: infopage.updatedAt,
            id: infopage.id,
            order: infopage.order,
            title: formValues.title,
            categoryId: formValues.categoryId.length === 0 ? undefined : formValues.categoryId,
            type: formValues.type,
            isHidden: formValues.isHidden
        };

        InfopageService.updateInfopage(payload)
            .then((x) => {
                api.info({
                    message: `Zaktualizowano stronę`,
                    description: 'Strona została zaktualizowana!',
                    placement: 'top'
                });
            })
            .catch((e) => {
                let error = "Twoje uprawnienia nie pozwalają na zaktualizowanie strony!"

                if (e.message === ErrorMessages.validationFailed) {
                    error = 'Nazwa strony musi mieć co najmniej 3 znaki!'
                }

                if (e.message === ErrorMessages.notFound) {
                    error = 'Nie znaleziono strony!'
                }

                api.error({
                    message: `Wystąpił błąd`,
                    description: error,
                    placement: 'top'
                });
            });
    }

    return (
        <div className="content">
            <RetoricBreadcrumbs locations={locations}>Edytuj stronę informacyjną</RetoricBreadcrumbs>
            {contextHolder}
            <Infobox title={"Strony informacyjne"} className="mb-4 2xl:hidden min-w-fit" rows={[
                'Strona informacyjna będzie wyświetlana w sekcji "Informacje" w momencie, gdy zostanie dodana do jednej z kategorii, a jej status będzie ustawiony jako widoczny.',
            ]}/>
            <div className="grid lg:grid-cols-4 grid-cols-1 gap-4 min-w-full">
                <Form
                    layout={'vertical'}
                    form={form}
                    className={"min-w-full"}
                >
                    <RetoricCard className="bg-offwhite">
                        <h4 className="lg:text-2xl text-xl text-slate-600 text-center lg:text-left tracking-wide self-stretch mb-4">Konfiguracja
                            strony</h4>
                        <Infobox title={"Strony informacyjne"} className="mb-4 hidden 2xl:block" rows={[
                            'Strona informacyjna będzie wyświetlana w sekcji "Informacje" w momencie, gdy zostanie dodana do jednej z kategorii, a jej status będzie ustawiony jako widoczny.',
                        ]}/>
                        <Form.Item label="Tytuł" name="title"
                                   rules={[{required: true, message: "Tytuł jest wymagany"}]}>
                            <Input size="large" placeholder="Tytuł strony informacyjnej"
                                   className="bg-offwhite rounded-none"/>
                        </Form.Item>
                        <Form.Item label="Szablon" name="type">
                            <Select style={{width: '100%'}} disabled>
                                <Select.Option
                                    value={InfopageType.Basic}>{InfopageTypeMapper(InfopageType.Basic)}</Select.Option>
                                <Select.Option
                                    value={InfopageType.PhoneExtensions}>{InfopageTypeMapper(InfopageType.PhoneExtensions)}</Select.Option>
                                <Select.Option
                                    value={InfopageType.Training}>{InfopageTypeMapper(InfopageType.Training)}</Select.Option>
                                <Select.Option
                                    value={InfopageType.Changelog}>{InfopageTypeMapper(InfopageType.Changelog)}</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label="Kategoria" name="categoryId">
                            <Select style={{width: '100%'}} allowClear>
                                <Select.Option value={''}>Brak kategorii</Select.Option>
                                {categories.map((category) => <Select.Option value={category.id}
                                                                             key={category.id}>{category.title}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Czy strona powinna być widoczna?" name="isHidden">
                            <Radio.Group>
                                <Radio value={true}>Nie</Radio>
                                <Radio value={false}>Tak</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <RetoricButton type={'primary'} onClick={publish} className="min-w-[200px]">Zapisz
                            stronę</RetoricButton>

                    </RetoricCard>
                </Form>
                <div className="lg:col-span-3 col-span-1">
                    {InfopageEditorMapper(infopage.type, infopage)}
                </div>
            </div>
        </div>
    );
}

export default InfoEdit;
