import React, {useEffect, useState} from "react";
import {InfopageProps} from "../PhoneNumbers/PhoneNumbersInfopage";
import {Infopage, InfopageBasic} from "../../../../../types/InfopageModels";
import {InfopageService} from "../../../../../services/InfopageService";
import {Empty, Skeleton} from "antd";
import {BasicMock} from "../TemplateMock";
import './BasicInfopage.css';
import AttachmentsDisplay from "../../../../../components/AttachmentsDisplay/AttachmentsDisplay";

const BasicInfopage = ({infopage, isTemplateView}: InfopageProps) => {
    const [page, setPage] = useState({} as InfopageBasic);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!isTemplateView) {
            setLoading(true);

            InfopageService.getInfopageById(infopage.id)
                .then((x: Infopage) => {
                    setPage(x as InfopageBasic)
                })
                .finally(() => setLoading(false));
        } else {
            setPage(BasicMock)
        }

        return () => {
        };
    }, [infopage, isTemplateView]);

    return (
        <div className="self-stretch flex flex-col justify-stretch">
            <h4 className="lg:text-2xl text-xl text-slate-600 text-center lg:text-left tracking-wide self-stretch mb-4">{page.title}</h4>
            {(!loading && page.htmlContent) &&
                <div className={'infopage__selector flex flex-col'}>
                    <div className="self-stretch mb-4" dangerouslySetInnerHTML={{__html: page.htmlContent}}></div>
                    {page.attachments && page.attachments.length > 0 &&
                        <AttachmentsDisplay attachments={page.attachments}/>}
                </div>}
            {(!loading && !page.htmlContent) && <Empty description="Ta strona nie została jeszcze skonfigurowana"/>}
            {loading && <Skeleton active/>}
        </div>
    );
};

export default BasicInfopage;
