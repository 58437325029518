export interface ClickableElementProps {
    action: () => void;
    children: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
}

export default function ClickableElement({action, children, className, style}: ClickableElementProps) {
    return (
        <button className={className || ""}
                style={style || {}}
                onClick={() => action()}
                onKeyDown={(e) => {if (e.key === 'Enter') action();}}>
            {children}
        </button>
    )
}
