import {BACKEND_BASE, GetToken, Logout} from "./LoginService";
import {
    Infopage,
    InfopageCategory,
    InfopageCategoryRequest,
    InfopageChangelogEntry,
    InfopageRequest,
    InfopageTrainingEntry,
    PhoneExtension,
    PhoneExtensionEntry,
    PhoneExtensionEntryRequest,
    PhoneExtensionRequest
} from "../types/InfopageModels";
import {SuccessObject} from "../types/AuthState";
import {ErrorMessages} from "../extensions/ErrorMessages";

export const InfopageService = {
    getInfopages: async (): Promise<Infopage[]> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Infopage`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                Logout();
                return [];
            }

            return await response.json();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    getAllInfopages: async (): Promise<Infopage[]> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Infopage/all`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                Logout();
                return [];
            }

            return await response.json();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    getInfopageById: async (id: string): Promise<Infopage> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Infopage/${id}`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            return await response.json();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    deleteInfopage: async (id: string): Promise<SuccessObject> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Infopage/${id}`, {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 403) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            return {success: true};
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    updateInfopage: async (infopage: Infopage): Promise<SuccessObject> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Infopage`, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(infopage)
            });

            if (response.status === 403) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            if (response.status === 422) {
                return Promise.reject(new Error(ErrorMessages.validationFailed));
            }

            if (response.status === 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return {success: true};
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    addInfopage: async (infopage: InfopageRequest): Promise<Infopage> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Infopage`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(infopage)
            });

            if (response.status === 403) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 422) {
                return Promise.reject(new Error(ErrorMessages.validationFailed));
            }

            if (response.status === 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            return await response.json();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    getInfopageCategories: async (): Promise<InfopageCategory[]> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Infopage/categories`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                Logout();
                return [];
            }

            return await response.json();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    addInfopageCategory: async (category: InfopageCategoryRequest): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Infopage/category`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(category)
            });

            if (response.status === 403) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            if (response.status === 422) {
                const r = await response.json();
                return Promise.reject(new Error(r.message));
            }

            return await response.json();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    updateInfopageCategory: async (id: string, category: InfopageCategoryRequest): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Infopage/category/${id}`, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(category)
            });

            if (response.status === 403) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            if (response.status === 422) {
                const r = await response.json();
                return Promise.reject(new Error(r.message));
            }

            return Promise.resolve();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    deleteInfopageCategory: async (id: string): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Infopage/category/${id}`, {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 403) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            return Promise.resolve();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    setBasicInfopageContent: async (id: string, request: { htmlContent: string }): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Infopage/basic/${id}`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(request)
            });

            if (response.status === 403) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            return Promise.resolve();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    deleteTrainingEntry: async (pageId: string, entryId: string): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Infopage/training/${pageId}/${entryId}`, {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 403) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            return Promise.resolve();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    updateTrainingEntry: async (pageId: string, entryId: string, entry: InfopageTrainingEntry): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Infopage/training/${pageId}/${entryId}`, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(entry)
            });

            if (response.status === 403) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            return Promise.resolve();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    addTrainingEntry: async (pageId: string, entry: InfopageTrainingEntry): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Infopage/training/${pageId}`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(entry)
            });

            if (response.status === 403) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 422) {
                return Promise.reject(new Error(ErrorMessages.validationFailed));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            return Promise.resolve();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    deleteChangelogEntry: async (pageId: string, entryId: string): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Infopage/changelog/${pageId}/${entryId}`, {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 403) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            return Promise.resolve();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    updateChangelogEntry: async (pageId: string, entryId: string, entry: InfopageChangelogEntry): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Infopage/changelog/${pageId}/${entryId}`, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(entry)
            });

            if (response.status === 403) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            return Promise.resolve();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    addChangelogEntry: async (pageId: string, entry: InfopageChangelogEntry): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Infopage/changelog/${pageId}`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(entry)
            });

            if (response.status === 403) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 422) {
                return Promise.reject(new Error(ErrorMessages.validationFailed));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            return Promise.resolve();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    updatePhoneExtensionEntry: async (pageId: string, entryId: string, entry: PhoneExtensionEntry): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Infopage/phoneextension/${pageId}/${entryId}`, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(entry)
            });

            if (response.status === 403) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 422) {
                const r = await response.json();
                return Promise.reject(new Error(r.message));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            return Promise.resolve();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    deletePhoneExtensionEntry: async (pageId: string, entryId: string): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Infopage/phoneextension/${pageId}/${entryId}`, {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 403) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            return Promise.resolve();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    addPhoneExtensionEntry: async (pageId: string, entry: PhoneExtensionEntryRequest): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Infopage/phoneextension/${pageId}`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(entry)
            });

            if (response.status === 403) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 422) {
                const r = await response.json();
                return Promise.reject(new Error(r.message));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            return Promise.resolve();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    updatePhoneExtension: async (pageId: string, entryId: string, extensionId: string, extension: PhoneExtension): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Infopage/phoneextension/${pageId}/${entryId}/${extensionId}`, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(extension)
            });

            if (response.status === 403) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 422) {
                const r = await response.json();
                return Promise.reject(new Error(r.message));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            return Promise.resolve();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    deletePhoneExtension: async (pageId: string, entryId: string, extensionId: string): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Infopage/phoneextension/${pageId}/${entryId}/${extensionId}`, {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 403) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            return Promise.resolve();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    addPhoneExtension: async (pageId: string, entryId: string, extension: PhoneExtensionRequest): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Infopage/phoneextension/${pageId}/${entryId}`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(extension)
            });

            if (response.status === 403) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 422) {
                const r = await response.json();
                return Promise.reject(new Error(r.message));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            return Promise.resolve();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
}
