import {BACKEND_BASE, GetToken, Logout} from "./LoginService";
import {ErrorMessages} from "../extensions/ErrorMessages";
import {AiChat, AiChatRedacted, AiCommand, AiPrePromptRedacted, TokenUsageWithPrice} from "../types/AiChat";

export const AiChatService = {
    getChat: async (id: string): Promise<AiChat> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Chatbot/${id}`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            return await response.json();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    getChats: async (): Promise<AiChatRedacted[]> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Chatbot`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            return await response.json();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    getPerUserUsage: async (from?: Date, to?: Date): Promise<any> => {
        try {
            let url = `${BACKEND_BASE}/Chatbot/admin/usage/detailed`;

            if (from && to)
                url += `/${from.toISOString()}/${to.toISOString()}`;
            else if (from)
                url += `/${from.toISOString()}`;

            const response = await fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            return await response.json();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    getTotalUsage: async (from?: Date, to?: Date): Promise<TokenUsageWithPrice> => {
        try {
            let url = `${BACKEND_BASE}/Chatbot/admin/usage`;

            if (from && to)
                url += `/${from.toISOString()}/${to.toISOString()}`;
            else if (from)
                url += `/${from.toISOString()}`;

            const response = await fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            return await response.json();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    getAiCommands: async (): Promise<AiCommand[]> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Chatbot/commands`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            if (response.status === 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return await response.json();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    getPrePrompts: async (): Promise<AiPrePromptRedacted[]> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Chatbot/preprompts`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            if (response.status === 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return await response.json();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    addAiCommand: async (command: string, instruction: string): Promise<AiCommand> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Chatbot/commands`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify({command, instruction})
            });

            if (response.status === 401) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            if (response.status === 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            if (response.status === 422) {
                return Promise.reject(await response.json());
            }

            return await response.json();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    updateAiCommand: async (command: string, instruction: string, commandId: string): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Chatbot/commands/${commandId}`, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify({command, instruction})
            });

            if (response.status === 401) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            if (response.status === 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            if (response.status === 422) {
                return Promise.reject(await response.json());
            }

            return Promise.resolve();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    },
    deleteAiCommand: async (commandId: string): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Chatbot/commands/${commandId}`, {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status === 401) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            if (response.status === 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return Promise.resolve();
        } catch (e) {
            return Promise.reject(new Error(ErrorMessages.forbidden));
        }
    }
}
