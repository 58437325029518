import React, {useEffect, useState} from 'react';
import './TrainingRedaction.css';
import {Empty, Pagination} from "antd";
import NewsCard, {NewsCardProps} from "../../../../components/NewsCard/NewsCard";
import {useAuth} from "../../../../extensions/Auth";
import {AdminScopes} from "../../../../types/Scopes";
import {TrainingService} from "../../../../services/TrainingService";
import BlankNewsCard from "../../../../components/BlankNewsCard/BlankNewsCards";
import RetoricBreadcrumbs from "../../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../../extensions/Locations";
import RetoricButton from "../../../../designsystems/RetoricButton/RetoricButton";
import TrainingCategoriesModal, {EntryCategory} from "../TrainingCategoriesModal/TrainingCategoriesModal";

function TrainingRedaction() {
    const auth = useAuth();
    const [page, setPage] = useState(1);
    const [data, setData] = useState([] as NewsCardProps[]);
    const [trainingData, setTrainingData] = useState([] as NewsCardProps[]);
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([] as EntryCategory[]);

    const filter = (p = 1, input = data) => setTrainingData(input.filter((_, index) => index >= (p - 1) * 10 && index < p * 10));

    const refreshData = (withLoad = true) => {
        setLoading(withLoad);
        TrainingService.getPendingTrainingCourses().then((r) => {
            setData(r);
            filter(page, r);
            setLoading(false);
        }).catch((e) => {
            setLoading(false);
            auth.handleLogout();
        });

        TrainingService.getCategories().then((r) => {
            setCategories(r);
        });
    }

    useEffect(() => {
        auth.verifyAuth(AdminScopes.TRAINING);

        refreshData();

        return () => {
        };
    }, [auth, page]);

    const updatePagination = (page: number) => {
        setPage(page);
        filter(page);
    }

    return (
        <div className="content">
            <RetoricBreadcrumbs locations={Locations['admin/training']}>Zarządzaj poradnikami</RetoricBreadcrumbs>
            <div className="flex flex-row lg:justify-start justify-center lg:self-start self-stretch">
                <RetoricButton type={'primary'} outline onClick={() => setModalOpen(true)}
                               className="mr-4 min-w-[150px]">Zarządzaj kategoriami</RetoricButton>
                <RetoricButton type={'primary'} outline onClick={() => auth.navigate('/admin/training/new')}
                               className="mr-4 min-w-[150px] lg:hidden">Dodaj poradnik</RetoricButton>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 self-stretch">
                {!loading && trainingData.map((news, index) => <NewsCard key={`training-${news.id}`} {...news}
                                                                         refresh={() => refreshData(false)}
                                                                         categories={categories}
                                                                         categoryName={categories.find((x) => x.id === news.categoryId)?.name}
                                                                         withApproval={true}
                                                                         isTraining={true}/>)}
                {!loading && trainingData.length === 0 &&
                    <Empty className="lg:col-span-2" description="Brak wyników"/>}
                {loading && [null, null, null, null].map((item, index) => <BlankNewsCard
                    key={`placeholder-training-${index}`}/>)}
            </div>
            <Pagination defaultCurrent={1} total={data.length} onChange={updatePagination}
                        showSizeChanger={false}/>
            <TrainingCategoriesModal open={modalOpen} setOpen={setModalOpen}
                                     refresh={() => refreshData(false)}/>
        </div>
    );
}

export default TrainingRedaction;
