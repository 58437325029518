import React, {useEffect, useState} from 'react';
import './SoftwareEdit.css';
import {useAuth} from "../../../../extensions/Auth";
import {Form, Input, message, notification, Radio, Select, Upload, UploadFile, UploadProps} from "antd";
import {SoftwareService} from "../../../../services/SoftwareService";
import {AdminScopes} from "../../../../types/Scopes";
import RetoricButton from "../../../../designsystems/RetoricButton/RetoricButton";
import RetoricBreadcrumbs from "../../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../../extensions/Locations";
import {EntryCategory} from "../../Training/TrainingCategoriesModal/TrainingCategoriesModal";
import {useParams} from "react-router-dom";
import {SoftwareCardProps} from "../../../../components/SoftwareCard/SoftwareCard";
import {UploadOutlined} from "@ant-design/icons";
import {Attachment} from "../../../../types/StaticAsset";
import {StaticService} from "../../../../services/StaticService";
import {BACKEND_BASE} from "../../../../services/LoginService";

const {TextArea} = Input;

function SoftwareEdit() {
    const auth = useAuth();
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [icon, setIcon] = useState('');
    const [link, setLink] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [categories, setCategories] = useState([] as EntryCategory[]);
    const [data, setData] = useState({} as SoftwareCardProps)
    const [iconType, setIconType] = useState('link' as 'link' | 'file');
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [attachment, setAttachment] = useState({} as Attachment);

    let {id} = useParams();

    const locations = [...Locations['admin/software'], {
        title: `Edycja: ${data.title}`,
        path: `/software/edit/${id}`
    }]


    const updateField = (e: any, updater: any) => {
        updater(e.target.value);
    }

    const updateCategoryId = (e: any) => {
        setCategoryId(e);
    }

    useEffect(() => {
        auth.verifyAuth(AdminScopes.SOFTWARE);

        if (!id) {
            auth.navigate('/admin')
            return
        }

        SoftwareService.getCategories().then((r) => {
            setCategories(r);
        }).finally(() => {
            SoftwareService.getSoftwareById(id!).then((r) => {
                setData(r);
                form.setFieldsValue({
                    title: r.title,
                    description: r.description,
                    avatar: r.avatar,
                    url: r.url,
                    categoryId: r.categoryId ?? ''
                });

                setTitle(r.title ?? '');
                setDescription(r.description ?? '');
                setIcon(r.avatar ?? '');
                setLink(r.url ?? '');
                setCategoryId(r.categoryId ?? '');
            });
        });

        return () => {
        };
    }, [auth]);

    const updateIconType = (e: any) => {
        setIconType(e.target.value);
    }

    const uploadProps: UploadProps = {
        name: 'file',
        multiple: false,
        fileList: fileList,
        accept: 'image/jpeg,image/png,image/jpg,image/png',
        beforeUpload(file) {
            StaticService.addAttachment(file)
                .then(x => {
                    const attachmentResult: Attachment = {
                        uploadId: file.uid,
                        asset: x
                    };

                    setAttachment(attachmentResult);
                    setFileList([...fileList, file]);
                    setIcon(`${BACKEND_BASE}/Static/attachment/${x.name}`);
                    message.success(`Ikona została przesłana!`);
                })
                .catch(x => {
                    message.error(`Wystąpił błąd!`);
                })

            return false;
        },
        onRemove(file) {
            StaticService.deleteAttachment(attachment.asset.name)
                .then(x => {
                    setAttachment({} as Attachment);
                    setFileList(fileList.filter(x => x.uid !== file.uid));
                    setIcon('')
                    message.success(`Ikona została usunięta!`);
                })
                .catch(x => {
                    message.error(`Wystąpił błąd!`);
                });
        }
    };

    const publish = () => {
        if (!title || !description || !icon) {
            api.error({
                message: `Uzupełnij wymagane pola`,
                description: 'Tytuł, opis i ikona nie mogą być puste!',
                placement: 'top'
            });
            return;
        }

        if (title.length > 50) {
            api.error({
                message: `Zbyt długi tytuł`,
                description: 'Tytuł może mieć maksymalnie 50 znaków!',
                placement: 'top'
            });
            return;
        }

        const payload: SoftwareCardProps = {
            ...data,
            title,
            description,
            url: link,
            avatar: icon,
            categoryId: categoryId.length === 0 ? undefined : categoryId,
        }

        SoftwareService.updateSoftware(payload)
            .then(() => {
                api.info({
                    message: `Zaktualizowano oprogramowanie`,
                    description: 'Oprogramowanie zostało Zaktualizowano!',
                    placement: 'top'
                });
            })
            .catch((e) => {
                api.error({
                    message: `Błąd`,
                    description: 'Wystąpił błąd podczas aktualizacji oprogramowania!',
                    placement: 'top'
                });
            });
    }

    return (
        <div className="content">
            <RetoricBreadcrumbs locations={locations}>Edytuj oprogramowanie</RetoricBreadcrumbs>
            {contextHolder}
            <Form
                layout={'vertical'}
                form={form}
                className={"min-w-full"}
            >
                <Form.Item label="Tytuł" name="title" rules={[{required: true}]}>
                    <Input size="large" placeholder="Tytuł oprogramowania" value={title}
                           onChange={(e) => updateField(e, setTitle)} className="bg-offwhite rounded-none"/>
                </Form.Item>
                <Form.Item label="Opis" name="description" rules={[{required: true}]}>
                    <TextArea size="large" placeholder="Opis oprogramowania" value={description}
                              onChange={(e) => updateField(e, setDescription)}
                              className="bg-offwhite rounded-none"
                              rows={4}/>
                </Form.Item>
                <Form.Item label="Ikona">
                    <Radio.Group defaultValue="link" onChange={updateIconType}>
                        <Radio value="link">Link</Radio>
                        <Radio value="file">Plik</Radio>
                    </Radio.Group>
                    {icon && <div className="mt-4">
                        <img src={icon} alt={'Updated software icon'} className="w-64 h-64"/>
                    </div>}
                </Form.Item>
                {iconType === 'link' &&
                    <Form.Item name="avatar" rules={[{required: true, message: 'Ikona oprogramowania jest wymagana'}]}>
                        <Input size="large" placeholder="Link do ikony" value={icon}
                               onChange={(e) => updateField(e, setIcon)} className="bg-offwhite rounded-none"/>
                    </Form.Item>}
                {iconType === 'file' &&
                    <Form.Item name="avatarAttachment"
                               rules={[{required: true, message: 'Ikona oprogramowania jest wymagana'}]}>
                        <Upload {...uploadProps}>
                            <RetoricButton htmlType={'button'} icon={<UploadOutlined/>}>Wybierz ikonę</RetoricButton>
                        </Upload>
                    </Form.Item>}
                <Form.Item label="Kategoria" name="categoryId">
                    <Select value={categoryId} style={{width: '100%'}} allowClear size={'large'}
                            onChange={updateCategoryId}>
                        <Select.Option value={''}>Brak kategorii</Select.Option>
                        {categories.map((category) => <Select.Option value={category.id}
                                                                     key={category.id}>{category.name}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label="Link" name="url">
                    <Input size="large" placeholder="Link do oprogramowania" value={link}
                           onChange={(e) => updateField(e, setLink)} className="bg-offwhite rounded-none"/>
                </Form.Item>
            </Form>
            <RetoricButton type={'primary'} onClick={publish} className="min-w-[200px]">Zapisz
                oprogramowanie</RetoricButton>
        </div>
    );
}

export default SoftwareEdit;
