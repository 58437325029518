import {Menu, MenuProps} from "antd";
import React, {useEffect, useState} from "react";
import {
    CodeOutlined,
    ContainerOutlined, HomeOutlined,
    InfoCircleOutlined,
    IssuesCloseOutlined,
    LeftCircleOutlined,
    MenuOutlined,
    RightCircleOutlined,
    RobotOutlined,
    SecurityScanOutlined,
    SettingOutlined,
    UserOutlined
} from '@ant-design/icons';
import {useAuth} from "../../extensions/Auth";
import './NavManagerMenu.css'
import {getWindowDimensions} from "../../screens/UserView/Info/InfoDynamic/InfoDynamic";

export default function NavManagerMenu() {
    const auth = useAuth();
    const [menuItems, setMenuItems] = useState([] as MenuProps['items']);
    const [openKeys, setOpenKeys] = useState([] as string[]);
    const [current, setCurrent] = useState('invalid');
    const [isMobile, setIsMobile] = React.useState(getWindowDimensions().width < 1024);
    const [collapsed, setCollapsed] = useState(localStorage.getItem('manager-collapsed') === 'true');

    const items: MenuProps['items'] = [];

    const onClick: MenuProps['onClick'] = e => {
        if (e.key === 'collapse')
            updateCollapsed(!collapsed);
        else {
            auth.navigate(`/manager/${e.key}`);
        }
    };

    const updateCollapsed = (newState: boolean) => {
        setCollapsed(newState);
        localStorage.setItem('manager-collapsed', newState.toString());
    }

    useEffect(() => {
        const path = window.location.pathname.split('/');

        if (path[1] === 'manager' && path.length > 2) {
            if (path.length === 4)
                setCurrent(path.slice(2).join('/'));
            else
                setCurrent(path[2]);
        }

        items.push({
            label: 'Lista pracowników',
            key: '',
            icon: <UserOutlined/>,
        })

        const holidayItems = [
            {
                label: 'Lista urlopów',
                key: 'holidays',
            }
        ];

        if(getWindowDimensions().width > 1024) {
            holidayItems.push({
                label: 'Kalendarz',
                key: 'holidays/calendar'
            });
        }

        items.push({
            label: 'Urlopy',
            key: 'holidays-group',
            icon: <HomeOutlined/>,
            children: holidayItems
        })

        setMenuItems(items);
        setOpenKeys(items.map((x: any) => x.key));

        function handleResize() {
            setIsMobile(getWindowDimensions().width < 1024);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [auth]);

    return (
        <div className={collapsed ? '' : 'lg:min-w-[250px]'}>
            <Menu onClick={onClick}
                  overflowedIndicatorPopupClassName={'admin-nav'}
                  selectedKeys={[current]}
                  defaultOpenKeys={openKeys}
                  overflowedIndicator={<MenuOutlined/>}
                  className={'admin-nav'}
                  rootClassName={'admin-nav'}
                  mode={isMobile ? 'horizontal' : 'inline'}
                  inlineCollapsed={collapsed || isMobile}
                  items={[...menuItems!, {
                      label: collapsed ? 'Otwórz menu' : 'Zamknij menu',
                      key: 'collapse',
                      className: 'desktop',
                      icon: collapsed ? <RightCircleOutlined/> : <LeftCircleOutlined/>
                  }]}/>
        </div>)
}
