export const AdminScopes = {
    SYSTEM: 0x00000001,
    NEWS: 0x00000002,
    USERS: 0x00000004,
    SOFTWARE: 0x00000008,
    TRAINING: 0x00000010,
    INFOPAGE: 0x00000020,
    SIGNALIST: 0x00000040,
}

export const ControllableProperties = {
    FIRST_NAME: 1,
    LAST_NAME: 2,
    ORGANISATION: 3,
    POSITION: 4,
    HOLIDAY_ALLOWANCE: 5,
}

export const GUID_EMPTY = '00000000-0000-0000-0000-000000000000';