import React, {useEffect} from 'react';
import './Info.css';
import {Empty, Skeleton, Tabs} from 'antd';
import RetoricBreadcrumbs from "../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../extensions/Locations";
import {InfopageService} from "../../../services/InfopageService";
import {InfopageCategory} from "../../../types/InfopageModels";
import InfoDynamic from "./InfoDynamic/InfoDynamic";
import {useParams} from "react-router-dom";

function Info() {
    const [categories, setCategories] = React.useState([] as { label: string, key: string, children: JSX.Element }[]);
    const [activeTab, setActiveTab] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const {catId, pageId} = useParams();

    const updateStateAndHistory = (id: string) => {
        setActiveTab(id);
        window.history.pushState({}, '', `/information/${id}`);
    }

    const setCategoryId = (x: InfopageCategory[]) => {
        if (x.length === 0) return;

        let id = x[0].id.toString();

        if (catId) {
            const exists = x.find(y => y.id.toString() === catId);

            id = exists ? catId : x[0].id.toString();
        }

        updateStateAndHistory(id);
    }

    useEffect(() => {
        setLoading(true);

        InfopageService
            .getInfopageCategories()
            .then((x: InfopageCategory[]) => {
                setCategories(x
                    .filter(y => y.infopages.length > 0)
                    .map((y: InfopageCategory) => {
                        return {
                            label: y.title,
                            key: y.id.toString(),
                            children: <InfoDynamic category={y}/>
                        }
                    }));

                setCategoryId(x);
            })
            .finally(() => setLoading(false));

        return () => {
        };
    }, []);

    const updateActiveTab = (id: string) => {
        setActiveTab(id);
        updateStateAndHistory(id);
    }

    return (
        <div className="info">
            <RetoricBreadcrumbs locations={Locations['info']}>Informacje</RetoricBreadcrumbs>
            {!loading && categories.length > 0 && <Tabs
                defaultActiveKey={activeTab}
                onChange={updateActiveTab}
                centered
                items={categories}
            />}
            {!loading && categories.length === 0 && <Empty description="Brak stron informacyjnych"/>}
            {loading && <Skeleton active/>}
        </div>
    );
}

export default Info;
