import React, {useState} from 'react';
import './SoftwareCard.css';
import {Avatar, notification, Select, Skeleton, Tooltip} from "antd";
import Meta from "antd/es/card/Meta";
import RetoricCard from "../../designsystems/RetoricCard/RetoricCard";
import {DeleteOutlined, EditOutlined, SaveOutlined} from "@ant-design/icons";
import {SoftwareService} from "../../services/SoftwareService";
import {useAuth} from "../../extensions/Auth";
import {EntryCategory} from "../../screens/AdminView/Training/TrainingCategoriesModal/TrainingCategoriesModal";
import ConfirmationModal, {ConfirmationModalProps} from "../../designsystems/ConfirmationModal/ConfirmationModal";
import {ErrorMessages} from "../../extensions/ErrorMessages";

export interface SoftwareCardProps {
    id: string;
    title: string;
    description: string;
    avatar: string;
    url?: string;
    onClick?: () => void;
    refresh?: () => void;
    isRedact?: boolean;
    categoryName?: string;
    categoryId?: string;
    categories?: EntryCategory[];
}

function SoftwareCard(props: SoftwareCardProps) {
    const {title, description, avatar, onClick, isRedact, categoryName, categoryId, id, refresh, categories} = props;
    const [api, contextHolder] = notification.useNotification();
    const auth = useAuth();
    const [displayEdit, setDisplayEdit] = React.useState(false);
    const [editing, setEditing] = React.useState(false);
    const [newCategoryId, setNewCategoryId] = React.useState(categoryId || '');
    const [softwareDeleteModal, setSoftwareDeleteModal] = useState(false);
    const [modelPayload, setModelPayload] = useState({} as ConfirmationModalProps);

    const deleteSoftware = () => {
        SoftwareService.deleteSoftware(id).then(() => {
            api.info({
                message: `Oprogramowanie usunięte pomyślnie`,
                description: 'Oprogramowanie zostało usunięte!',
                placement: 'top'
            });

            if (refresh)
                refresh();
        }).catch((e: any) => {
            if (e.message === 'notFound')
                api.error({
                    message: `Błąd`,
                    description: 'Wystąpił błąd podczas usuwania oprogramowania!',
                    placement: 'top'
                });
            else
                auth.handleLogout();
        });
    }

    const updateCategoryId = (id: string) => {
        setNewCategoryId(id);
    }

    const saveCategory = () => {
        SoftwareService.updateSoftwareCategory(id, newCategoryId)
            .then(() => {
                api.info({
                    message: `Kategoria zmieniona pomyślnie`,
                    description: 'Oprogramowanie zostało przypisane do nowej kategorii!',
                    placement: 'top'
                });

                if (refresh)
                    refresh();

                setEditing(false);
            })
            .catch((e: any) => {
                if (e.message === ErrorMessages.softwareDoesNotExists)
                    api.error({
                        message: `Błąd`,
                        description: 'Ten program nie istnieje!',
                        placement: 'top'
                    });
                else if (e.message === ErrorMessages.categoryDoesNotExists)
                    api.error({
                        message: `Błąd`,
                        description: 'Ta kategoria nie istnieje!',
                        placement: 'top'
                    })
                else
                    auth.handleLogout();
            });
    }

    return (
        <RetoricCard
            hoverable
            className={`software__card bg-offwhite ${isRedact ? 'hover:shadow-none' : 'hover:shadow'}`}
            onClick={onClick ?? undefined}
        >
            {contextHolder}
            <div>
                {isRedact && <div className="mb-8 flex flex-col w-full gap-0">
                    <div className="flex flex-row justify-between w-full" onMouseEnter={() => setDisplayEdit(true)}
                         onMouseLeave={() => setDisplayEdit(false)}>
                        {!editing && <div className="text-lg flex flex-row gap-2">{categoryName ?
                            <span className="text-slate-950">{categoryName}
                            </span> :
                            <span className="text-slate-600">Brak kategorii</span>}
                            {displayEdit && <Tooltip title="Zmień kategorię">
                                <EditOutlined onClick={() => setEditing(true)}/>
                            </Tooltip>}
                        </div>}
                        {editing && <div className="text-lg flex flex-row gap-2">
                            <Select defaultValue={newCategoryId} allowClear style={{minWidth: 300}}
                                    onClear={() => setEditing(false)}
                                    onChange={updateCategoryId}>
                                <Select.Option value={''}>Brak kategorii</Select.Option>
                                {categories?.map((category) => <Select.Option value={category.id}
                                                                              key={category.id}>{category.name}</Select.Option>)}
                            </Select>
                            <Tooltip title="Zapisz">
                                <SaveOutlined onClick={() => saveCategory()}/>
                            </Tooltip>
                        </div>}

                        <div className="flex flex-row gap-4">

                            <Tooltip title="Usuń">
                                <DeleteOutlined
                                    className="text-lg cursor-pointer" onClick={() => {
                                    const payload = {
                                        title: 'Usuń oprogramowanie',
                                        open: true,
                                        setOpen: setSoftwareDeleteModal,
                                        yesAction: () => {
                                            deleteSoftware();
                                            setSoftwareDeleteModal(false);
                                        },
                                        noAction: () => setSoftwareDeleteModal(false),
                                    } as ConfirmationModalProps;

                                    payload.infoboxTitle = "Wybrane oprogramowanie zostanie usunięte"
                                    payload.children =
                                        <p className="text-slate-600">Usunięte oprogramowanie nie może zostać
                                            przywrócone, czy jesteś pewien, że chcesz usunąć <i>"{title}"</i>?</p>

                                    setModelPayload(payload);
                                    setSoftwareDeleteModal(true);
                                }}/>
                            </Tooltip>
                            <Tooltip title="Edytuj">
                                <EditOutlined
                                    className="text-lg"
                                    onClick={() => auth.navigate(`/admin/software/edit/${id}`)}/>
                            </Tooltip>
                        </div>
                    </div>
                </div>}
            </div>
            <Skeleton loading={false} avatar active>
                <Meta
                    avatar={<Avatar className="bg-brandColor-50 p-1" src={avatar}/>}
                    title={title}
                    description={<p>{description}</p>}
                />
            </Skeleton>
            <ConfirmationModal {...modelPayload} open={softwareDeleteModal} setOpen={setSoftwareDeleteModal}/>
        </RetoricCard>
    );
}

export default SoftwareCard;
