import React from 'react';
import './Login.css';
import {Checkbox, Form, Input} from "antd";
import {useAuth} from "../../extensions/Auth";
import {LockOutlined, MailOutlined} from "@ant-design/icons";
import Logo from '../../assets/logotype.svg'
import LogoDark from '../../assets/logotype-dark.svg'
import RetoricButton from "../../designsystems/RetoricButton/RetoricButton";

function Login({logo, logoDark}: { logo?: string, logoDark?: string }) {
    const auth = useAuth();
    const [loading, setLoading] = React.useState(false);
    const [loginError, setLoginError] = React.useState(false);

    const onFinish = (values: any) => {
        setLoading(true);
        setLoginError(false);
        const user = {
            email: values.email,
            password: values.password
        }

        auth.handleLogin(user).then((x: boolean) => {
            if (x)
                setLoginError(false);
            else
                setLoginError(true);

            setLoading(false);
        }).catch((e: any) => {
            setLoginError(true);
            setLoading(false);
        });
    };

    return (
        <div className="flex flex-col justify-center items-center w-full h-[100vh] bg-[#eeeeee]">
            <div className="container flex lg:flex-row flex-col-reverse rounded-2xl">
                <div className="flex-1 self-stretch bg-white lg:rounded-l-2xl rounded-2xl">
                    <div className="flex-1 self-stretch lg:p-16 p-4">
                        <img src={logoDark || LogoDark} alt="Logo" className="lg:hidden w-[50%] mx-auto my-4"/>
                        <h1 className="lg:text-3xl text-2xl text-slate-600 text-center lg:text-left tracking-wide mb-4">Witaj!</h1>
                        <h3 className="text-xl text-slate-600 text-center lg:text-left tracking-wide">Zaloguj się by
                            uzyskać dostęp do intranetu!</h3>
                        <Form
                            name="basic"
                            layout={'vertical'}
                            className="flex flex-col mt-8"
                            initialValues={{remember: true}}
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[{required: true, message: 'Adres email jest wymagany!'}]}
                            >
                                <Input prefix={<MailOutlined/>} size={'large'} placeholder={'jan@domena.pl'}
                                       className="rounded-none"
                                />
                            </Form.Item>

                            <Form.Item
                                label="Hasło"
                                name="password"
                                rules={[{required: true, message: 'Hasło jest wymagane!'}]}
                            >
                                <Input.Password prefix={<LockOutlined/>} size={'large'} placeholder={'Hasło...'}
                                                className="rounded-none"
                                />
                            </Form.Item>

                            <Form.Item name="remember" valuePropName="checked">
                                <Checkbox>Zapamiętaj mnie na 30 dni</Checkbox>
                            </Form.Item>

                            <Form.Item
                                validateStatus={loginError ? 'error' : 'success'}
                                help={loginError ? 'Podano nieprawidłowe dane logowania!' : ''}
                            >
                                <RetoricButton size='lg' htmlType="submit" loading={loading} className="min-w-[200px]">
                                    Zaloguj
                                </RetoricButton>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
                <div
                    className="flex-1 self-stretch justify-center items-center bg-gradient-to-br from-brandColor-950 to-brandColor4-800 rounded-r-2xl hidden lg:flex">
                    <img src={logo || Logo} alt="Logo" className="w-[50%]"/>
                </div>
            </div>
        </div>
    );
}

export default Login;
