import React, {useEffect, useState} from 'react';
import './AiAssistantSettings.css';
import {Empty, Form, Input, message, notification, Popconfirm, Space, Table, Tooltip} from "antd";
import {LoginService} from "../../../../services/LoginService";
import {SystemService} from "../../../../services/SystemService";
import RetoricBreadcrumbs from "../../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Infobox from "../../../../designsystems/Infobox/Infobox";
import RetoricCard from "../../../../designsystems/RetoricCard/RetoricCard";
import Locations from "../../../../extensions/Locations";
import {
    ClockCircleOutlined,
    CloseOutlined, DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    QuestionCircleOutlined, SaveOutlined,
} from "@ant-design/icons";
import RetoricButton from "../../../../designsystems/RetoricButton/RetoricButton";
import {ColumnsType} from "antd/es/table";
import {AiCommand, AiSettings, TokenUsageWithPrice} from "../../../../types/AiChat";
import {GetFirstOfThisMonth} from "../../../../extensions/DateFormatter";
import {AiChatService} from "../../../../services/AiChatService";
import {ErrorMessages} from "../../../../extensions/ErrorMessages";
import ConfirmationModal, {ConfirmationModalProps} from "../../../../designsystems/ConfirmationModal/ConfirmationModal";
import {Infopage} from "../../../../types/InfopageModels";
import {useAuth} from "../../../../extensions/Auth";

function AiAssistantSettings() {
    const auth = useAuth();
    const [api, contextHolder] = notification.useNotification();
    const [usage, setUsage] = useState({
        totalPrice: 0,
        promptTokens: 0,
        completionTokens: 0,
        completionPrice: 0,
        promptPrice: 0
    } as TokenUsageWithPrice);
    const [aiSettings, setAiSettings] = useState({
        totalBudget: 0,
        budgetPerUser: 0,
        maxCompletionLength: 0
    } as AiSettings);
    const [aiCommands, setAiCommands] = useState([] as AiCommand[]);
    const [aiCommandForm] = Form.useForm();
    const [commandDeleteModal, setCommandDeleteModal] = useState(false);
    const [modelPayload, setModelPayload] = useState({} as ConfirmationModalProps);
    const [editRow, setEditRow] = useState({} as AiCommand)
    const [originalRow, setOriginalRow] = useState({} as AiCommand)

    const firstOfThisMonth = GetFirstOfThisMonth().toLocaleDateString('pl-PL', {
        year: 'numeric',
        month: 'long',
        day: '2-digit'
    });

    useEffect(() => {
        LoginService
            .getAiUsage()
            .then((x) => {
                setUsage(x);
            })
            .catch((e) => {
                message.error('Wystąpił błąd podczas ładowania danych AI.');
            });

        SystemService
            .getAiSettings()
            .then((res: AiSettings | null) => {
                if (res) {
                    setAiSettings(res);
                }
            });

        LoginService
            .getUser()
            .then((data: any) => {
                if (data.error)
                    return;

                if (!data.isAiEnabled) {
                    api.error({
                        message: `Błąd`,
                        description: "Nie posiadasz uprawnień do korzystania z asystenta AI",
                        placement: 'top'
                    });

                    auth.navigate('/');
                }
            });

        refreshCommands();
    }, []);

    const refreshCommands = () => {
        AiChatService
            .getAiCommands()
            .then((res: AiCommand[]) => {
                setAiCommands(res);
            })
            .catch((e) => {
                message.error('Wystąpił błąd podczas ładowania komend.');
            });
    }
    const addAiCommand = (values: AiCommand) => {
        aiCommandForm.validateFields()
            .then((values) => {
                const formValues: AiCommand = {...values};

                AiChatService
                    .addAiCommand(formValues.command, formValues.instruction)
                    .then((res: AiCommand) => {
                        setAiCommands([...aiCommands, res]);
                        message.success('Dodano komendę.');
                        aiCommandForm.resetFields();
                    })
                    .catch((e) => {
                        if (e.message === ErrorMessages.nameExists) message.error('Komenda z takim identyfikatorem już istnieje, wybierz inny identyfikator.');
                        else if (e.message === ErrorMessages.validationFailed) message.error('Identyfikator i polecenie są wymagane.');
                        else message.error('Wystąpił błąd podczas dodawania komendy.');
                    });
            });
    }

    const updateAiCommand = () => {
        if(editRow.command === originalRow.command && editRow.instruction === originalRow.instruction) {
            setEditRow({} as AiCommand);
            setOriginalRow({} as AiCommand);
            return;
        }

        AiChatService
            .updateAiCommand(editRow.command, editRow.instruction, editRow.id)
            .then((x) => {
                refreshCommands();
                setEditRow({} as AiCommand);
                setOriginalRow({} as AiCommand);
                message.success('Zaktualizowano komendę.');
            })
            .catch((e) => {
                if (e.message === ErrorMessages.nameExists) message.error('Komenda z takim identyfikatorem już istnieje, wybierz inny identyfikator.');
                else if (e.message === ErrorMessages.validationFailed) message.error('Identyfikator i polecenie są wymagane.');
                else message.error('Wystąpił błąd podczas edytowania komendy.');
            });
    }

    const deleteAiCommand = (id: string) => {
        AiChatService
            .deleteAiCommand(id)
            .then((x) => {
                setAiCommands(aiCommands.filter(x => x.id !== id));
                message.success('Usunięto komendę.');
            })
            .catch((e) => {
                message.error('Wystąpił błąd podczas usuwania komendy.');
            });
    }

    const columns: ColumnsType<AiCommand> = [
        {
            title: 'Identyfikator',
            dataIndex: 'command',
            key: 'command',
            render: (value, record) =>
                <>
                    {editRow.id === record.id ?
                        <Input value={editRow.command} required
                               onChange={(e) => setEditRow({...editRow, command: e.target.value})}
                               className="rounded-none"/> :
                        <span className="cursor-pointer block">{value}</span>}
                </>
        },
        {
            title: 'Polecenie',
            dataIndex: 'instruction',
            key: 'instruction',
            render: (value, record) =>
                <>
                    {editRow.id === record.id ?
                        <Input value={editRow.instruction} required
                               onChange={(e) => setEditRow({...editRow, instruction: e.target.value})}
                               className="rounded-none"/> :
                        <span className="cursor-pointer block">{value}</span>}
                </>
        },
        {
            title: 'Akcje',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    {editRow.id === record.id &&
                        <Tooltip title="Zapisz zmiany">
                            <SaveOutlined onClick={() => updateAiCommand()}/>
                        </Tooltip>}
                    {editRow.id === record.id &&
                        <Tooltip title="Anuluj">
                            <CloseOutlined onClick={() => {
                                setEditRow({} as AiCommand)
                                setOriginalRow({} as AiCommand)
                            }
                            }/>
                        </Tooltip>}
                    {editRow.id !== record.id &&
                        <Tooltip title="Edytuj komendę">
                            <EditOutlined className="text-lg cursor-pointer" onClick={() => {
                                setEditRow(record);
                                setOriginalRow(record);
                            }}/>
                        </Tooltip>}
                    <Tooltip title="Usuń komendę">
                        <DeleteOutlined
                            className="text-lg cursor-pointer" onClick={() => {
                            const payload = {
                                title: 'Usuń komendę',
                                open: true,
                                setOpen: setCommandDeleteModal,
                                yesAction: () => {
                                    deleteAiCommand(record.id);
                                    setCommandDeleteModal(false);
                                },
                                noAction: () => setCommandDeleteModal(false),
                            } as ConfirmationModalProps;

                            payload.infoboxTitle = "Wybrana komenda zostanie usunięta"
                            payload.children = <p className="text-slate-600">Usunięta komenda nie może zostać
                                przywrócone, czy jesteś pewien, że chcesz usunąć <i>"{record.command}"</i>?</p>

                            setModelPayload(payload);
                            setCommandDeleteModal(true);
                        }}/>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    return (
        <div className="content">
            <div className={'flex flex-col w-full gap-4'}>
                {contextHolder}
                <RetoricBreadcrumbs locations={Locations['aiassistant/settings']}>Ustawienia</RetoricBreadcrumbs>
                <Infobox title={'Ustawienia asystenta AI'} rows={
                    [
                        'W tej sekcji możesz dodać komendy do Asystenta AI, tym sposobem możesz dostosować asystenta do swoich potrzeb.',
                        'Dodatkowo możesz tutaj sprawdzić liczbę wykorzystanych kredytów AI w tym miesiącu.',
                    ]
                }/>
                <div className="grid lg:grid-cols-3 grid-cols-1 gap-8">
                    <div className="flex flex-col gap-4">
                        <RetoricCard bordered={false} className="bg-offwhite w-full flex flex-col gap-4">
                            <div>
                                <h2 className="text-lg text-slate-600">Dodaj komendę</h2>
                            </div>
                            <div>
                                <Form className="gap-4 flex flex-col"
                                      onFinish={addAiCommand}
                                      form={aiCommandForm}
                                      layout={'vertical'}>
                                    <Form.Item label={"Identyfikator"} required name="command"
                                               className="flex-1"
                                               help={'Identyfikator, którym będziesz wywoływać komendę'}
                                               rules={[{required: true, message: 'Identyfikator jest wymagany!'}]}>
                                        <Input placeholder={'Identyfikator...'} className="rounded-none" size="large"/>
                                    </Form.Item>
                                    <Form.Item label={"Polecenie"} required name="instruction"
                                               className="flex-1"
                                               help={'Polecenie, które ma wykonać asystent AI'}
                                               rules={[{required: true, message: 'Polecenie jest wymagane!'}]}>
                                        <Input.TextArea placeholder={'Polecenie...'} className="rounded-none"
                                                        size="large"/>
                                    </Form.Item>
                                    <Form.Item className="mb-0">
                                        <RetoricButton htmlType={'submit'} size="md" type="primary">Dodaj
                                            komendę</RetoricButton>
                                    </Form.Item>
                                </Form>
                            </div>
                        </RetoricCard>
                        <RetoricCard bordered={false} className="bg-offwhite w-full flex flex-col gap-4">
                            <div>
                                <h2 className="text-lg text-slate-600">Wykorzystanie budżetu
                                    (od {firstOfThisMonth})</h2>
                            </div>
                            <span
                                className="text-2xl font-light"><ClockCircleOutlined
                                className="mr-2"/> {usage.totalPrice.toFixed(5)} / {aiSettings.budgetPerUser} <span
                                className="text-sm align-super">PLN</span></span>
                        </RetoricCard>
                        <RetoricCard bordered={false} className="bg-offwhite w-full flex flex-col gap-4">
                            <div>
                                <h2 className="text-lg text-slate-600">Wykorzystane kredyty zapytań
                                    (od {firstOfThisMonth})</h2>
                            </div>
                            <span
                                className="text-2xl font-light"><QuestionCircleOutlined
                                className="mr-2"/> {usage.promptTokens} ({(usage.promptPrice).toFixed(5)}
                                <span className="text-sm align-super">PLN</span>)</span>
                        </RetoricCard>
                        <RetoricCard bordered={false} className="bg-offwhite w-full flex flex-col gap-4">
                            <div>
                                <h2 className="text-lg text-slate-600">Wykorzystane kredyty odpowedzi
                                    (od {firstOfThisMonth})</h2>
                            </div>
                            <span
                                className="text-2xl font-light"><ExclamationCircleOutlined
                                className="mr-2"/> {usage.completionTokens} ({(usage.completionPrice).toFixed(5)}
                                <span className="text-sm align-super">PLN</span>)</span>
                        </RetoricCard>
                    </div>
                    <RetoricCard className="bg-offwhite lg:p-8 p-2 lg:col-span-2 flex flex-col justify-start gap-4">
                        <h3 className="text-lg text-slate-700 lg:col-span-2">Komendy</h3>
                        <Table columns={columns} dataSource={aiCommands} rowKey={'id'} scroll={{x: 768}}
                               className="self-stretch"
                               pagination={{position: ['bottomCenter']}}
                               locale={{emptyText: <Empty description={'Brak danych'}/>}}/>
                    </RetoricCard>
                </div>
            </div>
            <ConfirmationModal {...modelPayload} open={commandDeleteModal} setOpen={setCommandDeleteModal}/>
        </div>
    );
}

export default AiAssistantSettings;
