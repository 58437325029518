import {Avatar, Divider, List, message, Skeleton, Spin, Tag, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import {
    CalendarDayProps,
    CalendarEvent,
    CalendarEventLocationType, CalendarPriorityColourMapper, CalendarPriorityMapper,
    CalendarTitleMapper,
} from "../RetoricCalendar";
import RetoricCard from "../../../../designsystems/RetoricCard/RetoricCard";
import localeDayJs from 'dayjs/locale/pl';
import './CalendarDayView.css';
import {DeleteOutlined, EditOutlined, InfoCircleTwoTone, LeftOutlined, RightOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import RetoricButton from "../../../../designsystems/RetoricButton/RetoricButton";
import CreateMeetingModal, {CreateMeetingModalPayload} from "../CreateMeetingModal/CreateMeetingModal";
import {
    CalendarEventTypeFormatter,
    DayFormatter,
    GenerateInitials,
    TimeSpanFormatter
} from "../../../../extensions/Calendar";
import CalendarService from "../../../../services/CalendarService";
import {useAuth} from "../../../../extensions/Auth";
import EditMeetingModal, {EditMeetingModalPayload} from "../EditMeetingModal/EditMeetingModal";
import ConfirmationModal, {ConfirmationModalProps} from "../../../../designsystems/ConfirmationModal/ConfirmationModal";

export const GenerateNextDays = (currentDay: dayjs.Dayjs, days: number): dayjs.Dayjs[] => {
    const nextDays = [];
    const mid = Math.floor(days / 2);

    for (let i = -mid; i < days - mid; i++) {
        nextDays.push(currentDay.add(i, 'day'))
    }

    return nextDays;
}


function CalendarDayView({currentDay, setCurrentDay, isMobile, modalState}: CalendarDayProps) {
    const auth = useAuth();
    const [event, setEvent] = useState<CalendarEvent | undefined>(undefined)
    const [eventsList, setEventsList] = useState([] as CalendarEvent[])
    const [createMeetingModalOpen, setCreateMeetingModalOpen] = useState(false);
    const [modalPayload, setModalPayload] = useState({} as CreateMeetingModalPayload)
    const [editMeetingModalOpen, setEditMeetingModalOpen] = useState(false);
    const [editModalPayload, setEditModalPayload] = useState({} as EditMeetingModalPayload)
    const [eventDeleteModal, setEventDeleteModal] = useState(false);
    const [eventDeleteModalPayload, setEventDeleteModalPayload] = useState({} as ConfirmationModalProps);
    const [loading, setLoading] = useState(false);

    const getEvents = (day: dayjs.Dayjs) => {
        const start = day.hour(0).minute(0).second(0).millisecond(0);
        const end = day.hour(23).minute(59).second(59).millisecond(999);

        CalendarService
            .getEvents(start.toDate(), end.toDate())
            .then(x => {

                x.sort((a, b) => {
                    if (a.date.isWholeDay && !b.date.isWholeDay) return 1;
                    if (!a.date.isWholeDay && b.date.isWholeDay) return -1;
                    if(a.priority === b.priority) return 0;

                    return a.priority > b.priority ? -1 : 1;
                });

                setEventsList(x);

                if (x.length > 0) setEvent(x[0])
                else setEvent(undefined);

                setLoading(false);
            })
            .catch(x => {
                message.error("Wystąpił błąd podczas pobierania wydarzeń");
                setLoading(false);
            });
    }

    const setDay = (day: dayjs.Dayjs) => {
        setCurrentDay(day);
    }

    function createMeeting() {
        const now = dayjs();
        const start = currentDay.hour(now.hour()).minute(0).second(0).millisecond(0).add(1, 'hour');
        const end = currentDay.hour(now.hour()).minute(0).second(0).millisecond(0).add(90, 'minutes');

        setModalPayload({start, end});
        setCreateMeetingModalOpen(true);
    }

    const editMeeting = (event: CalendarEvent) => {
        setEditModalPayload({id: event.id});
        setEditMeetingModalOpen(true);
    }

    const onUpdate = () => {
        getEvents(currentDay)
        setEditModalPayload({} as EditMeetingModalPayload);
    }

    const deleteEvent = (id: string) => {
        CalendarService
            .deleteEvent(id)
            .then(() => {
                message.success("Usunięto wydarzenie");
                getEvents(currentDay);
            })
            .catch(() => {
                message.error("Wystąpił błąd podczas usuwania wydarzenia");
            });
    }

    useEffect(() => {
        setLoading(true);
        getEvents(currentDay);
    }, [currentDay]);

    useEffect(() => {
        getEvents(currentDay);
    }, [modalState, createMeetingModalOpen]);

    return (
        <div className={'grid lg:grid-cols-2 grid-cols-1 lg:gap-8 gap-4'}>
            <Spin spinning={loading} tip="Pobieranie wydarzeń">
                <List
                    itemLayout="horizontal"
                    header={<div className="flex lg:flex-row flex-col justify-between items-center gap-2">
                        <h2 className="text-xl font-light">{DayFormatter(currentDay)}</h2>
                        <div className="flex flex-row gap-2">
                            <Tooltip title="Poprzedni dzień">
                                <button onClick={() => setDay(currentDay.subtract(1, 'day'))}><LeftOutlined/></button>
                            </Tooltip>
                            {isMobile && GenerateNextDays(currentDay, 5).map((day, index) =>
                                day.isSame(currentDay, 'day') ?
                                    <button key={`day-${day.toISOString()}`} onClick={() => setDay(day)}>
                                        <Avatar className={`align-middle text-slate-50 bg-brandColor-800`}
                                                shape="square"
                                                size="large">
                                            {day.format('DD MMM')}
                                        </Avatar>
                                    </button>
                                    :
                                    <button key={`day-${day.toISOString()}`} onClick={() => setDay(day)}>
                                        <Avatar className={`align-middle text-slate-50 bg-brandColor-950`}
                                                shape="square">
                                            {day.format('DD MMM')}
                                        </Avatar>
                                    </button>
                            )}
                            <Tooltip title="Następny dzień">
                                <button onClick={() => setDay(currentDay.add(1, 'day'))}><RightOutlined/></button>
                            </Tooltip>
                        </div>
                    </div>}
                    className={'min-h-full events-list'}
                    dataSource={eventsList}
                    locale={{emptyText: 'Brak wydarzeń w tym dniu'}}
                    pagination={{position: 'bottom', pageSize: 8, hideOnSinglePage: true}}
                    renderItem={(item, index) => (
                        <List.Item
                            onClick={() => setEvent(item)}
                            className={`hover:bg-offwhite/80 cursor-pointer ${item.id === event?.id ? 'bg-offwhite/60' : ''}`}>
                            <List.Item.Meta
                                avatar={<Avatar className={`align-middle text-slate-600`}
                                                style={{background: `${item.colour}`}}
                                                size="large">
                                    {CalendarEventTypeFormatter(item.eventType)}
                                </Avatar>}
                                title={<div className="min-w-full flex flex-row justify-between">
                                    <div className="flex flex-col">
                                        <span>{item.title}</span>
                                        <span
                                            className="text-gray-700/75 font-light italic">{item.organiser.name}</span>
                                    </div>
                                    <span className="text-gray-700/75 font-light">
                                    {TimeSpanFormatter(item, currentDay)}
                                </span>
                                </div>}
                                description={<span className="text-gray-700/75">{item.description?.short ||
                                    <span className={"text-gray-700"}>Brak opisu</span>}</span>}
                            />
                        </List.Item>
                    )}
                />
            </Spin>
            {event && <RetoricCard className={'bg-offwhite'}>
                <div
                    className="flex flex-col 2xl:flex-row 2xl:justify-between 2xl:items-center justify-stretch items-start">
                    <div className="w-full">
                        <div className="flex flex-row items-center">
                            <Tag color={CalendarPriorityColourMapper(event.priority)} className="block">
                                {CalendarPriorityMapper(event.priority)}
                            </Tag>
                            <h3 className="text-lg font-inter mb-1">
                                {event.title}
                            </h3>
                        </div>
                        <div className="flex flex-row gap-1">
                        <span
                            className={"capitalize font-light"}>
                                {!event.date.isWholeDay && (event.date.start.isSame(event.date.end, 'day') ?
                                    `${event.date.start.locale(localeDayJs).format("DD MMMM YYYY HH:mm")} - ${event.date.end.locale(localeDayJs).format("HH:mm")}` :
                                    `${event.date.start.locale(localeDayJs).format("DD MMMM YYYY HH:mm")} - ${event.date.end.locale(localeDayJs).format("DD MMMM YYYY HH:mm")}`)}
                            {event.date.isWholeDay && (event.date.start.isSame(event.date.end, 'day') ?
                                `${event.date.start.locale(localeDayJs).format("DD MMMM YYYY")}` :
                                `${event.date.start.locale(localeDayJs).format("DD MMMM YYYY")} - ${event.date.end.locale(localeDayJs).format("DD MMMM YYYY")}`)}
                            </span>
                            <span className="font-light">({CalendarTitleMapper(event.eventType)})</span>
                        </div>
                    </div>
                    <div
                        className="flex flex-row gap-4 2xl:justify-end justify-between lg:justify-end items-center w-full">
                        <div>
                            <p className="text-lg font-light">Organizator</p>
                            <div className="flex flex-row justify-start items-center gap-1">
                                <Avatar
                                    size="small"
                                    shape="square"
                                    className={`align-middle text-slate-600`}
                                    style={{background: `${event.organiser.colour}`}}>
                                    {GenerateInitials(event.organiser.name)}
                                </Avatar>
                                <span className="text-lg font-light">{event.organiser.name}</span>
                            </div>
                        </div>
                        {event.organiser.id == auth?.user?.id && <div className="min-h-full flex flex-row gap-2">
                            <Tooltip title="Edytuj wydarzenie">
                                <EditOutlined className="text-xl hover:text-brandColor-800"
                                              onClick={() => editMeeting(event)}/>
                            </Tooltip>
                            <Tooltip title="Usuń wydarzenie">
                                <DeleteOutlined className="text-xl hover:text-brandColor-800" onClick={() => {
                                    const payload = {
                                        title: 'Usuń wydarzenie',
                                        open: true,
                                        setOpen: setEventDeleteModal,
                                        yesAction: () => {
                                            deleteEvent(event.id)
                                            setEventDeleteModal(false);
                                        },
                                        noAction: () => setEventDeleteModal(false),
                                    } as ConfirmationModalProps;

                                    payload.infoboxTitle = "Wybrane wydarzenie zostanie usunięte"
                                    payload.children = <>
                                        <p className="text-slate-600">Usunięte wydarzenie nie może zostać przywrócone i
                                            zostanie usunięte dla wszystkich uczestników.</p>
                                    </>

                                    setEventDeleteModalPayload(payload);
                                    setEventDeleteModal(true);
                                }}/>
                            </Tooltip>
                        </div>}
                    </div>
                </div>
                <div>
                    <Divider orientation="left"
                             className="after:bg-slate-300 before:bg-slate-300"
                             orientationMargin={4}
                    >
                        <span className="text-lg font-light mb-2">Uczestnicy</span>
                    </Divider>
                    <div className="flex flex-row flex-wrap gap-2">
                        {event.attendees.map((user, index) =>
                            <div key={`user-${index}`} className="flex flex-row justify-center items-center gap-1">
                                <Avatar
                                    className={`align-middle text-slate-600`}
                                    style={{background: `${user.colour}`}}
                                    size="small">
                                    {GenerateInitials(user.name)}
                                </Avatar>
                                <span className="text-md font-light">{user.name}</span></div>)}
                        {event.attendees.length === 0 &&
                            <p><span className={"text-gray-700"}>Brak uczestników</span></p>}
                    </div>
                    <Divider orientation="left"
                             className="after:bg-slate-300 before:bg-slate-300"
                             orientationMargin={4}
                    >
                        <span className="text-lg font-light mb-2">Lokalizacja</span>
                    </Divider>
                    {event.location.type === CalendarEventLocationType.InPerson && event.location.address &&
                        <p>{event.location.address}</p>}
                    {event.location.type === CalendarEventLocationType.InPerson && !event.location.address && <p><span
                        className="text-gray-700">Stacjonarna (brak szczegółów)</span></p>}
                    {event.location.type === CalendarEventLocationType.Online && event.location.link &&
                        <p><a href={event.location.link}>{event.location.link}</a></p>}
                    {event.location.type === CalendarEventLocationType.Online && !event.location.link && <p><span
                        className={"text-gray-700"}>Online (brak szczegółów)</span></p>}
                    {event.location.type === CalendarEventLocationType.None && <p>Brak</p>}
                    <Divider orientation="left"
                             className="after:bg-slate-300 before:bg-slate-300"
                             orientationMargin={4}
                    >
                        <span className="text-lg font-light mb-2">Opis wydarzenia</span>
                    </Divider>
                    <p>{event.description?.long || <span className={"text-gray-700"}>Brak opisu</span>}</p>
                </div>
            </RetoricCard>}
            {!event && <RetoricCard className={'bg-offwhite flex flex-col justify-center items-center gap-4'}>
                <h3 className="text-xl font-inter mb-2">Brak wydarzeń w tym dniu</h3>
                <InfoCircleTwoTone className="text-4xl"/>
                <p className="font-light text-xl">Chcesz dodać nowe wydarzenie?</p>
                <RetoricButton type={'primary'} onClick={createMeeting}>Dodaj wydarzenie</RetoricButton>
            </RetoricCard>}
            <CreateMeetingModal open={createMeetingModalOpen} setOpen={setCreateMeetingModalOpen}
                                payload={modalPayload}/>
            <EditMeetingModal open={editMeetingModalOpen} setOpen={setEditMeetingModalOpen} payload={editModalPayload}
                              onUpdate={() => onUpdate()}/>
            <ConfirmationModal {...eventDeleteModalPayload} open={eventDeleteModal} setOpen={setEventDeleteModal}/>
        </div>
    );
}

export default CalendarDayView;
