import React, {Key, useEffect, useState} from 'react';
import {AutoComplete, Card, Input, message, Modal, Popconfirm, Select, Space, Tooltip, Tree} from "antd";
import {CheckOutlined, CloseOutlined, DeleteOutlined, DownOutlined} from "@ant-design/icons";
import './AppsModal.css';
import {useAuth} from "../../extensions/Auth";
import {UserApp} from "../../types/AuthState";
import {LoginService} from "../../services/LoginService";
import Infobox from "../../designsystems/Infobox/Infobox";
import RetoricButton from "../../designsystems/RetoricButton/RetoricButton";
import {TargetAppSpace} from "../../screens/UserView/News/NewsList/NewsList";

export interface AppsModalProps {
    open: boolean;
    setOpen: (value: boolean) => void;
}

export const SupportedApps = [
    {
        value: 'Aktualności',
        key: '/'
    },
    {
        value: 'Dodaj aktualność',
        key: '/news/new'
    },
    {
        value: 'Oprogramowanie',
        key: '/modules/software'
    },
    {
        value: 'Delegacje',
        key: '/modules/delegations'
    },
    {
        value: 'Urlop',
        key: '/modules/holiday'
    },
    {
        value: 'Poczta',
        key: '/mail'
    },
    {
        value: 'Profil',
        key: '/profile'
    },
    {
        value: 'Poradniki',
        key: '/training'
    }
]

export const CategoryMapper = (x: UserApp) => {
    return {
        title: `Kategoria: ${x.displayName}`,
        key: `category-${x.displayName}`,
        id: x.id,
        order: x.order,
        isCategory: x.isCategory,
        displayName: x.displayName,
        children: [] as any[],
        isEdit: false,
    }
}

export const AppsMapper = (x: UserApp, key: string) => {
    return {
        title: `${x.displayName} - ${x.url}`,
        url: x.url,
        order: x.order,
        displayName: x.displayName,
        id: x.id,
        isCategory: x.isCategory,
        key: `${key}-app-${x.displayName}`,
        isEdit: false,
        category: x.category ? x.category : '',
        targetAppSpace: x.targetAppSpace
    }
}

function AppsModal({open, setOpen}: AppsModalProps) {
    const auth = useAuth();
    const [dataSource, setDataSource] = useState([] as any[]);
    const [selectedValues, setSelectedValues] = useState([] as Key[]);
    const [editMode, setEditMode] = useState({isEdit: false, value: {} as any, mode: 'category' as 'category' | 'app'});
    const [insertMode, setInsertMode] = useState({
        isInsert: false,
        value: {} as any,
        mode: 'category' as 'category' | 'app'
    });

    const appSorter = (a: UserApp, b: UserApp) => a.order !== undefined && b.order !== undefined ? a.order - b.order : 0;

    const loadApps = () => {
        LoginService.getUserApps().then((userApps: UserApp[]) => {
            const sortedApps = userApps.slice().sort(appSorter);
            const categories = [] as any[];

            categories.push({
                title: 'Kategoria: Brak',
                key: `category-blank`,
                children: [] as any[]
            });

            const allCategories = sortedApps
                .filter((x) => x.isCategory)
                .map(x => CategoryMapper(x)).sort((a: any, b: any) => a.title.localeCompare(b.title));

            categories.push(...allCategories);

            sortedApps.forEach((x) => {
                const key = x.category ? `category-${x.category}` : 'category-blank';
                const category = categories.find(y => y.key === key);

                if (category && !x.isCategory) {
                    category.children.push(AppsMapper(x, key))
                }
            });

            setDataSource(categories);
        });
    }

    useEffect(() => {
        if (auth.user?.isAuthenticated) {
            loadApps();
        }
    }, []);

    const editApp = (key: Key[]) => {
        cancelInsertMode();
        if (key.length > 0 && key[0] !== 'category-blank') {
            let app = dataSource.find(x => x.key === key[0]);

            if (app) {
                app.isEdit = true;
                setEditMode({isEdit: true, value: app, mode: 'category'});
            } else {
                dataSource.forEach(x => {
                    if (x.children) {
                        app = x.children.find((y: any) => y.key === key[0]);

                        if (app) {
                            setEditMode({isEdit: true, value: app, mode: 'app'});
                            app.isEdit = true;
                        }
                    }
                })
            }
        } else {
            const data = [...dataSource];

            data.forEach(x => {
                x.isEdit = false;

                if (x.children) {
                    x.children.forEach((y: any) => {
                        y.isEdit = false;
                    });
                }
            })

            setEditMode({isEdit: false, value: {}, mode: 'category'});
        }

        setSelectedValues(key);
    }

    const cancelEdit = () => {
        setEditMode({isEdit: false, value: {}, mode: 'category'})
        setSelectedValues(['']);
    }

    const cancelInsertMode = () => {
        setInsertMode({isInsert: false, value: {}, mode: 'category'})
    }

    const onFormEdit = (event: any, key: string) => {
        let value = event?.target?.value ?? event;

        if (key === 'url') {
            const app = SupportedApps.find(x => x.value === value);

            if (app) value = app.key;
        }

        setEditMode({...editMode, value: {...editMode.value, [key]: value}});
    }

    const onInsertFormEdit = (event: any, key: string) => {
        let value = event?.target?.value ?? event;

        if (key === 'url') {
            const app = SupportedApps.find(x => x.value === value);

            if (app) value = app.key;
        }

        setInsertMode({...insertMode, value: {...insertMode.value, [key]: value}});
    }

    const onFormEditCategory = (event: any) => {
        setEditMode({...editMode, value: {...editMode.value, category: event.split('category-')[1]}});
    }

    const onInsertFormEditCategory = (event: any) => {
        setInsertMode({...insertMode, value: {...insertMode.value, category: event.split('category-')[1]}});
    }

    const deleteApp = (id: string) => {
        LoginService.deleteUserApp(id).then((x) => {
            if (x) {
                message.success(`${insertMode.mode === 'category' ? 'Kategoria' : 'Aplikacja'} została usunięta!`);
                loadApps();
                cancelEdit();
            } else {
                message.error(`Wystąpił błąd podczas usuwania ${insertMode.mode === 'category' ? 'kategorii' : 'aplikacji'}!`);
            }
        });
    }

    const saveEdit = () => {
        const payload: UserApp = {
            id: editMode.value.id,
            displayName: editMode.value.displayName,
            order: editMode.value.order,
            isCategory: editMode.value.isCategory
        }

        if (!editMode.value.isCategory) {
            payload.category = editMode.value.category;
            payload.targetAppSpace = editMode.value.targetAppSpace;
            payload.url = editMode.value.url;
        }

        LoginService.editUserApp(payload).then((x) => {
            if (x) {
                message.success(`${insertMode.value.isCategory ? 'Kategoria' : 'Aplikacja'} została zapisana`);
                cancelEdit();
                loadApps();
            } else {
                message.error(`Wystąpił błąd podczas zapisywania ${insertMode.value.isCategory ? 'kategorii' : 'aplikacji'}`);
            }
        });
    }

    const saveInsert = () => {
        const payload: UserApp = {
            id: insertMode.value.id,
            displayName: insertMode.value.displayName,
            order: insertMode.value.order,
            isCategory: insertMode.mode === 'category'
        }

        if (!insertMode.value.isCategory) {
            payload.category = insertMode.value.category;
            payload.targetAppSpace = insertMode.value.targetAppSpace;
            payload.url = insertMode.value.url;
        }

        LoginService.addUserApp(payload).then((x) => {
            if (x) {
                message.success(`${insertMode.mode === 'category' ? 'Kategoria' : 'Aplikacja'} została dodana!`);
                loadApps();
                cancelInsertMode();
            } else {
                message.error(`Wystąpił błąd podczas dodawania ${insertMode.mode === 'category' ? 'kategorii' : 'aplikacji'}${insertMode.mode === 'category' ? ', kategoria już istnieje!' : ''}!`);
            }
        });
    }

    const closeModal = () => {
        setOpen(false);
        cancelEdit();
        cancelInsertMode();
    }

    const ActionButtons = () => <Space>
        <Tooltip title={'Zapisz zmiany'}>
            <CheckOutlined onClick={() => editMode.isEdit ? saveEdit() : saveInsert()}/>
        </Tooltip>
        <Tooltip title={'Odrzuć zmiany'}>
            <CloseOutlined onClick={() => {
                cancelEdit()
                cancelInsertMode()
            }
            }/>
        </Tooltip>
        {editMode.isEdit && <Tooltip title={`Usuń ${editMode.mode === 'category' ? 'kategorię' : 'aplikację'}`}>
            <Popconfirm
                placement="top"
                title={`Czy jesteś pewien?`}
                description={`Czy na pewno chcesz usunąć ${editMode.mode === 'category' ? 'kategorię' : 'aplikację'} ${editMode.value.displayName}?`}
                onConfirm={() => deleteApp(editMode.value.id)}
                okText="Tak"
                okButtonProps={{className: "bg-brandColor-950 hover:bg-brandColor-800"}}
                cancelText="Nie"
            >
                <DeleteOutlined/>
            </Popconfirm>
        </Tooltip>}
    </Space>

    return (
        <Modal
            title="Zarządzanie aplikacjami"
            centered
            footer={[
                <RetoricButton key="back" onClick={closeModal}>
                    Zamknij
                </RetoricButton>,
            ]}
            onCancel={closeModal}
            open={open}
            width={1000}
        >
            <Infobox title="Zarządzanie aplikacjami"
                     rows={[
                         'W tej skecji możesz zmienić liste aplikacji wyświetlanych w menu na górze ekranu.',
                         'Możesz podzielić swoje aplikacja na kategorie. Wybierz aplikacje z listy wspieranych aplikacji lub dodaj swój własny link.'
                     ]}/>
            <div className="flex flex-col gap-4 mt-4">
                <Space>
                    <RetoricButton outline onClick={() => {
                        setInsertMode({isInsert: true, value: {}, mode: 'app'})
                        cancelEdit();
                    }}>
                        Dodaj aplikację
                    </RetoricButton>
                    <RetoricButton outline onClick={() => {
                        setInsertMode({isInsert: true, value: {}, mode: 'category'})
                        cancelEdit();
                    }}>
                        Dodaj kategorię
                    </RetoricButton>
                </Space>
                <Tree
                    showLine
                    switcherIcon={<DownOutlined/>}
                    defaultExpandedKeys={selectedValues}
                    selectedKeys={selectedValues}
                    treeData={dataSource}
                    onSelect={(selectedKeys, info) => editApp(selectedKeys)}
                />
                {editMode.isEdit &&
                    <Card title={<div className={'apps-modal__edit-form-header'}>
                        <span>Edytowanie {editMode.mode === 'category' ? 'kategorii' : 'aplikacji'}</span>
                        <ActionButtons/></div>}>
                        {editMode.mode === 'category' && <div>
                            <div className={'apps-modal__edit-form'}>
                                <div>
                                    <label>Nazwa</label>
                                    <Input value={editMode.value.displayName} className="rounded-none"
                                           onKeyDown={(e) => {
                                               if (e.key === 'Enter' && editMode.isEdit) saveEdit();
                                               if (e.key === 'Enter' && !editMode.isEdit) saveInsert();
                                           }}
                                           onChange={(x) => onFormEdit(x, 'displayName')}/>
                                </div>
                            </div>
                        </div>}
                        {editMode.mode === 'app' && <div>
                            <div className={'apps-modal__edit-form'}>
                                <div>
                                    <label>Nazwa</label>
                                    <Input value={editMode.value.displayName} className="rounded-none"
                                           onKeyDown={(e) => {
                                               if (e.key === 'Enter' && editMode.isEdit) saveEdit();
                                               if (e.key === 'Enter' && !editMode.isEdit) saveInsert();
                                           }}
                                           onChange={(x) => onFormEdit(x, 'displayName')}/>
                                </div>
                                <div>
                                    <label>Link</label>
                                    <AutoComplete
                                        options={SupportedApps}
                                        defaultValue={editMode.value.url}
                                        onSearch={(x) => onFormEdit(x, 'url')}
                                        onSelect={(x) => onFormEdit(x, 'url')}
                                    />
                                </div>
                                <div>
                                    <label>Miejsce wyświetlania</label>
                                    <Select value={editMode.value.targetAppSpace} style={{width: '100%'}} allowClear
                                            onChange={(x) => onFormEdit(x, 'targetAppSpace')}>
                                        <Select.Option value={TargetAppSpace.All}>Wszędzie</Select.Option>
                                        <Select.Option value={TargetAppSpace.Menu}>Menu w nagłówku</Select.Option>
                                        <Select.Option value={TargetAppSpace.Index}>Strona główna</Select.Option>
                                    </Select>
                                </div>
                                <div>
                                    <label>Kategoria</label>
                                    <Select value={editMode.value.category} style={{width: '100%'}} allowClear
                                            onChange={(x) => onFormEditCategory(x)}>
                                        <Select.Option value={''}>Brak</Select.Option>
                                        {dataSource.filter(x => x.key !== 'category-blank').map(x => <Select.Option
                                            value={x.key} key={x.key}>{x.title}</Select.Option>)}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        }
                    </Card>
                }
                {insertMode.isInsert &&
                    <Card title={<div className={'apps-modal__edit-form-header'}>
                        <span>Dodawanie {insertMode.mode === 'category' ? 'kategorii' : 'aplikacji'}</span>
                        <ActionButtons/></div>}>
                        {insertMode.mode === 'category' && <div>
                            <div className={'apps-modal__edit-form'}>
                                <div>
                                    <label>Nazwa</label>
                                    <Input value={insertMode.value.displayName} className="rounded-none"
                                           onKeyDown={(e) => {
                                               if (e.key === 'Enter' && editMode.isEdit) saveEdit();
                                               if (e.key === 'Enter' && !editMode.isEdit) saveInsert();
                                           }}
                                           onChange={(x) => onInsertFormEdit(x, 'displayName')}/>
                                </div>
                            </div>
                        </div>}
                        {insertMode.mode === 'app' && <div>
                            <div className={'apps-modal__edit-form'}>
                                <div>
                                    <label>Nazwa</label>
                                    <Input value={insertMode.value.displayName} className="rounded-none"
                                           onKeyDown={(e) => {
                                               if (e.key === 'Enter' && editMode.isEdit) saveEdit();
                                               if (e.key === 'Enter' && !editMode.isEdit) saveInsert();
                                           }}
                                           onChange={(x) => onInsertFormEdit(x, 'displayName')}/>
                                </div>
                                <div>
                                    <label>Link</label>
                                    <AutoComplete
                                        options={SupportedApps}
                                        onSearch={(x) => onInsertFormEdit(x, 'url')}
                                        onSelect={(x) => onInsertFormEdit(x, 'url')}
                                    />
                                </div>
                                <div>
                                    <label>Miejsce wyświetlania</label>
                                    <Select defaultValue={TargetAppSpace.All} style={{width: '100%'}} allowClear
                                            onChange={(x) => onInsertFormEdit(x, 'targetAppSpace')}>
                                        <Select.Option value={TargetAppSpace.All}>Wszędzie</Select.Option>
                                        <Select.Option value={TargetAppSpace.Menu}>Menu w nagłówku</Select.Option>
                                        <Select.Option value={TargetAppSpace.Index}>Strona główna</Select.Option>
                                    </Select>
                                </div>
                                <div>
                                    <label>Kategoria</label>
                                    <Select defaultValue={insertMode.value.category} style={{width: '100%'}} allowClear
                                            onChange={(x) => onInsertFormEditCategory(x)}>
                                        <Select.Option value={''}>Brak</Select.Option>
                                        {dataSource.filter(x => x.key !== 'category-blank').map(x => <Select.Option
                                            value={x.key} key={x.key}>{x.title}</Select.Option>)}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        }
                    </Card>
                }
            </div>
        </Modal>
    );
}

export default AppsModal;
