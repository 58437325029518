import React, {useEffect, useState} from 'react';
import './TrainingView.css';
import {useParams} from "react-router-dom";
import {TrainingService} from "../../../../services/TrainingService";
import {NewsCardProps} from "../../../../components/NewsCard/NewsCard";
import DateFormatter from "../../../../extensions/DateFormatter";
import {Skeleton} from "antd";
import {useAuth} from "../../../../extensions/Auth";
import RetoricCard from "../../../../designsystems/RetoricCard/RetoricCard";
import RetoricBreadcrumbs from "../../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../../extensions/Locations";
import AttachmentsDisplay from "../../../../components/AttachmentsDisplay/AttachmentsDisplay";

function TrainingView() {
    const auth = useAuth();
    const [data, setData] = useState({} as NewsCardProps);
    const [loading, setLoading] = useState(true);

    let {id} = useParams();

    const locations = [...Locations['training'], {
        title: data.title,
        path: `/training/view/${id}`
    }]

    useEffect(() => {
        setLoading(true);

        if (!id) {
            auth.navigate('/training')
            return
        }

        TrainingService.getTrainingCourse(id).then((r) => {
            setData(r);
            setLoading(false);
        }).catch((e) => {
            setLoading(false);
        });

        return () => {
        };
    }, [id]);

    return (
        <div className="content">
            <RetoricBreadcrumbs locations={locations}>{data.title}</RetoricBreadcrumbs>
            {!loading &&
                <RetoricCard className={'bg-offwhite card__selector flex flex-col content-stretch self-stretch'}>
                    <span className="text-justify text-lg mb-8 block">{data.shortDescription}</span>
                    <div className={'training__content flex flex-col'}>
                        <div className="self-stretch mb-4" dangerouslySetInnerHTML={{__html: data.description}}></div>
                        {data.attachments && data.attachments.length > 0 &&
                            <AttachmentsDisplay attachments={data.attachments}/>}
                    </div>
                    <span
                        className="h-4 text-right w-full block">Dodany {DateFormatter(data.date)}. Autor: {data.author}</span>
                </RetoricCard>}
            {loading && <RetoricCard className={'bg-offwhite w-full self-stretch'}>
                <Skeleton className="w-full" paragraph={{rows: 0}} active title={true}/>
                <Skeleton className="w-full mb-8" paragraph={{rows: 2}} active title={false}/>
                <div className="flex flex-col gap-4">
                    <Skeleton className="w-full" paragraph={{rows: 4}} active title={false}/>
                    <Skeleton className="w-full" paragraph={{rows: 4}} active title={false}/>
                    <Skeleton className="w-full" paragraph={{rows: 4}} active title={false}/>
                </div>
            </RetoricCard>}

        </div>
    );
}

export default TrainingView;
