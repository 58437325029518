import React, {useEffect, useState} from 'react';
import './AiAssistantRedaction.css';
import {UserService} from "../../../../services/UserService";
import {DatePicker, Divider, Empty, Input, notification, Skeleton, Space, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {
    ClockCircleOutlined,
    DollarCircleOutlined,
    ExclamationCircleOutlined,
    QuestionCircleOutlined,
    UserOutlined
} from "@ant-design/icons";
import {User} from "../../../../types/AuthState";
import {useAuth} from "../../../../extensions/Auth";
import {AdminScopes} from "../../../../types/Scopes";
import RetoricButton from "../../../../designsystems/RetoricButton/RetoricButton";
import RetoricCard from "../../../../designsystems/RetoricCard/RetoricCard";
import RetoricBreadcrumbs from "../../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../../extensions/Locations";
import {AiChatService} from "../../../../services/AiChatService";
import {
    CalculateDifferenceInDays,
    FormatDateMonYear,
    FormatDateSimple,
    GetDate,
    GetFirstOfMonth,
    GetFirstOfThisMonth,
    GetLastOfMonth
} from "../../../../extensions/DateFormatter";
import {AiSettings, TokenUsageWithPrice} from "../../../../types/AiChat";
import pl from "antd/es/date-picker/locale/pl_PL";
import dayjs from "dayjs";
import {SystemService} from "../../../../services/SystemService";
import Infobox from "../../../../designsystems/Infobox/Infobox";

const {RangePicker} = DatePicker;


const searchFilter = (x: User, searchTerm: string) => {
    return x.name.toLowerCase().includes(searchTerm) ||
        x.position.toLowerCase().includes(searchTerm) ||
        x.email.toLowerCase().includes(searchTerm);
}

const GetPayPeriods = () => {
    const periods: any = {
        'Od wczoraj': [dayjs(GetDate(-1)), dayjs(GetDate(0))],
        'Ostatnie 7 dni': [dayjs(GetDate(-7)), dayjs(GetDate(0))],
        'Ostatnie 30 dni': [dayjs(GetDate(-30)), dayjs(GetDate(0))],
        'Obecny okres': [dayjs(GetFirstOfThisMonth()), dayjs(GetDate(0))],
    };

    let currentMonth = new Date().getMonth();
    let currentYear = new Date().getFullYear();

    for (let i = 0; i < 12; i++) {
        if (currentMonth === 0) {
            currentMonth = 12;
            currentYear--;
        }

        currentMonth--;
        const first = GetFirstOfMonth(currentMonth, currentYear);
        periods[`${FormatDateMonYear(first)}`] = [dayjs(first), dayjs(GetLastOfMonth(currentMonth, currentYear))];
    }

    return periods;
}

const IsPayPeriod = (from: Date, to: Date) => {
    return (from.getTime() === GetFirstOfMonth(from.getMonth(), from.getFullYear()).getTime() &&
            to.getTime() === GetLastOfMonth(to.getMonth(), to.getFullYear()).getTime() &&
            from.getMonth() === to.getMonth()) ||
        (from.getTime() === GetFirstOfThisMonth().getTime() &&
            to.getTime() === GetDate(0).getTime());
}

function AiAssistantRedaction() {
    const [userList, setUserList] = useState([] as User[]);
    const [usageList, setUsageList] = useState({} as any);
    const [tokenUsage, setTokenUsage] = useState({} as TokenUsageWithPrice);
    const [fromDate, setFromDate] = useState(GetFirstOfThisMonth());
    const [toDate, setToDate] = useState(GetDate(0));
    const [aiSettings, setAiSettings] = useState({} as AiSettings);
    const [loading, setLoading] = useState({usage: false, total: false, users: false, aiSettings: false});

    const [filteredUserList, setFilteredUserList] = useState([] as User[]);
    const [search, setSearch] = useState('');
    const [api, contextHolder] = notification.useNotification();
    const auth = useAuth();

    const getUsageValue = (property: string, id?: string) => {
        if (!id) return 0;
        if (!usageList) return '';
        if (!usageList[id]) return 0;

        return usageList[id][property] ?? 0;
    }

    const columns: ColumnsType<User> = [
        {
            title: 'Nazwa użytkownika',
            dataIndex: 'name',
            key: 'name',
            render: (value, record) => <span className="block">{value}</span>
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (value, record) => <span className="block">{value}</span>
        },
        {
            title: 'Wykorzystane kredyty (zapytanie)',
            dataIndex: 'organisation',
            key: 'organisation',
            render: (value, record) => <span
                className="block">{getUsageValue('promptTokens', record.id)} ({getUsageValue('promptPrice', record.id)} PLN)</span>
        },
        {
            title: 'Wykorzystane kredyty (odpowiedź)',
            dataIndex: 'position',
            key: 'position',
            render: (value, record) => <span
                className="block">{getUsageValue('completionTokens', record.id)} ({getUsageValue('completionPrice', record.id)} PLN)</span>
        },
        {
            title: 'Całkowity koszt AI',
            dataIndex: 'position',
            key: 'position',
            render: (value, record) => <span
                className="block">{getUsageValue('totalPrice', record.id)} PLN</span>
        },
        // {
        //     title: 'Akcje',
        //     key: 'action',
        //     responsive: ['lg'],
        //     render: (_, record) => (
        //         <Space size="middle">
        //             <Tooltip title="Edytuj użytkownika">
        //                 <EditOutlined onClick={() => auth.navigate(`/users/edit/${record.email}`)}/>
        //             </Tooltip>
        //         </Space>
        //     ),
        // },
    ];

    const searchUsers = (value: any, fromString = false) => {
        const searchTerm = fromString ? value.toLowerCase() : value.target.value.toLowerCase()

        setSearch(searchTerm);

        if (searchTerm === '')
            setFilteredUserList(userList);
        else
            setFilteredUserList(userList.filter(x => searchFilter(x, searchTerm)));
    }

    const refreshUsage = (from: Date, to: Date) => {
        AiChatService
            .getPerUserUsage(from, to)
            .then(x => {
                setUsageList(x);
                setLoading({...loading, usage: false})
            });

        AiChatService
            .getTotalUsage(from, to)
            .then(x => {
                setTokenUsage(x);
                setLoading({...loading, total: false})
            });
    }

    const onDateRangeChange = (dates: any, dateStrings: [string, string]) => {
        const from = dates[0].toDate();
        const to = dates[1].toDate();

        if (from === to)
            return;

        setFromDate(from);
        setToDate(to);
        refreshUsage(from, to);
    }

    const getDurationString = (fromDate: Date, toDate: Date) => {
        const diff = CalculateDifferenceInDays(fromDate, toDate);

        if (diff === 1) return '1 dzień';

        return `${diff} dni`;
    }

    useEffect(() => {
        auth.verifyAuth(AdminScopes.SYSTEM);
        auth.verifyAuth(AdminScopes.USERS);
        setLoading({usage: true, total: true, users: true, aiSettings: true});

        UserService
            .getUsers()
            .then(x => {
                setUserList(x);
                setFilteredUserList(x);

                setLoading({...loading, users: false});
            })
            .catch(e => {
                setLoading({...loading, users: false});
            });

        SystemService
            .getAiSettings()
            .then((res: AiSettings | null) => {
                setLoading({...loading, aiSettings: false});
                if (res) {
                    setAiSettings(res);
                }
            })
            .catch(e => {
                setLoading({...loading, aiSettings: false});
            });

        refreshUsage(fromDate, toDate);

        return () => {
        };
    }, [auth]);

    return (
        <div className="content">
            <RetoricBreadcrumbs locations={Locations['admin/aiassistant']}>Zarządzanie asystentem
                AI</RetoricBreadcrumbs>
            <RetoricCard className={'flex flex-col w-full bg-offwhite'}>
                {contextHolder}
                <div className="mb-4 flex flex-row justify-between">
                    <h1 className="text-lg text-slate-600">Dane
                        z {FormatDateSimple(fromDate)} - {FormatDateSimple(toDate)} ({getDurationString(fromDate, toDate)})</h1>
                    <RangePicker size={'large'} format={'DD.MM.YYYY'}
                                 locale={pl}
                                 value={[dayjs(fromDate), dayjs(toDate)]}
                                 placement={'bottomLeft'}
                                 presets={GetPayPeriods()}
                                 renderExtraFooter={() => <div className="min-w-full text-center">
                                     <span className="text-xs text-slate-600">Okres rozliczeniowy obowiązuje od pierwszego do ostatniego dnia miesiąca</span>
                                 </div>}
                                 onChange={onDateRangeChange}
                                 placeholder={['Okres od', 'Okres do']}
                                 className="rounded-none"/>
                </div>
                {!(loading.aiSettings && loading.total) && <div className="grid grid-cols-1 lg:grid-cols-4 mb-4 gap-4">
                    <Infobox title={'Informacje odnośnie budżetu'} className="lg:col-span-3" rows={[
                        `Każdemu użytkownikowi przysługuje ${aiSettings.budgetPerUser} PLN miesięcznie do wykorzystania na zapytania AI.`,
                        'W momencie, gdy użytkownik przekroczy limit miesięczny, będziesz musiał doładować środki na jego konto lub poczekać do następnego miesiąca.'
                    ]}/>
                    {IsPayPeriod(fromDate, toDate) ?
                        <RetoricCard bordered={false} className="bg-gray-200/40 w-full flex flex-col gap-4">
                            <div>
                                <h2 className="text-lg text-slate-600">Wykorzystanie budżetu</h2>
                            </div>
                            <span
                                className="text-2xl font-light"><ClockCircleOutlined
                                className="mr-2"/> {tokenUsage.totalPrice} / {aiSettings.totalBudget} <span
                                className="text-sm align-super">PLN</span></span>
                        </RetoricCard> :
                        <RetoricCard bordered={false} className="bg-gray-200/40 w-full flex flex-col gap-4">
                            <div>
                                <h2 className="text-lg text-slate-600">Budżet miesięczny</h2>
                            </div>
                            <span
                                className="text-2xl font-light"><ClockCircleOutlined
                                className="mr-2"/> {aiSettings.budgetPerUser} <span
                                className="text-sm align-super">PLN / użytkownik / miesiąc</span></span>
                            <span
                                className="text-lg font-light pl-10">Całkowity: {aiSettings.totalBudget} <span
                                className="text-sm align-super">PLN / miesiąc</span></span>
                        </RetoricCard>}
                </div>}
                {(loading.aiSettings || loading.total) && <Skeleton className="mb-4" active />}
                {!(loading.total && loading.usage) && <div className="grid grid-cols-1 lg:grid-cols-4 mb-4 gap-4">
                    <RetoricCard bordered={false} className="bg-gray-200/40 w-full flex flex-col gap-4">
                        <div>
                            <h2 className="text-lg text-slate-600">Wykorzystane kredyty zapytań</h2>
                        </div>
                        <span
                            className="text-2xl font-light"><QuestionCircleOutlined
                            className="mr-2"/> {tokenUsage.promptTokens} ({tokenUsage.promptPrice} <span
                            className="text-sm align-super">PLN</span>)</span>
                    </RetoricCard>
                    <RetoricCard bordered={false} className="bg-gray-200/40 w-full flex flex-col gap-4">
                        <div>
                            <h2 className="text-lg text-slate-600">Wykorzystane kredyty odpowedzi</h2>
                        </div>
                        <span
                            className="text-2xl font-light"><ExclamationCircleOutlined
                            className="mr-2"/> {tokenUsage.completionTokens} ({tokenUsage.completionPrice} <span
                            className="text-sm align-super">PLN</span>)</span>
                    </RetoricCard>
                    <RetoricCard bordered={false} className="bg-gray-200/40 w-full flex flex-col gap-4">
                        <div>
                            <h2 className="text-lg text-slate-600">Całkowity koszt AI</h2>
                        </div>
                        <span className="text-2xl font-light"><DollarCircleOutlined
                            className="mr-2"/> {tokenUsage.totalPrice} <span
                            className="text-sm align-super">PLN</span></span>
                    </RetoricCard>
                    <RetoricCard bordered={false} className="bg-gray-200/40 w-full flex flex-col gap-4">
                        <div>
                            <h2 className="text-lg text-slate-600">Użytkownicy korzystający z AI</h2>
                        </div>
                        <span className="text-2xl font-light"><UserOutlined
                            className="mr-2"/> {Object.keys(usageList).length}</span>
                    </RetoricCard>
                </div>}
                {(loading.total || loading.usage) && <Skeleton active />}
                <Divider/>
                <div className="flex lg:flex-row flex-col justify-between">
                    <Space className="flex-1 mb-4 lg:justify-start justify-center">
                        <RetoricButton outline className="lg:hidden min-w-[150px]"
                                       onClick={() => auth.navigate('/admin/aiassistant/config')}>Konfiguracja</RetoricButton>
                    </Space>
                    <div className="flex-1 mb-4">
                        <Input.Search placeholder="Wyszukaj użytkownika" allowClear size={'large'}
                                      onChange={(value: any) => searchUsers(value)}
                                      className="search bg-offwhite"/>
                    </div>
                    <div className={'users__new'}></div>
                </div>
                {!loading.users && <Table columns={columns} dataSource={filteredUserList} rowKey={'id'} scroll={{x: 768}}
                       locale={{emptyText: <Empty description={'Brak danych'}/>}}/>}
                {loading.users && <Skeleton active />}
            </RetoricCard>
        </div>
    );
}

export default AiAssistantRedaction;
