import React, {useEffect, useState} from 'react';
import './AiConfiguration.css';
import {useAuth} from "../../../../extensions/Auth";
import {AdminScopes} from "../../../../types/Scopes";
import {Form, Input, message, Skeleton} from "antd";
import {SystemService} from "../../../../services/SystemService";
import Infobox from "../../../../designsystems/Infobox/Infobox";
import RetoricCard from "../../../../designsystems/RetoricCard/RetoricCard";
import RetoricButton from "../../../../designsystems/RetoricButton/RetoricButton";
import RetoricBreadcrumbs from "../../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../../extensions/Locations";
import {AiSettings} from "../../../../types/AiChat";

function AiConfiguration() {
    const auth = useAuth();
    const [loading, setLoading] = useState(true);
    const [aiSettings, setAiSettings] = useState({} as AiSettings);
    const [aiSettingsForm] = Form.useForm();

    const updateAiSettings = () => {
        aiSettingsForm.validateFields().then((values) => {
            const formValues: AiSettings = {...aiSettings, maxCompletionLength: values.maxCompletionLength};

            SystemService.updateAiSettings(formValues).then((x) => {
                if (x) message.success('Zmieniono ustawienia AI.');
                else message.error('Wystąpił błąd podczas zmiany ustawień AI.');

                setAiSettings(formValues);
            });
        });
    }

    useEffect(() => {
        auth.verifyAuth(AdminScopes.SYSTEM);

        SystemService.getAiSettings().then((res: AiSettings | null) => {
            if (res) {
                aiSettingsForm.setFieldsValue({
                    maxCompletionLength: res.maxCompletionLength,
                    budgetPerUser: res.budgetPerUser,
                });

                setLoading(false);
            }
        });
    }, []);
    return (
        <div className="content">
            <RetoricBreadcrumbs locations={Locations['admin/aiassistant/config']}>Konfiguracja AI</RetoricBreadcrumbs>
            <Infobox
                title={'Ustawienia asystenta AI'}
                className="self-stretch"
                rows={[
                    'W tej sekcji możesz zarządzać ustawieniami asystenta AI.'
                ]}
            />
            <RetoricCard className={'self-stretch lg:px-8 px-2'}>
                {!loading && <Form
                    name="site-settings"
                    layout={'vertical'}
                    className={'gap-4 flex flex-col'}
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    style={{maxWidth: 800}}
                    form={aiSettingsForm}
                    onFinish={updateAiSettings}
                    initialValues={{remember: true}}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Maksymalna długość odpowiedzi"
                        help={'wartość w kredytach; 10 kredytów - ~35 znaków'}
                        name="maxCompletionLength"
                        rules={[{required: true, message: 'Nazwa portalu jest wymagana!'}]}
                    >
                        <Input placeholder={'500'} className="rounded-none" type="number"/>
                    </Form.Item>
                    <Form.Item
                        label="Miesięczny budżet AI"
                        help={'wartość w polskich złotych na jednego użytkownika'}
                        name="budgetPerUser"
                    >
                        <Input disabled placeholder={'3.5 PLN na użytkownika'} className="rounded-none"
                               type="number"/>
                    </Form.Item>
                    <Form.Item>
                        <RetoricButton type="primary" htmlType="submit">
                            Zapisz
                        </RetoricButton>
                    </Form.Item>
                </Form>}
                {loading && <Skeleton active/>}
            </RetoricCard>
        </div>
    );
}

export default AiConfiguration;
