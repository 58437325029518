import RetoricBreadcrumbs from "../../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../../extensions/Locations";
import React, {useEffect, useState} from "react";
import RetoricCard from "../../../../designsystems/RetoricCard/RetoricCard";
import {InfoCircleTwoTone} from "@ant-design/icons";
import {useParams} from "react-router-dom";
import {useAuth} from "../../../../extensions/Auth";
import './ModuleInDevelopment.css';

const modules = [
    {
        name: 'signalizer',
        displayName: 'Sygnalizer',
        expectedCompletion: 'Listopad 2023',
        shortDescription: <p className="text-lg">Moduł pozwalający na tworzenie anonimowych zgłoszeń i monitorowanie ich
            statusu.</p>,
        description: <div className="text-lg">
            <p className="mb-4">Dzięki tej funkcji Twoi pracownicy będą mogli złożyć anonimowe zgłoszenia do pracodawcy.
                Moduł pozwala na monitorowanie postępu zgłoszeń i anonimową komunikację z pracownikiem.</p>
            <p>
                Funkcja ta jest zgodna z Dyrektywą Parlamentu Europejskiego i Rady(UE) 2019/1937 z dnia 23 października
                2019 r. w
                sprawie ochrony osób zgłaszających naruszenia prawa Unii (Dz. Urz. UE L 305 z 26.11.2019).</p>
        </div>
    },
    {
        name: 'kanban',
        displayName: 'Kanban',
        expectedCompletion: 'Listopad/Grudzień 2023',
        shortDescription: <p className="text-lg">Moduł pozwalający na zarządzanie zadaniami w formie tablicy
            Kanban.</p>,
        description: <div className="text-lg">
            <p className="mb-4">Dzięki tej funkcji Twoi pracownicy będą mogli zarządzać zadaniamiutworzonymi za pomocą
                modułu Zadań z użyciem tablicy Kanban.</p>
            <p>
                Moduł pozwala na dzielenia pracowników na zespoły, następnie zespołymogą utworzyć projekty nad którymi
                obecnie pracują. Tablica Kanban zapewnia transparentność projektu i możliwość śledzenia postępów
                nadpracami.</p>
        </div>
    },
    {
        name: 'geotool',
        displayName: 'Geotool',
        expectedCompletion: 'Marzec 2024',
        shortDescription: <p className="text-lg">Moduł pozwalający na zwiększenie wpływów z podatków od
            nieruchomości.</p>,
        description: <div className="text-lg">
            <p>Moduł ten jest dedykowany dla urzędów miejskich, w celu uzyskania szczegółowych informacji na temat
                funkcjonalności, skontaktuj się z nami za pomocą <a
                    href={"mailto:kontakt@retoric.pl"}>kontakt@retoric.pl</a></p>
        </div>
    },
    {
        name: 'calendar',
        displayName: 'Kalendarz',
        expectedCompletion: 'Styczeń 2024',
        shortDescription: <p className="text-lg">Moduł kalendarza pozwala na tworzenie i zarządzanie personalnym
            kalendarzem.
            Będzie on zintegrowany z innymi częściami intranetu tj. zadaniami lub urlopem.</p>,
        description: <div className="text-lg">
            <p>Funkcja pozwala na zarządzanie kalendarzami indywidualnymi i zespołowymi. Dodatkowo, jest on zintegrowany
                z innymi modułami dostępnymi w ramach intranetu tj.:</p>
            <ul className="mb-4">
                <li>zadania</li>
                <li>urlop</li>
                <li>delegacje</li>
                <li>zamówienia</li>
            </ul>
            <p className="text-lg">Dodatkowo, będzie on
                pozwalał na integracje z zewnętrznymi kalendarzami takimi jak Google czy Office 365.</p>
        </div>
    },
    {
        name: 'delegations',
        displayName: 'Delegacje',
        expectedCompletion: 'Listopad 2023',
        shortDescription: <p className="text-lg">Moduł pozwalający pracownikom na tworzenie delegacji, automatyczne
            wyliczanie dystansu i kosztów oraz przygotowywanie dokumentów w formacie PDF gotowych do druku</p>,
        description: <div className="text-lg">
            <p>Dzięki tej funkcji pracownicy mogą w bardzo łatwy i szybki sposób tworzyć druki delegacji. System na
                podstawie podanych punktów trasy obliczy dystans oraz koszty, a następnie automatycznie
                przygotuje wypełniony dokument delegacji w formacie PDF</p>
        </div>
    },
    {
        name: 'orders',
        displayName: 'Zamówienia',
        expectedCompletion: 'Listopad/Grudzień 2023',
        shortDescription: <p className="text-lg">Moduł pozwalający na prowadzenie procesów zamówieniowych w Twojej
            organizacji.</p>,
        description: <div className="text-lg">
            <p>Moduł pozwala na tworzenie i zarządzanie procesami zamówieniowymi w Twojej firmie. Dodatkowo, dzięki temu
                modułowi możesz zarządzać kontrahentami oraz prowadzić procesy ofertowe</p>
        </div>
    },
    {
        name: 'tasks',
        displayName: 'Zadania',
        expectedCompletion: 'Listopad 2023',
        shortDescription: <p className="text-lg">Moduł pozwalający na tworzenie zadań i monitorowanie ich postępu.</p>,
        description: <div className="text-lg">
            <p>Dzięki tej funkcji Twoi pracownicy mogą tworzyć zadania, które obecnie wykonują. W zależności od
                konfiguracji i uprawnień, pracownicy mogą tworzyć zadania dla swoich współpracowników, monitorować ich
                postęp, dodawać szczegóły dotyczące zadań oraz prowadzić konwersacje ze współpracownikami</p>
        </div>
    },
]

export interface ModuleInDevelopmentProps {
    name?: string
}

export default function ModuleInDevelopment({name}: ModuleInDevelopmentProps) {
    const {moduleName} = useParams();
    const auth = useAuth();
    const [currentModule, setCurrentModule] = useState(modules.find(m => m.name === moduleName) || null);

    useEffect(() => {
        if (!moduleName && !name) {
            auth.navigate('/modules');
            return;
        }

        const module = modules.find(m => m.name === name || m.name === moduleName);

        if (!module) {
            auth.navigate('/modules');
            return;
        }

        setCurrentModule(module);

        return () => {
        };
    }, [auth]);
    return (
        <div className="content modules">
            <RetoricBreadcrumbs locations={Locations['modules']}>{currentModule?.displayName ?? 'Nieznany'} - dostępny
                wkrótce</RetoricBreadcrumbs>
            <RetoricCard className="bg-none self-stretch justify-center items-center flex flex-col">
                <InfoCircleTwoTone className="text-4xl mb-8"/>
                <h1 className="text-2xl mb-4">Ten moduł nie jest jeszcze gotowy</h1>
                <p className="text-lg mb-8">Obecnie trwają prace programistyczne nad tym modułem. Administrator systemu
                    zostanie poinformowany, gdy będzie on gotowy!</p>
                <div className="grid lg:grid-cols-3 grid-cols-1 gap-4 self-stretch">
                    <RetoricCard className="bg-offwhite flex flex-col">
                        <h1 className="text-xl mb-4">{currentModule?.displayName}</h1>
                        {currentModule?.shortDescription}
                        <p className={'text-lg mt-auto mx-auto font-medium'}>Oczekiwana data
                            ukończenia: {currentModule?.expectedCompletion}</p>
                    </RetoricCard>
                    <RetoricCard className="bg-offwhite lg:col-span-2 flex flex-col">
                        <h1 className="text-xl mb-4">Opis modułu</h1>
                        {currentModule?.description}
                    </RetoricCard>
                </div>
            </RetoricCard>
        </div>)
}
