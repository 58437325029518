import React, {useEffect, useState} from 'react';
import './InfoEdit.css';
import {useAuth} from "../../../../extensions/Auth";
import {AdminScopes} from "../../../../types/Scopes";
import {InfopageEditProps} from "./InfoEdit";
import {notification} from "antd";
import {InfopageBasic} from "../../../../types/InfopageModels";
import {Editor} from "react-draft-wysiwyg";
import {EditorState} from "draft-js";
import {convertToHTML} from "draft-convert";
import RetoricButton from "../../../../designsystems/RetoricButton/RetoricButton";
import {InfopageService} from "../../../../services/InfopageService";
import {ErrorMessages} from "../../../../extensions/ErrorMessages";
import RetoricCard from "../../../../designsystems/RetoricCard/RetoricCard";
import {FromHtmlConfig, HtmlConverter} from "../../News/NewsEdit/NewsEdit";
import {uploadCallback} from "../../../UserView/News/NewsAdd/NewsAdd";
import {Attachment} from "../../../../types/StaticAsset";
import {BACKEND_BASE} from "../../../../services/LoginService";
import AttachmentsDragger from "../../../../components/AttachmentsDragger/AttachmentsDragger";

function BasicInfopageEdit({infopage}: InfopageEditProps) {
    const auth = useAuth();
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    const [htmlContent, setHtmlContent] = useState('');
    const [attachments, setAttachments] = useState([] as Attachment[]);
    const [fileList, setFileList] = useState([] as any[]);
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        auth.verifyAuth(AdminScopes.INFOPAGE);
        const ip = infopage as InfopageBasic;

        setHtmlContent(ip.htmlContent || '');
        const result = HtmlConverter(ip.htmlContent || '');
        setEditorState(EditorState.createWithContent(result));

        setAttachments(ip.attachments?.map(x => {
            return {
                uploadId: x.id,
                asset: x
            }
        }) ?? []);
        setFileList(ip.attachments?.map(x => {
            return {
                uid: x.id,
                name: x.friendlyName,
                status: 'done',
                url: `${BACKEND_BASE}/static/${x.name}`
            }
        }) ?? []);

        return () => {
        };
    }, [auth, infopage]);

    const updateEditorState = (editorState: EditorState) => {
        const htmlContent = convertToHTML(FromHtmlConfig)(editorState.getCurrentContent());
        setEditorState(editorState);
        setHtmlContent(htmlContent);
    }


    const publish = () => {
        const payload = {
            htmlContent,
            attachments: attachments.map(x => x.asset)
        };

        InfopageService.setBasicInfopageContent(infopage.id, payload)
            .then((x) => {
                api.info({
                    message: `Zaktualizowano stronę`,
                    description: 'Treść strony została zaktualizowana!',
                    placement: 'top'
                });
            })
            .catch((e) => {
                let error = "Twoje uprawnienia nie pozwalają na zaktualizowanie strony!"

                if (e.message === ErrorMessages.notFound) {
                    error = 'Nie znaleziono strony!'
                }

                api.error({
                    message: `Wystąpił błąd`,
                    description: error,
                    placement: 'top'
                });
            });
    }

    return (
        <RetoricCard className="min-h-full min-w-full flex flex-col justify-stretch items-center gap-4">
            {contextHolder}
            <Editor
                editorState={editorState}
                locale={'pl'}
                wrapperClassName="border border-gray-200 bg-offwhite min-h-[30vh] mb-4 flex-1 min-w-full"
                editorClassName="px-4 bg-offwhite"
                toolbar={{
                    image: {
                        uploadCallback: uploadCallback,
                        uploadEnabled: true,
                        previewImage: true,
                        alt: {present: true, mandatory: false},
                        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg,image/svg+xml,image/webp',
                    }
                }}
                onEditorStateChange={updateEditorState}
            />
            <AttachmentsDragger attachments={attachments} setAttachments={setAttachments} fileList={fileList}
                                setFileList={setFileList}/>
            <RetoricButton type={'primary'} onClick={publish} className="min-w-[200px]">Zapisz zmiany</RetoricButton>
        </RetoricCard>
    );
}

export default BasicInfopageEdit;
