import {StaticAsset} from "./StaticAsset";

export interface Infopage {
    id: string;
    title: string;
    type: InfopageType;
    isHidden: boolean;
    order: number;
    categoryId?: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface InfopageRequest {
    title: string;
    type: InfopageType;
    isHidden: boolean;
    categoryId?: string;
}

// Phone extensions
export interface InfopagePhoneExtensions extends Infopage {
    entries: PhoneExtensionEntry[]
}

export interface PhoneExtensionEntry {
    id: string;
    title: string;
    extensions: PhoneExtension[];
}

export interface PhoneExtension {
    id: string;
    title: string;
    extensions: ContactRecord[];
}

export interface ContactRecord {
    value: string;
    type: ContactRecordType;
}

export enum ContactRecordType {
    Phone = 0, Email = 1, Web = 2
}

export interface PhoneExtensionEntryRequest {
    title: string;
}

export interface PhoneExtensionRequest {
    title: string;
    extensions: ContactRecord[];
}

// Basic
export interface InfopageBasic extends Infopage {
    htmlContent: string;
    attachments?: StaticAsset[];
}

// Training
export interface InfopageTraining extends Infopage {
    entries: InfopageTrainingEntry[]
}

export interface InfopageTrainingEntry {
    id: string;
    title: string;
    date: Date;
    description: string;
    trainingProvider: string;
    location: string;
    infoUrl: string;
}

// Changelog
export interface InfopageChangelog extends Infopage {
    entries: InfopageChangelogEntry[]
}

export interface InfopageChangelogEntry {
    id: string;
    title: string;
    date: Date;
    description: string;
}

// Category
export interface InfopageCategory {
    id: string;
    title: string;
    order: number;
    infopages: Infopage[];
    createdAt: Date;
    updatedAt: Date;
}

export interface InfopageCategoryRequest {
    title: string;
    order?: number;
}

export enum InfopageType {
    PhoneExtensions = 0, Basic = 1, Training = 2, Changelog = 3
}
