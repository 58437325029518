import Locations from "../../../../extensions/Locations";
import React, {useEffect, useState} from "react";
import RetoricBreadcrumbs from "../../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import {Calendar, DatePicker, Descriptions, Drawer, message, Tag, Tooltip} from "antd";
import locale from "antd/es/date-picker/locale/pl_PL";
import {
    FormatMonthDate,
    PastelColours
} from "../../../UserView/Calendar/RetoricCalendar";
import {
    CarOutlined, CheckOutlined, CloseOutlined,
    LeftOutlined,
    QuestionOutlined,
    RightOutlined
} from "@ant-design/icons";
import ClickableElement from "../../../../components/ClickableElement/ClickableElement";
import dayjs from "dayjs";
import {HolidayUserEntry} from "../../../../types/AuthState";
import ManagerService from "../../../../services/ManagerService";
import localeDayJs from 'dayjs/locale/pl';
import {HolidayDescriptionMap} from "../../../../extensions/DateFormatter";
import {ColorMap} from "../UserHolidays/UserHolidays";

export const HolidayDescriptionShortMap = (value: number) => {
    switch (value) {
        case 0:
            return <QuestionOutlined/>;
        case 1:
            return <CarOutlined />;
        case 2:
            return <CloseOutlined />;
        case 3:
            return <CheckOutlined />;
        default:
            return '';
    }
}
function HolidayCalendar() {
    const [messageApi, contextHolder] = message.useMessage();
    const [currentMonth, setCurrentMonth] = useState(dayjs());
    const [holidayList, setHolidayList] = useState([] as HolidayUserEntry[]);
    const [drawer, setDrawer] = useState<HolidayUserEntry | null>(null);

    const showDrawer = (event: HolidayUserEntry) => {
        setDrawer(event);
    };

    const onClose = () => {
        setDrawer(null);
    };

    useEffect(() => {
        ManagerService
            .getHolidayByPeriod(currentMonth.startOf('month').subtract(7, 'days'), currentMonth.endOf('month').add(7, 'days'))
            .then(x => {
                const entries = [] as HolidayUserEntry[];

                for (const holidayUser of x) {
                    for (const holiday of holidayUser.holidayEntries) {
                        entries.push({
                            id: holiday.id,
                            name: holidayUser.name,
                            email: holidayUser.email,
                            firstName: holidayUser.firstName,
                            lastName: holidayUser.lastName,
                            holidayAllowance: holidayUser.holidayAllowance,
                            startDate: dayjs(holiday.startDate),
                            endDate: dayjs(holiday.endDate),
                            duration: holiday.duration,
                            substitution: holiday.substitution,
                            status: holiday.status,
                            organisation: holiday.organisation,
                            position: holiday.position,
                            forYear: holiday.forYear,
                            city: holiday.city,
                            colour: holiday.colour ?? PastelColours[Math.floor(Math.random() * 20) % PastelColours.length]
                        });
                    }
                }

                entries.sort((a, b) => {
                    if (a.startDate.isBefore(b.startDate)) {
                        return -1;
                    }

                    if (a.startDate.isAfter(b.startDate)) {
                        return 1;
                    }

                    return 0;

                });

                setHolidayList(entries);
            })
            .catch(e => {
                messageApi.error('Nie udało się pobrać danych');
            });

        return () => {
        }
    }, [currentMonth]);

    return (
        <div className="content">
            <RetoricBreadcrumbs locations={Locations['manager/holidays']}>Kalendarz urlopów</RetoricBreadcrumbs>
            <div className="min-w-full flex lg:flex-row flex-col lg:justify-between justify-stretch px-4 lg:px-0 gap-4">
                <Calendar
                    locale={locale}
                    value={currentMonth}
                    onChange={(date) => setCurrentMonth(date)}
                    headerRender={({value, type, onChange, onTypeChange}) =>
                        <div className="w-full flex lg:flex-row flex-col justify-between gap-2 items-center">
                            <div className="text-lg font-light">
                                {FormatMonthDate(value)}
                            </div>
                            <div className="flex flex-row gap-2">
                                <Tooltip title="Poprzedni miesiąc">
                                    <button onClick={() => setCurrentMonth(currentMonth.subtract(1, 'month').date(1))}>
                                        <LeftOutlined/></button>
                                </Tooltip>
                                <DatePicker
                                    locale={locale}
                                    value={value}
                                    picker="month"
                                    allowClear={false}
                                    onChange={(x) => setCurrentMonth(x!)}/>
                                <Tooltip title="Następny miesiąc">
                                    <button onClick={() => setCurrentMonth(currentMonth.add(1, 'month').date(1))}>
                                        <RightOutlined/></button>
                                </Tooltip>
                            </div>
                        </div>
                    }
                    dateCellRender={(value) => {
                        const dayEvents = holidayList.filter(x =>
                            x.startDate.isSame(value, 'day') ||
                            (value.isAfter(x.startDate) && value.isBefore(x.endDate)));

                        if (holidayList.length > 0) {
                            return <div className="flex flex-col gap-1 justify-start items-start">
                                {dayEvents.map((event, index) =>
                                    <ClickableElement
                                        action={() => showDrawer(event)}
                                        className="min-w-full text-gray-700/75 hover:text-gray-800 text-left flex flex-row items-center gap-1 text-xs"
                                        style={{background: event.colour ?? '#D0D0D0', padding: '1px 2px 1px 10px'}}
                                        key={event.id}>
                                        <div className="flex flex-col">
                                            <Tag color={ColorMap[event.status]} style={{paddingLeft: 2, paddingRight: 2, lineHeight: '14px', fontSize: 10}}>{HolidayDescriptionShortMap(event.status)}</Tag>
                                        </div>
                                        <div>
                                            <span className="text-sm">{event.name}</span>
                                        </div>
                                    </ClickableElement>)
                                }
                            </div>
                        }

                        return null;
                    }}
                />
            </div>
            <Drawer width={640} placement="right" closable={false} onClose={onClose} open={drawer !== null}>
                {drawer !== null && <div className="flex flex-col gap-4">
                    <div
                        className="flex flex-col 2xl:flex-row 2xl:justify-between 2xl:items-center justify-stretch items-start">
                        <div className="w-full">

                            <div className="flex flex-row items-center">
                                <Tag color={ColorMap[drawer.status]}>{HolidayDescriptionMap(drawer.status)}</Tag>
                                <h3 className="text-lg font-inter mb-1">
                                    {drawer!.name}
                                </h3>
                            </div>
                            <div className="flex flex-row gap-1">
                            <span
                                className={"capitalize font-light"}>
                                {(drawer!.startDate.isSame(drawer!.endDate, 'day') ?
                                    `${drawer!.startDate.locale(localeDayJs).format("DD MMMM YYYY")}` :
                                    `${drawer!.startDate.locale(localeDayJs).format("DD MMMM YYYY")} - ${drawer!.endDate.locale(localeDayJs).format("DD MMMM YYYY")}`)}
                            </span>
                            </div>
                        </div>
                    </div>
                    <Descriptions bordered>
                        <Descriptions.Item label="Imię" span={3}>{drawer.firstName}</Descriptions.Item>
                        <Descriptions.Item label="Nazwisko" span={3}>{drawer.lastName}</Descriptions.Item>
                        <Descriptions.Item label="Email" span={3}><a href={`mailto:${drawer.email}`}>{drawer.email}</a></Descriptions.Item>
                        <Descriptions.Item label="Dział" span={3}>{drawer.organisation}</Descriptions.Item>
                        <Descriptions.Item label="Stanowisko" span={3}>{drawer.position}</Descriptions.Item>
                        <Descriptions.Item label="Zastępstwo" span={3}>{drawer.substitution ?? 'Brak'}</Descriptions.Item>
                        <Descriptions.Item label="Długość urlopu" span={3}>{drawer.duration}</Descriptions.Item>
                        <Descriptions.Item label="Roczny przydział urlopu" span={3}>{drawer.holidayAllowance}</Descriptions.Item>
                    </Descriptions>
                </div>}
            </Drawer>
            {contextHolder}
        </div>
    );
}

export default HolidayCalendar;
