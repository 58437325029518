export interface SystemSettings {
    authenticationMode: AuthenticationMode;
    ldapServer: LdapServer;
    accountSettings: AccountSettings;
}

export interface LdapServer {
    hostname: string;
    port: number;
    baseDn: string;
    userDn: string;
    ldapVersion: number;
}

export enum AuthenticationMode {
    Local = 0,
    Ldap = 1,
    Hybrid = 2
}

export enum SearchEngineType {
    None = 0,
    Google = 1,
    Bing = 2,
    Duck = 3,
    Brave = 4,
}

export interface SiteSettings {
    title: string;
    logo: string;
    logoDark: string;
    integratedSearchEngine: SearchEngineType;
}

export interface AccountSettings {
    passwordExpiry: number;
    minimumPasswordLength: number;
    controllableProperties: number[];
    isSelfHolidayManagementEnabled: boolean;
}
