import locale from "antd/es/date-picker/locale/pl_PL";
import {Avatar, Badge, Calendar, DatePicker, Divider, Drawer, message, Spin, Tag, Tooltip} from "antd";
import localeDayJs from 'dayjs/locale/pl';
import React, {useEffect, useState} from "react";
import {
    CalendarEvent,
    CalendarEventLocationType, CalendarMonthProps, CalendarPriorityColourMapper, CalendarPriorityMapper,
    CalendarProps,
    CalendarTitleMapper,
    FormatMonthDate
} from "../RetoricCalendar";
import dayjs from "dayjs";
import {DeleteOutlined, EditOutlined, LeftOutlined, RightOutlined} from "@ant-design/icons";
import ClickableElement from "../../../../components/ClickableElement/ClickableElement";
import {
    GenerateInitials,
    ShortDurationFormatter,
    ShortDurationWholeDayFormatter
} from "../../../../extensions/Calendar";
import CalendarService from "../../../../services/CalendarService";
import ConfirmationModal, {ConfirmationModalProps} from "../../../../designsystems/ConfirmationModal/ConfirmationModal";
import EditMeetingModal, {EditMeetingModalPayload} from "../EditMeetingModal/EditMeetingModal";
import {useAuth} from "../../../../extensions/Auth";

export default function CalendarMonthView({modalState}: CalendarMonthProps) {
    const auth = useAuth();
    const [currentMonth, setCurrentMonth] = useState(dayjs());
    const [events, setEvents] = useState([] as CalendarEvent[]);
    const [drawer, setDrawer] = useState<CalendarEvent | null>(null);
    const [editMeetingModalOpen, setEditMeetingModalOpen] = useState(false);
    const [editModalPayload, setEditModalPayload] = useState({} as EditMeetingModalPayload)
    const [eventDeleteModal, setEventDeleteModal] = useState(false);
    const [eventDeleteModalPayload, setEventDeleteModalPayload] = useState({} as ConfirmationModalProps);
    const [loading, setLoading] = useState(false);

    const getEvents = (day: dayjs.Dayjs) => {
        const start = day.date(1).hour(0).minute(0).second(0).millisecond(0).subtract(7, 'day');
        const end = day.endOf('month').hour(23).minute(59).second(59).millisecond(999).add(7, 'day');

        CalendarService
            .getEvents(start.toDate(), end.toDate())
            .then(x => {
                x.sort((a, b) => {
                    if (a.date.isWholeDay && !b.date.isWholeDay) return -1;
                    if (!a.date.isWholeDay && b.date.isWholeDay) return 1;
                    if(a.priority === b.priority) return 0;

                    return a.priority > b.priority ? -1 : 1;
                });

                setEvents(x);
                setLoading(false);
            })
            .catch(x => {
                message.error("Wystąpił błąd podczas pobierania wydarzeń");
                setLoading(false);
            });
    }

    const editMeeting = (event: CalendarEvent) => {
        setEditModalPayload({id: event.id});
        setEditMeetingModalOpen(true);
    }

    const deleteEvent = (id: string) => {
        CalendarService
            .deleteEvent(id)
            .then(() => {
                message.success("Usunięto wydarzenie");
                getEvents(currentMonth);
            })
            .catch(() => {
                message.error("Wystąpił błąd podczas usuwania wydarzenia");
            });
    }

    const showDrawer = (event: CalendarEvent) => {
        setDrawer(event);
    };

    const onClose = () => {
        setDrawer(null);
    };

    const onUpdate = () => {
        getEvents(currentMonth);
        setEditModalPayload({} as EditMeetingModalPayload);
        onClose();
    }

    useEffect(() => {
        setLoading(true)
        getEvents(currentMonth);

        return () => {
        }
    }, [currentMonth]);

    useEffect(() => {
        getEvents(currentMonth);

        return () => {
        }
    }, [modalState]);

    return (<div>
        <Spin spinning={loading} size="large" tip="Pobieranie wydarzeń...">
            <Calendar
                locale={locale}
                value={currentMonth}
                onChange={(date) => setCurrentMonth(date)}
                headerRender={({value, type, onChange, onTypeChange}) =>
                    <div className="w-full flex lg:flex-row flex-col justify-between gap-2 items-center">
                        <div className="text-lg font-light">
                            {FormatMonthDate(value)}
                        </div>
                        <div className="flex flex-row gap-2">
                            <Tooltip title="Poprzedni miesiąc">
                                <button onClick={() => setCurrentMonth(currentMonth.subtract(1, 'month').date(1))}>
                                    <LeftOutlined/></button>
                            </Tooltip>
                            <DatePicker
                                locale={locale}
                                value={value}
                                picker="month"
                                allowClear={false}
                                onChange={(x) => setCurrentMonth(x!)}/>
                            <Tooltip title="Następny miesiąc">
                                <button onClick={() => setCurrentMonth(currentMonth.add(1, 'month').date(1))}>
                                    <RightOutlined/></button>
                            </Tooltip>
                        </div>
                    </div>
                }
                cellRender={(value) => {
                    const dayEvents = events.filter(x =>
                        x.date.start.isSame(value, 'day') ||
                        (x.date.isWholeDay && value.isAfter(x.date.start) && value.isBefore(x.date.end.add(1, 'day'))));

                    if (events.length > 0) {
                        return <div className="flex flex-col gap-1 justify-start items-start">
                            {dayEvents.map((event, index) =>
                                event.date.isWholeDay ?
                                    <ClickableElement
                                        action={() => showDrawer(event)}
                                        className="min-w-full text-gray-700/75 hover:text-gray-800 text-left flex flex-row gap-1 text-xs"
                                        style={{background: event.colour, padding: '1px 2px 1px 10px'}}
                                        key={event.id}>
                                        <div className="flex flex-col">
                                            <span>{ShortDurationWholeDayFormatter(event.date.start, event.date.end)}</span>
                                        </div>
                                        <div>
                                            <span>{event.title}</span>
                                        </div>
                                    </ClickableElement>
                                    :
                                    <ClickableElement action={() => showDrawer(event)}
                                                      key={event.id}
                                                      className="text-gray-700/75 hover:text-gray-800 min-w-full text-left flex flex-row gap-1 text-xs">
                                        <Badge color={`${event.colour}`}/>
                                        <div className="flex flex-col">
                                            <span>{event.date.start.locale(localeDayJs).format("HH:mm")}</span>
                                            <span>{ShortDurationFormatter(event.date.start, event.date.end)}</span>
                                        </div>
                                        <div>
                                            <span>{event.title}</span>
                                        </div>
                                    </ClickableElement>
                            )}
                        </div>
                    }
                }}
            />
        </Spin>
        <Drawer width={640} placement="right" closable={false} onClose={onClose} open={drawer !== null}>
            {drawer !== null && <div>
                <div
                    className="flex flex-col 2xl:flex-row 2xl:justify-between 2xl:items-center justify-stretch items-start">
                    <div className="w-full">

                        <div className="flex flex-row items-center">
                            <Tag color={CalendarPriorityColourMapper(drawer!.priority)} className="block">
                                {CalendarPriorityMapper(drawer!.priority)}
                            </Tag>
                            <h3 className="text-lg font-inter mb-1">
                                {drawer!.title}
                            </h3>
                        </div>
                        <div className="flex flex-row gap-1">
                            <span
                                className={"capitalize font-light"}>
                                {!drawer!.date.isWholeDay && (drawer!.date.start.isSame(drawer!.date.end, 'day') ?
                                    `${drawer!.date.start.locale(localeDayJs).format("DD MMMM YYYY HH:mm")} - ${drawer!.date.end.locale(localeDayJs).format("HH:mm")}` :
                                    `${drawer!.date.start.locale(localeDayJs).format("DD MMMM YYYY HH:mm")} - ${drawer!.date.end.locale(localeDayJs).format("DD MMMM YYYY HH:mm")}`)}
                                {drawer!.date.isWholeDay && (drawer!.date.start.isSame(drawer!.date.end, 'day') ?
                                    `${drawer!.date.start.locale(localeDayJs).format("DD MMMM YYYY")}` :
                                    `${drawer!.date.start.locale(localeDayJs).format("DD MMMM YYYY")} - ${drawer!.date.end.locale(localeDayJs).format("DD MMMM YYYY")}`)}
                            </span>
                            <span className="font-light">({CalendarTitleMapper(drawer!.eventType)})</span>
                        </div>
                    </div>
                    <div
                        className="flex flex-row gap-4 2xl:justify-end justify-between items-center w-full">
                        <div>
                            <p className="text-lg font-light">Organizator</p>
                            <div className="flex flex-row justify-start items-center gap-1">
                                <Avatar
                                    size="small"
                                    shape="square"
                                    className={`align-middle text-slate-600`}
                                    style={{background: `${drawer!.organiser.colour}`}}>
                                    {GenerateInitials(drawer!.organiser.name)}
                                </Avatar>
                                <span className="text-lg font-light">{drawer!.organiser.name}</span>
                            </div>
                        </div>
                        {drawer!.organiser.id == auth?.user?.id && <div className="min-h-full flex flex-row gap-2">
                            <Tooltip title="Edytuj wydarzenie">
                                <EditOutlined className="text-xl hover:text-brandColor-800"
                                              onClick={() => editMeeting(drawer)}/>
                            </Tooltip>
                            <Tooltip title="Usuń wydarzenie">
                                <DeleteOutlined className="text-xl hover:text-brandColor-800" onClick={() => {
                                    const payload = {
                                        title: 'Usuń wydarzenie',
                                        open: true,
                                        setOpen: setEventDeleteModal,
                                        yesAction: () => {
                                            deleteEvent(drawer!.id)
                                            onClose();
                                            setEventDeleteModal(false);
                                        },
                                        noAction: () => setEventDeleteModal(false),
                                    } as ConfirmationModalProps;

                                    payload.infoboxTitle = "Wybrane wydarzenie zostanie usunięte"
                                    payload.children = <>
                                        <p className="text-slate-600">Usunięte wydarzenie nie może zostać przywrócone i
                                            zostanie usunięte dla wszystkich uczestników.</p>
                                    </>

                                    setEventDeleteModalPayload(payload);
                                    setEventDeleteModal(true);
                                }}/>
                            </Tooltip>
                        </div>}
                    </div>
                </div>
                <div>
                    <Divider orientation="left"
                             className="after:bg-slate-300 before:bg-slate-300"
                             orientationMargin={4}
                    >
                        <span className="text-lg font-light mb-2">Uczestnicy</span>
                    </Divider>
                    <div className="flex flex-row flex-wrap gap-2">
                        {drawer!.attendees.map((user, index) =>
                            <div key={`user-${index}`} className="flex flex-row justify-center items-center gap-1">
                                <Avatar
                                    className={`align-middle text-slate-600`}
                                    style={{background: `${user.colour}`}}
                                    size="small">
                                    {GenerateInitials(user.name)}
                                </Avatar>
                                <span className="text-md font-light">{user.name}</span></div>)}
                        {drawer!.attendees.length === 0 &&
                            <p><span className={"text-gray-700"}>Brak uczestników</span></p>}
                    </div>
                    <Divider orientation="left"
                             className="after:bg-slate-300 before:bg-slate-300"
                             orientationMargin={4}
                    >
                        <span className="text-lg font-light mb-2">Lokalizacja</span>
                    </Divider>
                    {drawer!.location.type === CalendarEventLocationType.InPerson && drawer!.location.address &&
                        <p>{drawer!.location.address}</p>}
                    {drawer!.location.type === CalendarEventLocationType.InPerson && !drawer!.location.address && <p>
                        <span className="text-gray-700">Stacjonarna (brak szczegółów)</span></p>}
                    {drawer!.location.type === CalendarEventLocationType.Online && drawer!.location.link &&
                        <p><a href={drawer!.location.link}>{drawer!.location.link}</a></p>}
                    {drawer!.location.type === CalendarEventLocationType.Online && !drawer!.location.link && <p><span
                        className={"text-gray-700"}>Online (brak szczegółów)</span></p>}
                    {drawer!.location.type === CalendarEventLocationType.None && <p>Brak</p>}
                    <Divider orientation="left"
                             className="after:bg-slate-300 before:bg-slate-300"
                             orientationMargin={4}
                    >
                        <span className="text-lg font-light mb-2">Opis wydarzenia</span>
                    </Divider>
                    <p>{drawer!.description?.long || <span className={"text-gray-700"}>Brak opisu</span>}</p>
                </div>
            </div>}
        </Drawer>
        <EditMeetingModal open={editMeetingModalOpen} setOpen={setEditMeetingModalOpen} payload={editModalPayload}
                          onUpdate={() => onUpdate()}/>
        <ConfirmationModal {...eventDeleteModalPayload} open={eventDeleteModal} setOpen={setEventDeleteModal}/>
    </div>)
}
