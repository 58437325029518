import React, {ChangeEvent, useEffect} from 'react';
import './Modules.css';
import {
    BellOutlined,
    CarOutlined,
    CodeOutlined,
    GlobalOutlined,
    HomeOutlined,
    OrderedListOutlined,
    ProjectOutlined,
    ShoppingCartOutlined
} from "@ant-design/icons";
import {useAuth} from "../../../extensions/Auth";
import RetoricModuleCard, {RetoricModuleCardProps} from "../../../designsystems/RetoricModuleCard/RetoricModuleCard";
import RetoricBreadcrumbs from "../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../extensions/Locations";
import {Input, message} from "antd";
import {ErrorMessages} from "../../../extensions/ErrorMessages";
import SignalistService from "../../../services/SignalistService";

function Modules() {
    const auth = useAuth();
    const [filteredModules, setFilteredModules] = React.useState([] as RetoricModuleCardProps[]);

    const openSignalist = () => {
        SignalistService.getToken()
            .then((intranetAuth) => {
                if (!intranetAuth.token) {
                    message.error("Nie masz uprawnień do korzystania z Retoric Signalist");
                    return;
                }

                window.open(`${intranetAuth.url}/?auth=${intranetAuth.token}`, '_blank');
            })
            .catch(e => {
                if (e.message === ErrorMessages.forbidden) {
                    message.error("Nie masz uprawnień do korzystania z Retoric Signalist");
                    return;
                }
                message.error("Wystąpił błąd podczas otwierania Retoric Signalist, spróbuj ponownie");
            })
    }

    const modules: RetoricModuleCardProps[] = [
        {
            title: 'Urlop',
            description: 'Generator wniosków urlopowych',
            icon: <HomeOutlined className={'block text-3xl'}/>,
            onClick: () => auth.navigate('/modules/holiday')
        },
        {
            title: 'Oprogramowanie',
            description: 'Baza przydatnych programów',
            icon: <CodeOutlined className={'block text-3xl'}/>,
            onClick: () => auth.navigate('/modules/software')
        },
        {
            title: 'Signalist',
            description: 'Anonimowe zgłoszenie nieprawidłowości',
            icon: <BellOutlined className={'block text-3xl'}/>,
            onClick: () => openSignalist()
        },
        {
            title: 'Kanban',
            description: 'Tablica Kanban do zarządzania zadaniami (dostępny wkrótce)',
            icon: <ProjectOutlined className={'block text-3xl'}/>,
            onClick: () => auth.navigate('/modules/in-development/kanban')
        },
        {
            title: 'Geotool',
            description: 'System do zarządzania podatkami od nieruchomości (dostępny wkrótce)',
            icon: <GlobalOutlined className={'block text-3xl'}/>,
            onClick: () => auth.navigate('/modules/in-development/geotool')
        },
        {
            title: 'Delegacje',
            description: 'Generator i kalkulator delegacji (dostępny wkrótce)',
            icon: <CarOutlined className={'block text-3xl'}/>,
            onClick: () => auth.navigate('/modules/in-development/delegations')
        },
        {
            title: 'Zamówienia',
            description: 'Procesy zamówieniowe i ofertowe (dostępny wkrótce)',
            icon: <ShoppingCartOutlined className={'block text-3xl'}/>,
            onClick: () => auth.navigate('/modules/in-development/orders')
        },
        {
            title: 'Zadania',
            description: 'Manadżer zadań pracowników (dostępny wkrótce)',
            icon: <OrderedListOutlined className={'block text-3xl'}/>,
            onClick: () => auth.navigate('/modules/in-development/tasks')
        }
    ];

    useEffect(() => {
        setFilteredModules(modules);
    }, []);

    const searchModules = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.toLowerCase();
        setFilteredModules(modules.filter(x => x.title.toLowerCase().includes(value) || x.description.toLowerCase().includes(value)));
    }

    return (
        <div className="content">
            <RetoricBreadcrumbs locations={Locations['modules']}>Moduły</RetoricBreadcrumbs>
            <div className="flex flex-row justify-start lg:self-start self-stretch">
                <Input.Search placeholder={'Wpisz frazę do wyszukania'} allowClear size={'large'}
                              onChange={searchModules} className="lg:min-w-[500px] search"/>
            </div>
            <div className="grid grid-cols-2 lg:grid-cols-4 flex-1 self-stretch gap-4">
                {filteredModules.map((module, index) => <RetoricModuleCard
                    key={`module-${module.title}`} {...module} />)}
            </div>
        </div>
    );
}

export default Modules;
