import React, {useEffect, useRef, useState} from 'react';
import './AiAssistant.css';
import {AutoComplete, Layout, List, notification, Space} from "antd";
import Loader from "./components/Loader";
import RetoricButton from "../../../designsystems/RetoricButton/RetoricButton";
import {useAuth} from "../../../extensions/Auth";
import BotLogo from '../../../assets/logotype-dark.svg'
import RetoricBreadcrumbs from "../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../extensions/Locations";
import {BACKEND_BASE, LoginService} from "../../../services/LoginService";
import {AiChatMessage, AiChatMessageRequest, AiChatRedacted, AiPrePromptRedacted} from "../../../types/AiChat";
import {AiChatService} from "../../../services/AiChatService";
import TextArea from "antd/es/input/TextArea";
import {ThemeColours} from "../../../extensions/Theme";
import {UserService} from "../../../services/UserService";
import {ErrorMessages} from "../../../extensions/ErrorMessages";

const {Footer, Sider, Content} = Layout;

interface AutoSelectOptions {
    value: string;
    label: string;
    key: string;
}

function AiAssistant() {
    const auth = useAuth();
    const [chats, setChats] = useState([] as AiChatRedacted[])
    const [messages, setMessages] = useState([] as AiChatMessage[])
    const [userMessage, setUserMessage] = useState<string>('');
    const [activeChat, setActiveChat] = useState<string | null>(null);
    const [botTyping, setBotTyping] = useState<boolean>(false);
    const [showChats, setShowChats] = useState<boolean>(false);
    const [responseInProgress, setResponseInProgress] = useState<boolean>(false);
    const [commandsOpen, setCommandsOpen] = useState<boolean>(false)
    const [autoSelectOptions, setAutoSelectOptions] = useState([] as AutoSelectOptions[])
    const [aiPrePrompts, setAiPrePrompts] = useState([] as AiPrePromptRedacted[])
    const [activePrePrompt, setActivePrePrompt] = useState<string>('')
    const [api, contextHolder] = notification.useNotification();

    const messagesEndRef = useRef(null as any)

    const loadChat = (id: string, switching: boolean = true) => {
        setActiveChat(id);
        scrollToBottom();

        if (switching) {
            localStorage.setItem('isStopChat', 'true');
        }

        AiChatService
            .getChat(id)
            .then((data) => {
                setMessages(data.messages)
                scrollToBottom()
            });
    }

    const scrollToBottom = () => {
        setTimeout(() => {
            messagesEndRef.current?.scrollTo({top: messagesEndRef.current?.scrollHeight, behavior: "smooth"})
        }, 250);
    }

    const sendMessage = async () => {
        if (userMessage.length === 0) {
            return;
        }

        localStorage.setItem('isStopChat', 'false');

        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${auth.user?.token}`);

        const message: AiChatMessageRequest = {
            message: userMessage,
        }

        const currentMessages = [...messages, {content: userMessage, role: 'user'}];

        if (activeChat) {
            message.chatId = activeChat;
        } else {
            setActiveChat('new');
        }

        setMessages(currentMessages)
        setUserMessage('');
        setActivePrePrompt('');

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(message)
        };

        setBotTyping(true);
        setResponseInProgress(true);

        const url = !activeChat && activePrePrompt ? `${BACKEND_BASE}/Chatbot?promptIdentifier=${activePrePrompt}` : `${BACKEND_BASE}/Chatbot`;

        const send = async () => {
            try {
                setUserMessage('');
                const response = await fetch(url, requestOptions);
                const newMessages = [...currentMessages, {content: '', role: 'assistant'}];

                if (!response.ok || !response.body) {
                    setResponseInProgress(false);
                    setBotTyping(false);
                    localStorage.setItem('isStopChat', 'false');

                    if(response.status === 401) {
                        api.error({
                            message: `Błąd`,
                            description: "Nie posiadasz uprawnień do korzystania z asystenta AI",
                            placement: 'top'
                        });
                        return;
                    }

                    if(response.status === 400 && response.body) {
                        const reader = response.body.getReader();
                        const decoder = new TextDecoder();

                        while (true) {
                            const {value, done} = await reader.read();

                            if (done) break;

                            const decodedChunk = decoder.decode(value, {stream: true})

                            const controlMsg: { message: string } = JSON.parse(decodedChunk);

                            if(controlMsg.message === ErrorMessages.budgetExceeded) {
                                api.error({
                                    message: `Błąd`,
                                    description: "Osiągnąłeś limit kredytów AI - skontaktuj się z administratorem.",
                                    placement: 'top'
                                });
                                return;
                            }
                        }
                    }

                    api.error({
                        message: `Błąd`,
                        description: "Wystąpił błąd wewnętrzny, spróbuj odświeżyć stronę.",
                        placement: 'top'
                    });
                    return;
                }

                const reader = response.body.getReader();
                const decoder = new TextDecoder();

                while (true) {
                    const {value, done} = await reader.read();

                    if (done) break;

                    const decodedChunk = decoder.decode(value, {stream: true})

                    setResponseInProgress(true);

                    if (decodedChunk.includes('RETORIC_STREAM_START'))
                        continue;


                    setBotTyping(false);

                    if (decodedChunk.includes('RETORIC_STREAM_END')) {
                        const controlMsg: { ChatId: string } = JSON.parse(decodedChunk.replace('---RETORIC_STREAM_END---', ''));
                        AiChatService
                            .getChats()
                            .then((data) => {
                                setActiveChat(controlMsg.ChatId)
                                setChats(data);
                                setResponseInProgress(false);
                            });

                        localStorage.setItem('isStopChat', 'false');

                        break;
                    }

                    if (localStorage.getItem('isStopChat') !== 'true') {
                        const msgs = [...newMessages];
                        msgs[msgs.length - 1].content = newMessages[newMessages.length - 1].content + decodedChunk;

                        setMessages([...msgs]);
                        scrollToBottom();
                    }
                }
            } catch (error) {
                api.error({
                    message: `Błąd`,
                    description: "Wystąpił błąd wewnętrzny, spróbuj odświeżyć stronę.",
                    placement: 'top'
                });

                setResponseInProgress(false);
                setBotTyping(false);
                localStorage.setItem('isStopChat', 'false');
            }
        };

        await send();
    }

    const newChat = () => {
        setActiveChat(null);
        setMessages([]);
    }

    useEffect(() => {
        AiChatService
            .getChats()
            .then((data) => {
                setChats(data);
            });

        AiChatService
            .getAiCommands()
            .then((data) => {
                setAutoSelectOptions(data.map((command) => ({
                    value: `${command.instruction} `,
                    label: command.command,
                    key: command.id
                })))
            });

        AiChatService
            .getPrePrompts()
            .then((data) => {
                setAiPrePrompts(data);
            });

        LoginService
            .getUser()
            .then((data: any) => {
                if (data.error)
                    return;

                if (!data.isAiEnabled) {
                    api.error({
                        message: `Błąd`,
                        description: "Nie posiadasz uprawnień do korzystania z asystenta AI",
                        placement: 'top'
                    });

                    auth.navigate('/');
                }
            });
        return () => {
        };
    }, []);

    return (
        <div className="content min-h-full">
            <RetoricBreadcrumbs locations={Locations['aiassistant']}>Asystent AI</RetoricBreadcrumbs>
            {contextHolder}
            <Layout
                className={'flex lg:flex-row flex-col gap-4 w-full rounded-2xl lg:height-sider-restricted min-h-full'}>
                <Sider className="aiassistant__sider bg-none-important rounded-2xl hidden lg:block" width={300}>
                    <List
                        size="large"
                        bordered
                        locale={{emptyText: 'Rozpocznij swój pierwszy czat'}}
                        className={'sider'}
                        header={<List.Item
                            className={'first:rounded-t-2xl hover:cursor-pointer m-0 p-0-important hover:font-medium'}
                            onClick={newChat}>Nowy chat</List.Item>}
                        dataSource={chats}
                        renderItem={(item) => <List.Item
                            className={'hover:cursor-pointer aiassistant__sider-entry hover:font-medium'}
                            onClick={() => loadChat(item.id)}>{item.title}</List.Item>}
                    >
                    </List>
                </Sider>
                <Layout>
                    <Content ref={messagesEndRef}
                             className={`flex-1 self-stretch bg-offwhite-important rounded mb-4 overflow-auto shadow ${activeChat ? 'height-restricted' : 'height-restricted-new'}`}>
                        <div className="min-h-full min-w-full bg-gray-100">
                            {activeChat && messages.map((message: AiChatMessage, index: number) => {
                                return (
                                    <div
                                        key={`message-${index}`}
                                        className={'lg:p-6 p-2' + (message.role === 'user' ? ' bg-gray-100 text-slate-950' : ' bg-offwhite text-slate-950')}>
                                        <div className="flex flex-row gap-4 lg:container">
                                            {message.role === 'user' ?
                                                <img src={`${BACKEND_BASE}/User/avatar/${auth.user?.email}`}
                                                     alt={auth.user?.name}
                                                     className="h-12 w-12"/> :
                                                <img src={BotLogo} alt={"Asystent AI"} className="h-12 w-12"/>
                                            }
                                            <pre style={{whiteSpace: 'pre-wrap'}}>{message.content}</pre>
                                        </div>
                                    </div>
                                )
                            })}
                            {botTyping &&
                                <div className="lg:p-6 p-2 bg-offwhite text-slate-950">
                                    <div className="flex flex-row gap-4 lg:container">
                                        <img src={BotLogo} alt={"Asystent AI"} className="h-12 w-12"/>
                                        <pre style={{whiteSpace: 'pre-wrap'}}><Loader style={{height: '40px'}}/></pre>
                                    </div>
                                </div>}
                            {!activeChat &&
                                <div className={'lg:p-6 p-2 text-center'}>Napisz wiadomość, aby rozpocząć
                                    czat z naszym asystentem AI.</div>}
                        </div>
                    </Content>
                    {!activeChat && <Content className="flex flex-row flex-wrap gap-2 justify-start items-start mb-2">
                        <RetoricButton size="sm"
                                       onClick={() => setActivePrePrompt('')}
                                       style={{background: activePrePrompt === '' ? ThemeColours.brandColor["800"] : ''}}>
                            Wykonaj instrukcję
                        </RetoricButton>
                        {aiPrePrompts.map((prePrompt) =>
                            <RetoricButton key={prePrompt.id}
                                           onClick={() => setActivePrePrompt(prePrompt.identifier)}
                                           size="sm"
                                           style={{background: activePrePrompt === prePrompt.identifier ? ThemeColours.brandColor["800"] : ''}}>
                                {prePrompt.description}
                            </RetoricButton>)}
                    </Content>}
                    <Footer className="self-stretch p-0-important flex bg-offwhite-important rounded-2xl mb-4 lg:mb-0">
                        <Space.Compact style={{width: '100%'}} size={'large'}>
                            <AutoComplete
                                style={{width: '100%', minHeight: '38px'}}
                                open={commandsOpen}
                                options={autoSelectOptions}
                                onBlur={() => setCommandsOpen(false)}
                                onFocus={() => {
                                    if (userMessage === '/') setCommandsOpen(true)
                                }}
                                value={userMessage}
                                defaultActiveFirstOption={true}
                                disabled={responseInProgress}
                                filterOption={(inputValue, option) => option!.label.toUpperCase().indexOf(inputValue.slice(1).toUpperCase()) !== -1}
                                onSelect={(value, option) => {
                                    setCommandsOpen(false)
                                    setUserMessage(value)
                                }}
                            >
                                <TextArea
                                    placeholder="Naciśnij '/' by skorzystać ze swoich komend lub napisz wiadomosc..."
                                    value={userMessage}
                                    onKeyDown={((e) => {
                                        if (e.key === 'Enter' && !e.shiftKey && !commandsOpen) {
                                            e.preventDefault();
                                            sendMessage()
                                        }

                                        if (e.key === '/' && userMessage.length === 0) {
                                            setCommandsOpen(true);
                                        }

                                        if (e.key === 'Tab' && commandsOpen) {
                                            e.preventDefault();
                                            setUserMessage(autoSelectOptions[0].value);
                                        }

                                        if (e.key === 'Escape' && commandsOpen) {
                                            setCommandsOpen(false);
                                        }

                                        if (e.key === "Backspace" && userMessage.length === 1 && userMessage[0] === "/") {
                                            setCommandsOpen(false);
                                        }
                                    })}
                                    rows={1}
                                    style={{minHeight: '38px', lineHeight: '32px'}}
                                    disabled={responseInProgress}
                                    className="bg-offwhite-important rounded-none placeholder:text-gray-500 min-h-[38px]"
                                    onChange={(data) => setUserMessage(data.target.value)}/>
                            </AutoComplete>
                            <RetoricButton type="primary" onClick={sendMessage}
                                           disabled={responseInProgress}>Wyślij</RetoricButton>
                        </Space.Compact>
                    </Footer>
                    <RetoricButton className="lg:hidden"
                                   onClick={() => setShowChats(!showChats)}>{showChats ? 'Ukryj' : 'Pokaż'} historyczne
                        czaty</RetoricButton>
                    {showChats && <List
                        size="large"
                        bordered
                        className={'lg:hidden max-h-[160px] overflow-auto'}
                        header={<List.Item
                            className={'first:rounded-t-2xl hover:font-medium hover:cursor-pointer m-0 p-0-important'}
                            onClick={newChat}>Nowy chat</List.Item>}
                        dataSource={chats}
                        renderItem={(item) => <List.Item className={'hover:font-medium hover:cursor-pointer'}
                                                         onClick={() => loadChat(item.id)}>{item.title}</List.Item>}
                    >
                    </List>}
                </Layout>
            </Layout>
        </div>
    );
}

export default AiAssistant;
