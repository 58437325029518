import React, {useEffect, useState} from 'react';
import './UsersList.css';
import {Empty, Input, notification, Space, Table, Tooltip} from "antd";
import {ColumnsType} from "antd/es/table";
import {EditOutlined} from "@ant-design/icons";
import {User} from "../../../../types/AuthState";
import TagCloud from "../../../../components/TagCloud/TagCloud";
import {useAuth} from "../../../../extensions/Auth";
import RetoricCard from "../../../../designsystems/RetoricCard/RetoricCard";
import RetoricBreadcrumbs from "../../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../../extensions/Locations";
import ManagerService from "../../../../services/ManagerService";
import {ScopeMapper} from "../../../AdminView/Users/UsersList/UsersList";
import {AdminScopes} from "../../../../types/Scopes";

const searchFilter = (x: User, searchTerm: string) => {
    return x.name.toLowerCase().includes(searchTerm) ||
        x.position.toLowerCase().includes(searchTerm) ||
        x.email.toLowerCase().includes(searchTerm);
}

function UsersList() {
    const [userList, setUserList] = useState([] as User[]);
    const [filteredUserList, setFilteredUserList] = useState([] as User[]);
    const [search, setSearch] = useState('');
    const [api, contextHolder] = notification.useNotification();
    const auth = useAuth();

    const columns: ColumnsType<User> = [
        {
            title: 'Imię',
            dataIndex: 'firstName',
            key: 'firstName',
            render: (value, record) => <span className="block">{value}</span>
        },
        {
            title: 'Nazwisko',
            dataIndex: 'lastName',
            key: 'lastName',
            render: (value, record) => <span className="block">{value}</span>
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (value, record) => <span className="block">{value}</span>
        },
        {
            title: 'Dział',
            dataIndex: 'organisation',
            key: 'organisation',
            render: (value, record) => <span className="block">{value}</span>
        },
        {
            title: 'Stanowisko',
            dataIndex: 'position',
            key: 'position',
            render: (value, record) => <span className="block">{value}</span>
        },
        {
            title: 'Uprawnienia',
            dataIndex: 'scopes',
            key: 'scopes',
            responsive: ['md'],
            render: (values: number[]) => <TagCloud tags={values.map(x => ScopeMapper(x))} limit={3}/>,
        },
        {
            title: 'Akcje',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    {auth?.user?.scopes?.includes(AdminScopes.USERS) && <Tooltip title="Edytuj użytkownika">
                        <EditOutlined onClick={() => auth.navigate(`/admin/users/edit/${record.email}`)}/>
                    </Tooltip>}
                </Space>
            ),
        },
    ];

    const searchUsers = (value: any, fromString = false) => {
        const searchTerm = fromString ? value.toLowerCase() : value.target.value.toLowerCase()

        setSearch(searchTerm);

        if (searchTerm === '')
            setFilteredUserList(userList);
        else
            setFilteredUserList(userList.filter(x => searchFilter(x, searchTerm)));
    }

    useEffect(() => {
        ManagerService
            .getDirectReports()
            .then(x => {
                setUserList(x);
                setFilteredUserList(x);
            });

        return () => {
        };
    }, [auth]);

    return (
        <div className="content">
            <RetoricBreadcrumbs locations={Locations['manager']}>Lista pracowników</RetoricBreadcrumbs>
            <RetoricCard className={'flex flex-col w-full bg-offwhite'}>
                {contextHolder}
                <div className="flex lg:flex-row flex-col justify-between">
                    <div className="flex-1 mb-4">
                        <Input.Search placeholder="Wyszukaj użytkownika" allowClear size={'large'}
                                      onChange={(value: any) => searchUsers(value)}
                                      className="search bg-offwhite"/>
                    </div>
                    <div className={'users__new'}></div>
                </div>
                <Table columns={columns} dataSource={filteredUserList} rowKey={'id'} scroll={{x: 768}}
                       pagination={{position: ['bottomCenter']}}
                       locale={{emptyText: <Empty description={'Brak danych'}/>}}/>
            </RetoricCard>
        </div>
    );
}

export default UsersList;
