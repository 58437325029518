import {BACKEND_BASE, GetToken} from "./LoginService";
import {ErrorMessages} from "../extensions/ErrorMessages";
import {CalendarEvent, CalendarEventRequest} from "../screens/UserView/Calendar/RetoricCalendar";
import dayjs from "dayjs";

const CalendarService = {
    addEvent: async (event: CalendarEventRequest): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Calendar`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(event)
            });

            if (response.status === 401) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            if (response.status === 422) {
                return Promise.reject(await response.json());
            }

            if (response.status >= 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return Promise.resolve();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    updateEvent: async (event: CalendarEventRequest, id: string): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Calendar/${id}`, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify(event)
            });

            if (response.status === 401) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            if (response.status === 422) {
                return Promise.reject(await response.json());
            }

            if (response.status >= 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return Promise.resolve();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    getEvents: async (from: Date, to: Date): Promise<CalendarEvent[]> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Calendar?from=${from.toISOString()}&to=${to.toISOString()}`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });
            if (response.status === 401)
                return Promise.reject(new Error(ErrorMessages.forbidden));


            if (response.status === 404)
                return Promise.reject(new Error(ErrorMessages.notFound));

            if (response.status >= 400)
                return Promise.reject(new Error(ErrorMessages.badRequest));

            const r = await response.json();

            return r.map((x: CalendarEvent) => {
                x.date.start = dayjs(x.date.start);
                x.date.end = dayjs(x.date.end);

                return x
            });
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    getEvent: async (id: string): Promise<CalendarEvent> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Calendar/${id}`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });
            if (response.status === 401)
                return Promise.reject(new Error(ErrorMessages.forbidden));


            if (response.status === 404)
                return Promise.reject(new Error(ErrorMessages.notFound));

            if (response.status >= 400)
                return Promise.reject(new Error(ErrorMessages.badRequest));

            const r = await response.json();

            r.date.start = dayjs(r.date.start);
            r.date.end = dayjs(r.date.end);

            return r;
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    deleteEvent: async (id: string): Promise<void> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Calendar/${id}`, {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });
            if (response.status === 401)
                return Promise.reject(new Error(ErrorMessages.forbidden));


            if (response.status === 404)
                return Promise.reject(new Error(ErrorMessages.notFound));

            if (response.status >= 400)
                return Promise.reject(new Error(ErrorMessages.badRequest));

            return Promise.resolve();
        } catch (e) {
            throw new Error('unauthorized');
        }
    }
}

export default CalendarService;
