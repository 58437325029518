import React, {useEffect, useState} from 'react';
import {DatePicker, Form, Input, message, Modal, Select, SelectProps, Switch, TimePicker} from "antd";
import './CreateMeetingModal.css';
import Infobox from "../../../../designsystems/Infobox/Infobox";
import RetoricButton from "../../../../designsystems/RetoricButton/RetoricButton";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/pl_PL";
import {
    CalendarOutlined,
    ControlOutlined,
    EnvironmentOutlined,
    ExclamationCircleTwoTone,
    FieldTimeOutlined,
    GlobalOutlined,
    MessageOutlined,
    PushpinOutlined,
    RightOutlined,
    TeamOutlined,
    PullRequestOutlined
} from "@ant-design/icons";
import {
    CalendarEventLocationType,
    CalendarEventRequest,
    CalendarEventType,
    CalendarPriority,
    CalendarTitleMapper
} from "../RetoricCalendar";
import {ShortDurationNumberFormatter} from "../../../../extensions/Calendar";
import TextArea from "antd/es/input/TextArea";
import {RedactedUser} from "../../../../types/AuthState";
import {UserService} from "../../../../services/UserService";
import CalendarService from "../../../../services/CalendarService";

export interface CreateMeetingModalProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    payload: CreateMeetingModalPayload;
}

export interface CreateMeetingModalPayload {
    start: dayjs.Dayjs;
    end: dayjs.Dayjs;
}

const TimeFormat = 'HH:mm';

const CreateTimes = (start: dayjs.Dayjs, end: dayjs.Dayjs, startTime: dayjs.Dayjs, endTime: dayjs.Dayjs) => {
    const s = start.hour(startTime.hour()).minute(startTime.minute());
    const e = end.hour(endTime.hour()).minute(endTime.minute());

    return [s, e];
}

const CreateTopOfHourTimes = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
    const s = start.hour(0).minute(0).second(0).millisecond(0);
    const e = end.hour(0).minute(0).second(0).millisecond(0);

    return [s, e];
}

function CreateMeetingModal({open, setOpen, payload}: CreateMeetingModalProps) {
    const [form] = Form.useForm();
    const [isWholeDay, setIsWholeDay] = useState(false);
    const [locationType, setLocationType] = useState(CalendarEventLocationType.None);
    const [duration, setDuration] = useState(0);
    const [users, setUsers] = useState<RedactedUser[]>([]);
    const [options, setOptions] = useState<SelectProps['options']>([]);

    const submitForm = () => {
        form
            .validateFields()
            .then((values) => {
                let start = values.startDate.hour(12).minute(0);
                let end = values.endDate.hour(12).minute(0);

                if(!isWholeDay)
                    [start, end] = CreateTimes(values.startDate, values.endDate, values.startTime, values.endTime);

                const request: CalendarEventRequest = {
                    title: values.title,
                    eventType: values.type,
                    attendees: values.attendees,
                    date: {
                        start: start.toDate(),
                        end: end.toDate(),
                        isWholeDay: isWholeDay
                    },
                    location: {
                        type: locationType,
                        address: values.address,
                        link: values.link
                    },
                    description: values.description,
                    priority: values.priority
                };

                CalendarService.addEvent(request)
                    .then(x => {
                        message.success('Wydarzenie zostało dodane!');
                        setIsWholeDay(false);
                        setLocationType(CalendarEventLocationType.None);
                        setDuration(0);
                        form.resetFields();
                        setOpen(false);
                    })
                    .catch(x => {
                        message.error("Wystąpił błąd podczas dodawania wydarzenia");
                    });
            })
            .catch((info) => {
                message.error('Formularz zawiera błędy, upewnij się, że wszystkie wymagane pola zostały wypełnione!');
            });
    }

    const onIsWholeDayChange = (value: boolean) => {
        setIsWholeDay(value);

        if (value) {
            const [start, end] = CreateTopOfHourTimes(form.getFieldValue('startDate'), form.getFieldValue('endDate'));
            const d = end.diff(start, 'day') || 0;
            setDuration((d + 1) * 1440);
        } else {
            const [start, end] = CreateTimes(form.getFieldValue('startDate'), form.getFieldValue('endDate'), form.getFieldValue('startTime'), form.getFieldValue('endTime'));

            setDuration(end.diff(start, 'minute') || 0);
        }
    }

    const onLocationTypeChange = (value: CalendarEventLocationType) => {
        setLocationType(value);
        form.setFieldValue('address', '');
        form.setFieldValue('link', '');
    }

    const onDateChange = (isStart: boolean, value: dayjs.Dayjs) => {
        const start = isStart ? value : form.getFieldValue('startDate');
        const end = isStart ? form.getFieldValue('endDate') : value;

        if (isWholeDay) {
            let d = end.diff(start, 'day') || 0;

            if(d <= 0 && isStart) {
                form.setFieldsValue({
                    endDate: start
                });

                d = 0;
            }

            if(d <= 0 && !isStart) {
                form.setFieldsValue({
                    startDate: end
                });

                d = 0;
            }

            setDuration((d + 1) * 1440);
        } else {
            const [s, e] = CreateTimes(start, end, form.getFieldValue('startTime'), form.getFieldValue('endTime'));
            const d = e.diff(s, 'minute') || 0;

            if (d <= 0 && isStart) {
                form.setFieldsValue({
                    endTime: s.add(1, 'hour'),
                    endDate: s
                });

                setDuration(60);
                return
            }

            if (d <= 0 && !isStart) {
                form.setFieldsValue({
                    startTime: e.add(-1, 'hour'),
                    startDate: e
                });

                setDuration(60);
                return
            }

            setDuration(d);
        }
    }

    const onTimeChange = (isStart: boolean, value: dayjs.Dayjs) => {
        const start = isStart ? value : form.getFieldValue('startTime');
        const end = isStart ? form.getFieldValue('endTime') : value;
        const [s, e] = CreateTimes(form.getFieldValue('startDate'), form.getFieldValue('endDate'), start, end);

        const d = e.diff(s, 'minute') || 0;

        if (d <= 0 && isStart) {
            form.setFieldsValue({
                endTime: s.add(1, 'hour')
            });

            setDuration(60);
            return
        }

        if (d <= 0 && !isStart) {
            form.setFieldsValue({
                startTime: e.add(-1, 'hour')
            });

            setDuration(60);
            return
        }

        setDuration(d);
    }

    useEffect(() => {
        form.resetFields();

        form.setFieldsValue({
            startDate: payload?.start?.hour(0)?.minute(0),
            startTime: payload?.start,
            endDate: payload?.end?.hour(0)?.minute(0),
            endTime: payload?.end
        });

        setDuration(payload?.end?.diff(payload?.start, 'minute') || 0);

        return () => {}
    }, [open]);

    useEffect(() => {
        form.setFieldsValue({
            startDate: payload?.start?.hour(0)?.minute(0),
            startTime: payload?.start,
            endDate: payload?.end?.hour(0)?.minute(0),
            endTime: payload?.end
        });

        setDuration(payload?.end?.diff(payload?.start, 'minute') || 0);

        UserService.getRedactedUsers().then((response) => {
            setUsers(response);
            setOptions(response.map(x => ({label: x.name, value: x.id})) as SelectProps['options']);
        });

        return () => {
        }
    }, [payload]);

    return (
        <Modal
            title={`Dodaj nowe wydarzenie`}
            centered
            footer={[
                <RetoricButton key="back" outline className="mr-4" onClick={() => setOpen(false)}>
                    Anuluj
                </RetoricButton>,
                <RetoricButton key="submit" onClick={() => submitForm()}>
                    Dodaj
                </RetoricButton>,
            ]}
            onCancel={() => setOpen(false)}
            open={open}
            width={1000}
        >
            {(payload?.start?.isBefore(dayjs()) || payload?.end?.isBefore(dayjs())) &&
                <Infobox title="Uwaga!" avatar={<ExclamationCircleTwoTone className="text-2xl"/>}>
                    <div>
                        <p className="text-red-600 font-medium">Dodajesz wydarzenie, które rozpoczyna się lub kończy się
                            przed obecną datą.</p>
                    </div>
                </Infobox>}
            <Form className="mt-4" form={form} onFinish={submitForm}>
                <div className="flex lg:flex-row flex-col gap-1 justify-start items-start min-w-full">
                    <div
                        className="flex-1 self-stretch flex flex-row gap-1 justify-start items-start min-w-full lg:min-w-fit">
                        <CalendarOutlined className="text-xl mt-1.5 mr-1"/>
                        <Form.Item
                            name="title"
                            className="w-full"
                            rules={[{required: true, message: 'Tytuł wydarzenia jest wymagany!'}, {max: 70, message: 'Maksymalna długość to 70 znaków!' }]}
                        >
                            <Input placeholder={'Tytuł wydarzenia'} className="rounded-none"/>
                        </Form.Item>
                    </div>
                    <div className="flex flex-row gap-1 justify-start items-start min-w-full lg:min-w-fit">
                        <ControlOutlined className="text-xl mt-1.5 mr-1 lg:ml-2"/>
                        <Form.Item
                            name="type"
                            className="lg:w-[200px] w-full"
                            rules={[{required: true, message: 'Kategoria jest wymagana!'}]}
                        >
                            <Select placeholder={'Kategoria'} className="rounded-none">
                                <Select.Option value={CalendarEventType.Meeting}>{CalendarTitleMapper(CalendarEventType.Meeting)}</Select.Option>
                                <Select.Option value={CalendarEventType.Delegation}>{CalendarTitleMapper(CalendarEventType.Delegation)}</Select.Option>
                                <Select.Option value={CalendarEventType.Training}>{CalendarTitleMapper(CalendarEventType.Training)}</Select.Option>
                                <Select.Option value={CalendarEventType.Holiday}>{CalendarTitleMapper(CalendarEventType.Holiday)}</Select.Option>
                                <Select.Option value={CalendarEventType.Other}>{CalendarTitleMapper(CalendarEventType.Other)}</Select.Option>
                            </Select>
                        </Form.Item>
                    </div>
                </div>
                <div className="flex lg:flex-row flex-col gap-1 justify-start items-start min-w-full">
                    <div className="flex flex-row gap-1 justify-start items-start min-w-full">
                        <TeamOutlined className="text-xl mt-1.5 mr-1"/>
                        <Form.Item name="attendees" className="flex-1 self-stretch">
                            <Select
                                mode="multiple"
                                placeholder="Dodaj uczestników"
                                optionLabelProp="label"
                                options={options}
                            />
                        </Form.Item>
                    </div>
                </div>
                <div className="flex flex-col lg:flex-row">
                    <div
                        className="flex flex-row gap-1 justify-start items-start min-w-full lg:min-w-fit flex-1 self-stretch">
                        <FieldTimeOutlined className="text-xl mt-1.5 mr-1"/>
                        <Form.Item name="startDate" className="flex-1 self-stretch">
                            <DatePicker className="rounded-none min-w-full" locale={locale} allowClear={false}
                                        onChange={(date: any) => onDateChange(true, date)}/>
                        </Form.Item>
                        {!isWholeDay && <Form.Item name="startTime">
                            <TimePicker
                                format={TimeFormat} allowClear={false}
                                onChange={(date: any) => onTimeChange(true, date)}
                                className="rounded-none min-w-full"
                                locale={locale}/>
                        </Form.Item>}
                    </div>
                    <div
                        className="flex flex-row gap-1 justify-start items-start min-w-full lg:min-w-fit flex-1 self-stretch">
                        <RightOutlined className="text-xl mt-1.5 mr-1 lg:ml-2"/>
                        <Form.Item name="endDate" className="flex-1 self-stretch">
                            <DatePicker className="rounded-none min-w-full"
                                        locale={locale} allowClear={false}
                                        disabledDate={(current) => current.isBefore(form.getFieldValue('startDate'))}
                                        onChange={(date: any) => onDateChange(false, date)}/>
                        </Form.Item>
                        {!isWholeDay && <Form.Item name="endTime">
                            <TimePicker
                                format={TimeFormat} allowClear={false}
                                onChange={(date: any) => onTimeChange(false, date)}
                                className="rounded-none"
                                locale={locale}/>
                        </Form.Item>}
                    </div>
                    <div
                        className="flex flex-row gap-1 justify-between items-start flex-1 self-stretch min-w-fit lg:max-w-[240px]">
                            <span
                                className="text-lg mt-0.5 mr-1 lg:ml-2">{ShortDurationNumberFormatter(duration)}</span>
                        <div className="flex flex-row gap-1">
                            <Form.Item name="isWholeDay" valuePropName={'checked'}>
                                <Switch className="bg-gray-200" onChange={onIsWholeDayChange}/>
                            </Form.Item>
                            <span className="text-lg mt-0.5 mr-1 lg:ml-2 ml-1">Cały dzień?</span>
                        </div>
                    </div>
                </div>
                <div className="flex lg:flex-row flex-col gap-1 justify-start items-start min-w-full">
                    <div className="flex flex-row gap-1 justify-start items-start min-w-full lg:min-w-fit">
                        <PullRequestOutlined className="text-xl mt-1.5 mr-1"/>
                        <Form.Item
                            name="priority"
                            className="lg:w-[200px] w-full"
                        >
                            <Select placeholder={'Priorytet'} className="rounded-none">
                                <Select.Option value={CalendarPriority.None}>Brak</Select.Option>
                                <Select.Option value={CalendarPriority.Low}>Niski</Select.Option>
                                <Select.Option value={CalendarPriority.Medium}>Średni</Select.Option>
                                <Select.Option value={CalendarPriority.High}>Ważny</Select.Option>
                                <Select.Option value={CalendarPriority.Critical}>Krytyczny</Select.Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="flex flex-row gap-1 justify-start items-start min-w-full lg:min-w-fit lg:ml-2">
                        <PushpinOutlined className="text-xl mt-1.5 mr-1"/>
                        <Form.Item
                            name="locationType"
                            className="lg:w-[200px] w-full"
                            rules={[{required: true, message: 'Typ lokalizacji jest wymagany!'}]}
                        >
                            <Select placeholder={'Lokalizacja'} className="rounded-none"
                                    onChange={onLocationTypeChange}>
                                <Select.Option value={CalendarEventLocationType.Online}>Online</Select.Option>
                                <Select.Option
                                    value={CalendarEventLocationType.InPerson}>Stacjonarna</Select.Option>
                                <Select.Option value={CalendarEventLocationType.None}>Brak</Select.Option>
                            </Select>
                        </Form.Item>
                    </div>
                    {locationType === CalendarEventLocationType.InPerson && <div
                        className="flex-1 self-stretch flex flex-row gap-1 justify-start items-start min-w-full lg:min-w-fit lg:ml-2">
                        <EnvironmentOutlined className="text-xl mt-1.5 mr-1"/>
                        <Form.Item
                            name="address"
                            className="w-full"
                        >
                            <Input placeholder={'Adres'} className="rounded-none"/>
                        </Form.Item>
                    </div>}
                    {locationType === CalendarEventLocationType.Online && <div
                        className="flex-1 self-stretch flex flex-row gap-1 justify-start items-start min-w-full lg:min-w-fit lg:ml-2">
                        <GlobalOutlined className="text-xl mt-1.5 mr-1"/>
                        <Form.Item
                            name="link"
                            className="w-full"
                        >
                            <Input placeholder={'Link (https://...)'} className="rounded-none"/>
                        </Form.Item>
                    </div>}
                </div>
                <div className="flex lg:flex-row flex-col gap-1 justify-start items-start min-w-full">
                    <div className="flex flex-row gap-1 justify-start items-start min-w-full">
                        <MessageOutlined className="text-xl mt-1.5 mr-1"/>
                        <Form.Item
                            name="description"
                            className="w-full"
                        >
                            <TextArea placeholder={'Opis wydarzenia'} rows={4} className="rounded-none"/>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </Modal>
    );
}

export default CreateMeetingModal;
