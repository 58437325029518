import React from 'react';
import {Avatar, Skeleton} from "antd";
import Meta from "antd/es/card/Meta";
import '../NewsCard/NewsCard.css';
import {UserOutlined} from "@ant-design/icons";
import RetoricCard from "../../designsystems/RetoricCard/RetoricCard";


function BlankNewsCard() {
    return (
        <RetoricCard className="bg-offwhite w-auto">
            <Skeleton avatar active paragraph={{rows: 3}}>
                <Meta avatar={<Avatar icon={<UserOutlined/>} className={'avatar'}/>}/>
            </Skeleton>
        </RetoricCard>
    );
}

export default BlankNewsCard;
