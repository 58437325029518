import React, {Key, useEffect, useState} from 'react';
import './System.css';
import {useAuth} from "../../../extensions/Auth";
import {AdminScopes, ControllableProperties} from "../../../types/Scopes";
import {
    AutoComplete,
    Card,
    Form,
    Input,
    message,
    Popconfirm,
    Radio,
    RadioChangeEvent,
    Select,
    Skeleton,
    Space,
    Tooltip,
    Tree,
    Upload,
    UploadProps
} from "antd";
import Meta from "antd/es/card/Meta";
import {
    ApartmentOutlined,
    CheckOutlined,
    CloseOutlined,
    CloudOutlined,
    DeleteOutlined,
    DownOutlined,
    HomeOutlined,
    UploadOutlined
} from "@ant-design/icons";
import {SystemService} from "../../../services/SystemService";
import {SiteSettings, SystemSettings} from "../../../types/System";
import {UserApp} from "../../../types/AuthState";
import {AppsMapper, CategoryMapper, SupportedApps} from "../../../components/AppsModal/AppsModal";
import {RcFile} from "antd/es/upload";
import Infobox from "../../../designsystems/Infobox/Infobox";
import RetoricCard from "../../../designsystems/RetoricCard/RetoricCard";
import RetoricButton from "../../../designsystems/RetoricButton/RetoricButton";
import RetoricBreadcrumbs from "../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../extensions/Locations";
import {TargetAppSpace} from "../../UserView/News/NewsList/NewsList";

export const ControllablePropertiesScopeMapper = (value: number) => {
    switch (value) {
        case 1:
            return 'Imię';
        case 2:
            return 'Nazwisko';
        case 3:
            return 'Stanowisko';
        case 4:
            return 'Dział';
        case 5:
            return 'Liczba dni urlopu';
        default:
            return 'Nieznany';
    }
}

const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

const LDAP_ENABLED = false;

function System() {
    const auth = useAuth();
    const [authType, setAuthType] = useState(0);
    const [loading, setLoading] = useState(true);
    const [dataSource, setDataSource] = useState([] as any[]);
    const [selectedValues, setSelectedValues] = useState([] as Key[]);
    const [editMode, setEditMode] = useState({isEdit: false, value: {} as any, mode: 'category' as 'category' | 'app'});
    const [insertMode, setInsertMode] = useState({
        isInsert: false,
        value: {} as any,
        mode: 'category' as 'category' | 'app'
    });
    const [siteSettings, setSiteSettings] = useState({} as SiteSettings);
    const [form] = Form.useForm();
    const [siteSettingsForm] = Form.useForm();
    const [accountSettingsForm] = Form.useForm();

    const onChange = (e: RadioChangeEvent) => {
        setAuthType(e.target.value);
    };

    const updateAuthMode = () => {
        SystemService
            .updateAuthMode(authType)
            .then((x) => {
                if (x) message.success('Zmieniono tryb uwierzytelnienia.');
                else message.error('Wystąpił błąd podczas zmiany trybu uwierzytelnienia.');
            });
    }

    const updateLdapSettings = () => {
        form.validateFields().then((values) => {
            SystemService.updateLdapSettings(values).then((x) => {
                if (x) message.success('Zmieniono ustawienia serwera LDAP.');
                else message.error('Wystąpił błąd podczas zmiany ustawień serwera LDAP.');
            });
        });
    }

    const updateSiteSettings = () => {
        siteSettingsForm.validateFields().then((values) => {
            const formValues = {
                ...siteSettings,
                title: values.title,
                integratedSearchEngine: values.integratedSearchEngine
            };

            SystemService.updateSiteSettings(formValues).then((x) => {
                if (x) message.success('Zmieniono ustawienia portaly.');
                else message.error('Wystąpił błąd podczas zmiany ustawień portalu.');
            });
        });
    }

    const updateAccountSettings = () => {
        accountSettingsForm.validateFields().then((values) => {
            SystemService.updateAccountSettings(values).then((x) => {
                if (x) message.success('Zmieniono ustawienia uwierzytelnienia.');
                else message.error('Wystąpił błąd podczas zmiany ustawień uwierzytelnienia.');
            });
        });
    }

    const onFormEdit = (event: any, key: string) => {
        let value = event?.target?.value ?? event;

        if (key === 'url') {
            const app = SupportedApps.find(x => x.value === value);

            if (app) value = app.key;
        }

        setEditMode({...editMode, value: {...editMode.value, [key]: value}});
    }

    const onInsertFormEdit = (event: any, key: string) => {
        let value = event?.target?.value ?? event;

        if (key === 'url') {
            const app = SupportedApps.find(x => x.value === value);

            if (app) value = app.key;
        }

        setInsertMode({...insertMode, value: {...insertMode.value, [key]: value}});
    }

    const onFormEditCategory = (event: any) => {
        setEditMode({...editMode, value: {...editMode.value, category: event.split('category-')[1]}});
    }

    const onInsertFormEditCategory = (event: any) => {
        setInsertMode({...insertMode, value: {...insertMode.value, category: event.split('category-')[1]}});
    }

    const userSorter = (a: UserApp, b: UserApp) => a.order !== undefined && b.order !== undefined ? a.order - b.order : 0;

    const loadApps = () => {
        SystemService.getDefaultUserApps().then((userApps: UserApp[]) => {
            const sortedApps = userApps.slice().sort(userSorter);
            const categories = [] as any[];

            categories.push({
                title: 'Kategoria: Brak',
                key: `category-blank`,
                children: [] as any[]
            });

            const allCategories = sortedApps
                .filter((x) => x.isCategory)
                .map(x => CategoryMapper(x)).sort((a, b) => a.title.localeCompare(b.title));

            categories.push(...allCategories);

            sortedApps.forEach((x) => {
                const key = x.category ? `category-${x.category}` : 'category-blank';
                const category = categories.find(y => y.key === key);

                if (category && !x.isCategory) {
                    category.children.push(AppsMapper(x, key))
                }
            });

            setDataSource(categories);
        });
    }

    const deleteApp = (id: string) => {
        SystemService.deleteDefaultUserApp(id).then((x) => {
            if (x) {
                message.success(`${insertMode.mode === 'category' ? 'Kategoria' : 'Aplikacja'} została usunięta!`);
                loadApps();
                cancelEdit();
            } else {
                message.error(`Wystąpił błąd podczas usuwania ${insertMode.mode === 'category' ? 'kategorii' : 'aplikacji'}!`);
            }
        });
    }

    const saveEdit = () => {
        const payload: UserApp = {
            id: editMode.value.id,
            displayName: editMode.value.displayName,
            order: editMode.value.order,
            isCategory: editMode.value.isCategory
        }

        if (!editMode.value.isCategory) {
            payload.category = editMode.value.category;
            payload.url = editMode.value.url;
            payload.targetAppSpace = editMode.value.targetAppSpace;
        }

        SystemService.editDefaultUserApp(payload).then((x) => {
            if (x) {
                message.success(`${insertMode.value.isCategory ? 'Kategoria' : 'Aplikacja'} została zapisana`);
                cancelEdit();
                loadApps();
            } else {
                message.error(`Wystąpił błąd podczas zapisywania ${insertMode.value.isCategory ? 'kategorii' : 'aplikacji'}`);
            }
        });
    }

    const saveInsert = () => {
        const payload: UserApp = {
            id: insertMode.value.id,
            displayName: insertMode.value.displayName,
            order: insertMode.value.order,
            isCategory: insertMode.mode === 'category'
        }

        if (!insertMode.value.isCategory) {
            payload.category = insertMode.value.category;
            payload.url = insertMode.value.url;
            payload.targetAppSpace = insertMode.value.targetAppSpace;
        }

        SystemService.addDefaultUserApp(payload).then((x) => {
            if (x) {
                message.success(`${insertMode.mode === 'category' ? 'Kategoria' : 'Aplikacja'} została dodana!`);
                loadApps();
                cancelInsertMode();
            } else {
                message.error(`Wystąpił błąd podczas dodawania ${insertMode.mode === 'category' ? 'kategorii' : 'aplikacji'}${insertMode.mode === 'category' ? ', kategoria już istnieje!' : ''}!`);
            }
        });
    }

    const cancelEdit = () => {
        setEditMode({isEdit: false, value: {}, mode: 'category'})
        setSelectedValues(['']);
    }

    const cancelInsertMode = () => {
        setInsertMode({isInsert: false, value: {}, mode: 'category'})
    }

    const editApp = (key: Key[]) => {
        cancelInsertMode();
        if (key.length > 0 && key[0] !== 'category-blank') {
            let app = dataSource.find(x => x.key === key[0]);

            if (app) {
                app.isEdit = true;
                setEditMode({isEdit: true, value: app, mode: 'category'});
            } else {
                dataSource.forEach(x => {
                    if (x.children) {
                        app = x.children.find((y: any) => y.key === key[0]);

                        if (app) {
                            setEditMode({isEdit: true, value: app, mode: 'app'});
                            app.isEdit = true;
                        }
                    }
                })
            }
        } else {
            const data = [...dataSource];

            data.forEach(x => {
                x.isEdit = false;

                if (x.children) {
                    x.children.forEach((y: any) => {
                        y.isEdit = false;
                    });
                }
            })

            setEditMode({isEdit: false, value: {}, mode: 'category'});
        }

        setSelectedValues(key);
    }

    const logoUploadProps: UploadProps = {
        maxCount: 1,
        customRequest: (options) => null,
        beforeUpload: (file) => {
            const isValid = file.type.startsWith('image/');

            if (!isValid) {
                message.error(`Logo musi być plikiem graficznym!`);
            }

            return isValid || Upload.LIST_IGNORE;
        },
        onChange: (info) => {
            info.fileList[0].status = 'done';
            getBase64(info.fileList[0].originFileObj!).then((r) => {
                setSiteSettings({...siteSettings, logo: r});
            });
        },
    };

    const logoDarkUploadProps: UploadProps = {
        maxCount: 1,
        customRequest: (options) => null,
        beforeUpload: (file) => {
            const isValid = file.type.startsWith('image/');

            if (!isValid) {
                message.error(`Logo musi być plikiem graficznym!`);
            }

            return isValid || Upload.LIST_IGNORE;
        },
        onChange: (info) => {
            info.fileList[0].status = 'done';
            getBase64(info.fileList[0].originFileObj!).then((r) => {
                setSiteSettings({...siteSettings, logoDark: r});
            });
        },
    };

    const ActionButtons = () => <Space>
        <Tooltip title={'Zapisz zmiany'}>
            <CheckOutlined onClick={() => editMode.isEdit ? saveEdit() : saveInsert()}/>
        </Tooltip>
        <Tooltip title={'Odrzuć zmiany'}>
            <CloseOutlined onClick={() => {
                cancelEdit()
                cancelInsertMode()
            }
            }/>
        </Tooltip>
        {editMode.isEdit && <Tooltip title={`Usuń ${editMode.mode === 'category' ? 'kategorię' : 'aplikację'}`}>
            <Popconfirm
                placement="top"
                title={`Czy jesteś pewien?`}
                description={`Czy na pewno chcesz usunąć ${editMode.mode === 'category' ? 'kategorię' : 'aplikację'} ${editMode.value.displayName}?`}
                onConfirm={() => deleteApp(editMode.value.id)}
                okText="Tak"
                okButtonProps={{className: "bg-brandColor-950 hover:bg-brandColor-800"}}
                cancelText="Nie"
            >
                <DeleteOutlined/>
            </Popconfirm>
        </Tooltip>}
    </Space>

    useEffect(() => {
        auth.verifyAuth(AdminScopes.SYSTEM);
        loadApps();

        SystemService.getSiteSettings().then((res: SiteSettings | null) => {
            if (res) {
                siteSettingsForm.setFieldsValue({
                    title: res.title,
                    integratedSearchEngine: res.integratedSearchEngine
                });
                setSiteSettings(res);
            }
        });

        SystemService.getSystemSettings().then((res: SystemSettings) => {
            setAuthType(res.authenticationMode);

            accountSettingsForm.setFieldsValue({
                passwordExpiry: res.accountSettings.passwordExpiry,
                minimumPasswordLength: res.accountSettings.minimumPasswordLength,
                controllableProperties: res.accountSettings.controllableProperties,
                isSelfHolidayManagementEnabled: res.accountSettings.isSelfHolidayManagementEnabled
            });

            form.setFieldsValue({
                hostname: res.ldapServer.hostname,
                port: res.ldapServer.port,
                baseDn: res.ldapServer.baseDn,
                userDn: res.ldapServer.userDn,
                ldapVersion: res.ldapServer.ldapVersion,
            });
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
        });
    }, []);

    const passwordResetOptions = [
        {
            label: 'Brak',
            value: -1,
        },
        {
            label: '30 dni',
            value: 30,
        },
        {
            label: '90 dni',
            value: 90,
        },
        {
            label: '180 dni',
            value: 180,
        },
        {
            label: '1 rok',
            value: 365,
        }
    ]

    return (
        <div className="content">
            <RetoricBreadcrumbs locations={Locations['admin']}>Ustawienia systemu</RetoricBreadcrumbs>
            <Infobox
                title={'Ustawienia systemowe'}
                className="self-stretch"
                rows={[
                    'W tej skecji możesz zmienić ustawienia globalne systemu.',
                    'Ustawienia, które mogą zostać zmienione to między innymi sposób uwierzytelnienia lub serwer uwierzytelniający.'
                ]}
            />
            <RetoricCard className={'self-stretch lg:px-8 px-2'}>
                <h3 className="text-lg text-slate-700 mb-4">Ustawienia portalu</h3>
                {!loading && <Form
                    name="site-settings"
                    layout={'vertical'}
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    style={{maxWidth: 800}}
                    form={siteSettingsForm}
                    onFinish={updateSiteSettings}
                    initialValues={{remember: true}}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Nazwa portalu"
                        name="title"
                        rules={[{required: true, message: 'Nazwa portalu jest wymagana!'}]}
                    >
                        <Input placeholder={'Intranet by Retoric'} className="rounded-none"/>
                    </Form.Item>

                    <Form.Item
                        label="Zintegrowana wyszukiwarka"
                        name="integratedSearchEngine"
                        rules={[{
                            required: true,
                            message: 'Ustawienie zintegrowanej wyszukiwarki jest wymagana!'
                        }]}
                    >
                        <Select>
                            <Select.Option value={0}>Brak wyszukiwarki</Select.Option>
                            <Select.Option value={1}>Google</Select.Option>
                            <Select.Option value={2}>Bing</Select.Option>
                            <Select.Option value={3}>DuckDuckGo</Select.Option>
                            <Select.Option value={4}>Brave</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Jasne logo (wyświetlane na ciemny tle)"
                    >
                        {siteSettings.logo && <div className="w-full p-4 mb-2 bg-slate-700 flex flex-col">
                            <Tooltip title={"Usuń"}>
                                <DeleteOutlined className="text-slate-100 cursor-pointer self-end mb-[2px]"
                                                onClick={() => setSiteSettings({...siteSettings, logo: ''})}/>
                            </Tooltip>
                            <img src={siteSettings.logo} className="mx-auto mb-4" alt="Jasne logo"/>
                        </div>}
                        {!siteSettings.logo &&
                            <p className="text-slate-600 italic mb-2">Jasne logo nie zostało przesłane</p>}
                        <Upload {...logoUploadProps}>
                            <RetoricButton outline icon={<UploadOutlined/>}>Dodaj obraz</RetoricButton>
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        label="Ciemne logo (wyświetlane na jasnym tle)"
                    >
                        {siteSettings.logoDark && <div className="w-full p-4 mb-2 bg-slate-100 flex flex-col">
                            <Tooltip title={"Usuń"}>
                                <DeleteOutlined className="text-slate-700 cursor-pointer self-end mb-[2px]"
                                                onClick={() => setSiteSettings({
                                                    ...siteSettings,
                                                    logoDark: ''
                                                })}/>
                            </Tooltip>
                            <img src={siteSettings.logoDark} className="mx-auto mb-4" alt="Ciemne logo"/>
                        </div>}
                        {!siteSettings.logoDark &&
                            <p className="text-slate-600 italic mb-2">Ciemne logo nie zostało przesłane</p>}
                        <Upload {...logoDarkUploadProps}>
                            <RetoricButton outline icon={<UploadOutlined/>}>Dodaj obraz</RetoricButton>
                        </Upload>
                    </Form.Item>
                    <RetoricButton type="primary" htmlType="submit">
                        Zapisz
                    </RetoricButton>
                </Form>}
                {loading && <Skeleton active/>}
            </RetoricCard>
            <RetoricCard className={'self-stretch lg:px-8 px-2'}>
                <h3 className="text-lg text-slate-700 mb-4">Ustawienia uwierzytelnienia</h3>
                {!loading && <Form
                    name="auth-settings"
                    layout={'vertical'}
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    style={{maxWidth: 800}}
                    form={accountSettingsForm}
                    onFinish={updateAccountSettings}
                    initialValues={{remember: true}}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Wymuszanie zmiany hasła"
                        name="passwordExpiry"
                        help={'Wartość kontroluje co ile użytkownicy będą musieli zmienić swoje hasło.'}
                        rules={[{
                            required: true,
                            message: 'Wartość dla Wymuszanie zmiany hasła jest wymagana!'
                        }]}
                    >
                        <Select
                            options={passwordResetOptions} className="rounded-none"
                            onSelect={(x) => accountSettingsForm.setFieldsValue({passwordExpiry: parseInt(x)})}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Minimalna długość hasła"
                        name="minimumPasswordLength"
                        help="Wartość kontroluje minimalną długość hasła. Rekomendowana wartość to minimum 10 znaków"
                        rules={[{required: true, message: 'Minimalna długość hasła jest wymagana!'}]}
                    >
                        <Input type="number" className="rounded-none"/>
                    </Form.Item>
                    <Form.Item
                        label="Pola edytowalne przez użytkowników"
                        name="controllableProperties"
                        className="mt-8"
                        help={'Wartość kontroluje, które informacje o sobie mogą zostać zmienione przez użytkownika.'}
                    >
                        <Select
                            className="bg-offwhite rounded-none"
                            mode="multiple"
                            size={'large'}
                            onChange={(x) => accountSettingsForm.setFieldsValue({controllableProperties: x})}
                            placeholder="Wybierz uprawnienia użytkownika"
                            style={{width: '100%'}}
                            options={Object.keys(ControllableProperties).map((key: number | string) => {
                                return ({
                                    value: ControllableProperties[key as keyof typeof ControllableProperties],
                                    label: ControllablePropertiesScopeMapper(ControllableProperties[key as keyof typeof ControllableProperties])
                                });
                            })}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Zarządzanie statusem urlopu przez użytkownika"
                        name="isSelfHolidayManagementEnabled"
                        help="Czy użytkonwik może zarządzać statusem własnych urlopów?"
                    >
                        <Radio.Group>
                            <Radio value={false}>Tak</Radio>
                            <Radio value={true}>Nie</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <RetoricButton type="primary" htmlType="submit" className="mt-4">
                        Zapisz
                    </RetoricButton>
                </Form>}
                {loading && <Skeleton active/>}
            </RetoricCard>
            <RetoricCard className={'self-stretch flex flex-col gap-4 lg:px-8 px-2'}>
                <h3 className="text-lg text-slate-700">Domyślne aplikacje użytkowników</h3>
                <Card>
                    <Skeleton avatar active loading={false}>
                        <Meta
                            avatar={<HomeOutlined style={{fontSize: '1rem'}}/>}
                            title="Aplikacje"
                            description={<div>
                                <p className="text-slate-600">W tej sekcji możesz skonfigurować, które aplikacje
                                    będą domyślnie wyświetlane użytkownikom w sekcji "Twoje aplikacje"</p>
                            </div>}
                        />
                    </Skeleton>
                </Card>
                <Space>
                    <RetoricButton outline onClick={() => {
                        setInsertMode({isInsert: true, value: {}, mode: 'app'})
                        cancelEdit();
                    }}>
                        Dodaj aplikację
                    </RetoricButton>
                    <RetoricButton outline onClick={() => {
                        setInsertMode({isInsert: true, value: {}, mode: 'category'})
                        cancelEdit();
                    }}>
                        Dodaj kategorię
                    </RetoricButton>
                </Space>
                <Tree
                    showLine
                    expandedKeys={dataSource.map(item => item.key)}
                    switcherIcon={<DownOutlined/>}
                    defaultExpandedKeys={selectedValues}
                    selectedKeys={selectedValues}
                    treeData={dataSource}
                    onSelect={(selectedKeys, info) => editApp(selectedKeys)}
                />
                {editMode.isEdit &&
                    <Card title={<div className={'apps-modal__edit-form-header'}>
                        <span>Edytowanie {editMode.mode === 'category' ? 'kategorii' : 'aplikacji'}</span>
                        <ActionButtons/></div>}>
                        {editMode.mode === 'category' && <div>
                            <div className={'apps-modal__edit-form'}>
                                <div>
                                    <label>Nazwa</label>
                                    <Input value={editMode.value.displayName} className="rounded-none"
                                           onKeyDown={(e) => {
                                               if (e.key === 'Enter' && editMode.isEdit) saveEdit();
                                               if (e.key === 'Enter' && !editMode.isEdit) saveInsert();
                                           }}
                                           onChange={(x) => onFormEdit(x, 'displayName')}/>
                                </div>
                            </div>
                        </div>}
                        {editMode.mode === 'app' && <div>
                            <div className={'apps-modal__edit-form'}>
                                <div>
                                    <label>Nazwa</label>
                                    <Input value={editMode.value.displayName} className="rounded-none"
                                           onKeyDown={(e) => {
                                               if (e.key === 'Enter' && editMode.isEdit) saveEdit();
                                               if (e.key === 'Enter' && !editMode.isEdit) saveInsert();
                                           }}
                                           onChange={(x) => onFormEdit(x, 'displayName')}/>
                                </div>
                                <div>
                                    <label>Link</label>
                                    <AutoComplete
                                        options={SupportedApps} className="rounded-none"
                                        defaultValue={editMode.value.url}
                                        onSearch={(x) => onFormEdit(x, 'url')}
                                        onSelect={(x) => onFormEdit(x, 'url')}
                                    />
                                </div>
                                <div>
                                    <label>Miejsce wyświetlania</label>
                                    <Select value={editMode.value.targetAppSpace} style={{width: '100%'}}
                                            allowClear
                                            onChange={(x) => onFormEdit(x, 'targetAppSpace')}>
                                        <Select.Option value={TargetAppSpace.All}>Wszędzie</Select.Option>
                                        <Select.Option value={TargetAppSpace.Menu}>Menu w
                                            nagłówku</Select.Option>
                                        <Select.Option value={TargetAppSpace.Index}>Strona
                                            główna</Select.Option>
                                    </Select>
                                </div>
                                <div>
                                    <label>Kategoria</label>
                                    <Select defaultValue={editMode.value.category} style={{width: '100%'}}
                                            allowClear
                                            className="rounded-none"
                                            onChange={(x) => onFormEditCategory(x)}>
                                        <Select.Option value={''}>Brak</Select.Option>
                                        {dataSource.filter(x => x.key !== 'category-blank').map(x =>
                                            <Select.Option
                                                value={x.key} key={x.key}>{x.title}</Select.Option>)}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        }
                    </Card>
                }
                {insertMode.isInsert &&
                    <Card title={<div className={'apps-modal__edit-form-header'}>
                        <span>Dodawanie {insertMode.mode === 'category' ? 'kategorii' : 'aplikacji'}</span>
                        <ActionButtons/></div>}>
                        {insertMode.mode === 'category' && <div>
                            <div className={'apps-modal__edit-form'}>
                                <div>
                                    <label>Nazwa</label>
                                    <Input value={insertMode.value.displayName} className="rounded-none"
                                           onKeyDown={(e) => {
                                               if (e.key === 'Enter' && editMode.isEdit) saveEdit();
                                               if (e.key === 'Enter' && !editMode.isEdit) saveInsert();
                                           }}
                                           onChange={(x) => onInsertFormEdit(x, 'displayName')}/>
                                </div>
                            </div>
                        </div>}
                        {insertMode.mode === 'app' && <div>
                            <div className={'apps-modal__edit-form'}>
                                <div>
                                    <label>Nazwa</label>
                                    <Input value={insertMode.value.displayName} className="rounded-none"
                                           onKeyDown={(e) => {
                                               if (e.key === 'Enter' && editMode.isEdit) saveEdit();
                                               if (e.key === 'Enter' && !editMode.isEdit) saveInsert();
                                           }}
                                           onChange={(x) => onInsertFormEdit(x, 'displayName')}/>
                                </div>
                                <div>
                                    <label>Link</label>
                                    <AutoComplete
                                        options={SupportedApps} className="rounded-none"
                                        onSearch={(x) => onInsertFormEdit(x, 'url')}
                                        onSelect={(x) => onInsertFormEdit(x, 'url')}
                                    />
                                </div>
                                <div>
                                    <label>Miejsce wyświetlania</label>
                                    <Select defaultValue={TargetAppSpace.All} style={{width: '100%'}} allowClear
                                            onChange={(x) => onInsertFormEdit(x, 'targetAppSpace')}>
                                        <Select.Option value={TargetAppSpace.All}>Wszędzie</Select.Option>
                                        <Select.Option value={TargetAppSpace.Menu}>Menu w nagłówku</Select.Option>
                                        <Select.Option value={TargetAppSpace.Index}>Strona główna</Select.Option>
                                    </Select>
                                </div>
                                <div>
                                    <label>Kategoria</label>
                                    <Select defaultValue={insertMode.value.category} style={{width: '100%'}}
                                            allowClear
                                            className="rounded-none"
                                            onChange={(x) => onInsertFormEditCategory(x)}>
                                        <Select.Option value={''}>Brak</Select.Option>
                                        {dataSource.filter(x => x.key !== 'category-blank').map(x =>
                                            <Select.Option
                                                value={x.key} key={x.key}>{x.title}</Select.Option>)}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        }
                    </Card>
                }
            </RetoricCard>
            {LDAP_ENABLED && <RetoricCard className={'self-stretch flex flex-col gap-4 lg:px-8 px-2'}>
                <h3 className="text-lg text-slate-700">Tryb uwierzytelnienia</h3>
                {authType === 0 && <Card>
                    <Skeleton avatar active loading={false}>
                        <Meta
                            avatar={<HomeOutlined style={{fontSize: '1rem'}}/>}
                            title="Uwierzytelnienie lokalne"
                            description={<div>
                                <p className="text-slate-600">Użytkownicy będą przechowywani w bazie danych
                                    intranetu.
                                    Uwierzytelnienie będzie
                                    przeprowadzone korzystając z tej bazy danych.</p>
                            </div>}
                        />
                    </Skeleton>
                </Card>}
                {authType === 1 && <Card>
                    <Skeleton avatar active loading={false}>
                        <Meta
                            avatar={<CloudOutlined style={{fontSize: '1rem'}}/>}
                            title="Uwierzytelnienie LDAP / ActiveDirectory"
                            description={<div>
                                <p className="text-slate-600">Użytkownicy będą przechowywani w bazie danych
                                    intranetu.
                                    Uwierzytelnienie będzie
                                    przeprowadzone poprzez łączenie się za pomocą protokołu LDAP z
                                    ActiveDirectory.</p>
                            </div>}
                        />
                    </Skeleton>
                </Card>}
                {authType === 2 && <Card>
                    <Skeleton avatar active loading={false}>
                        <Meta
                            avatar={<ApartmentOutlined style={{fontSize: '1rem'}}/>}
                            title="Uwierzytelnienie hybrydowe"
                            description={<div>
                                <p className="text-slate-600">Użytkownicy będą przechowywani w bazie danych
                                    intranetu.
                                    Uwierzytelnienie będzie
                                    przeprowadzone poprzez łączenie się za pomocą protokołu LDAP z
                                    ActiveDirectory,
                                    jeśli użytkownik nie istnieje lub podane hasło jest nieprawidłowe,
                                    uwierzytelnienie
                                    będzie przeprowadzone na podstawie bazy danych.</p>
                            </div>}
                        />
                    </Skeleton>
                </Card>}
                {!loading && <div className={'system__auth-mode__actions'}>
                    <Radio.Group onChange={onChange} value={authType}>
                        <Radio value={0}>Lokalny</Radio>
                        <Radio value={1}>LDAP / ActiveDirectory</Radio>
                        <Radio value={2}>Hybrydowy</Radio>
                    </Radio.Group>
                    <RetoricButton onClick={updateAuthMode} className="mb-4">Zapisz</RetoricButton>
                </div>}
                {loading && <Skeleton active/>}
            </RetoricCard>}
            {LDAP_ENABLED && <RetoricCard className={'self-stretch lg:px-8 px-2'}>
                <h3 className="text-lg text-slate-700 mb-4">Konfiguracja połączenia LDAP</h3>
                {!loading && <Form
                    name="basic"
                    layout={'vertical'}
                    disabled={authType === 0}
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    style={{maxWidth: 800}}
                    form={form}
                    onFinish={updateLdapSettings}
                    initialValues={{remember: true}}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Adres serwera"
                        name="hostname"
                        rules={[{required: true, message: 'Adres serwera jest wymagany!'}]}
                    >
                        <Input placeholder={'127.0.0.1'} className="rounded-none"/>
                    </Form.Item>

                    <Form.Item
                        label="Port"
                        name="port"
                        rules={[{required: true, message: 'Port jest wymagany!'}]}
                    >
                        <Input type={'number'} placeholder={'389'} className="rounded-none"/>
                    </Form.Item>

                    <Form.Item
                        label="Podstawowe DN (Base DN)"
                        name="baseDn"
                        rules={[{required: true, message: 'Podstawowe DN jest wymagane!'}]}
                    >
                        <Input placeholder={'dc=example,dc=org'} className="rounded-none"/>
                    </Form.Item>

                    <Form.Item
                        label="DN Użytkowników (User DN)"
                        name="userDn"
                        rules={[{required: true, message: 'DN Użytkowników jest wymagane!'}]}
                    >
                        <Input placeholder={'ou=users'} className="rounded-none"/>
                    </Form.Item>

                    <Form.Item label="Wersja protokołu LDAP" name={'ldapVersion'}>
                        <Radio.Group>
                            <Radio value={2}> LDAP v2 </Radio>
                            <Radio value={3}> LDAP v3 </Radio>
                        </Radio.Group>
                    </Form.Item>

                    <RetoricButton htmlType="submit" disabled={authType === 0}>
                        Zapisz
                    </RetoricButton>
                </Form>}
                {loading && <Skeleton active/>}
            </RetoricCard>}
        </div>
    );
}

export default System;
