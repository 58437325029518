import React, {useEffect, useState} from 'react';
import {Avatar, notification, Select, Skeleton, Tooltip} from "antd";
import Meta from "antd/es/card/Meta";
import './NewsCard.css';
import DateFormatter from "../../extensions/DateFormatter";
import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    PushpinOutlined,
    PushpinTwoTone,
    SaveOutlined
} from "@ant-design/icons";
import {NewsService} from "../../services/NewsService";
import {useAuth} from "../../extensions/Auth";
import {TrainingService} from "../../services/TrainingService";
import RetoricCard from "../../designsystems/RetoricCard/RetoricCard";
import {EntryCategory} from "../../screens/AdminView/Training/TrainingCategoriesModal/TrainingCategoriesModal";
import {UserService} from "../../services/UserService";
import ConfirmationModal, {ConfirmationModalProps} from "../../designsystems/ConfirmationModal/ConfirmationModal";
import {ErrorMessages} from "../../extensions/ErrorMessages";
import {StaticAsset} from "../../types/StaticAsset";
import AttachmentsDisplay from "../AttachmentsDisplay/AttachmentsDisplay";

export interface NewsCardProps {
    id: string;
    title: string;
    description: string;
    shortDescription?: string;
    avatar: string;
    author: string;
    email: string;
    date: string;
    approved: boolean;
    categoryId?: string | null;
    categoryName?: string;
    categories?: EntryCategory[];
    attachments?: StaticAsset[];
    withApproval?: boolean;
    className?: string;
    isTraining?: boolean;
    pinned?: boolean;
    isRedact?: boolean;
    onClick?: () => void;
    refresh?: () => void;
}

function NewsCard(props: NewsCardProps) {
    const auth = useAuth();
    const {
        id,
        title,
        description,
        shortDescription,
        avatar,
        author,
        email,
        date,
        attachments,
        withApproval,
        approved,
        className,
        categoryName,
        categoryId,
        categories,
        isTraining,
        pinned,
        isRedact,
        onClick,
        refresh
    } = props;
    const [api, contextHolder] = notification.useNotification();
    const [status, setStatus] = useState(approved);
    const [displayEdit, setDisplayEdit] = React.useState(false);
    const [editing, setEditing] = React.useState(false);
    const [newCategoryId, setNewCategoryId] = React.useState(categoryId || '');
    const [newNewsCategoryId, setNewNewsCategoryId] = React.useState(categoryId || '');
    const [avatarUrl, setAvatarUrl] = useState(avatar);
    const [newsDeleteModal, setNewsDeleteModal] = useState(false);
    const [modelPayload, setModelPayload] = useState({} as ConfirmationModalProps);

    useEffect(() => {
        setAvatarUrl(UserService.getUserAvatar(avatar, email));
    }, [email, avatar]);

    const changeNewsStatus = (id: string, status: boolean) => {
        NewsService.changeNewsStatus(id, status).then(() => {
            setStatus(status);
            api.info({
                message: `Status aktualności zmieniony pomyślnie`,
                description: status ? 'Aktualność została zaakceptowana, będzie ona widoczna na stronie głównej' : 'Aktualność została odrzucona, nie będzie ona widoczna na stronie głównej',
                placement: 'top'
            });

            if (refresh)
                refresh();
        }).catch(() => {
            api.error({
                message: `Błąd`,
                description: 'Wystąpił błąd podczas zmiany statusu!',
                placement: 'top'
            });
        });
    }

    const deleteTraining = (id: string) => {
        TrainingService.deleteTraining(id).then(() => {
            api.info({
                message: `Poradnik usunięty pomyślnie`,
                description: 'Poradnik został usunięty!',
                placement: 'top'
            });

            if (refresh)
                refresh();
        }).catch((e: any) => {
            if (e.message === 'notFound')
                api.error({
                    message: `Błąd`,
                    description: 'Wystąpił błąd podczas usuwania poradnika!',
                    placement: 'top'
                });
            else
                auth.handleLogout();
        });
    }

    const changeTrainingStatus = (id: string, status: boolean) => {
        TrainingService.changeTrainingStatus(id, status).then(() => {
            setStatus(status);
            api.info({
                message: `Status poradnika zmieniony pomyślnie`,
                description: status ? 'Poradnik został zaakceptowany, będzie on widoczny w sekcji Poradniki' : 'Poradnik został odrzucony, nie będzie on widoczny w sekcji Poradniki',
                placement: 'top'
            });

            if (refresh)
                refresh();
        }).catch(() => {
            api.error({
                message: `Błąd`,
                description: 'Wystąpił błąd podczas zmiany statusu!',
                placement: 'top'
            });
        });
    }

    const deleteNews = (id: string) => {
        NewsService.deleteNews(id).then(() => {
            api.info({
                message: `Aktualność została pomyślnie usunięta`,
                description: 'Aktualność została usunięty!',
                placement: 'top'
            });

            if (refresh)
                refresh();
        }).catch((e: any) => {
            if (e.message === 'notFound')
                api.error({
                    message: `Błąd`,
                    description: 'Wystąpił błąd podczas usuwania aktualności!',
                    placement: 'top'
                });
            else
                auth.handleLogout();
        });
    }

    const updateCategoryId = (id: string) => {
        setNewCategoryId(id);
    }

    const updateNewsCategoryId = (id: string) => {
        setNewNewsCategoryId(id);
    }

    const pinNews = (id: string, pinned?: boolean) => {
        NewsService.pinNews(id, pinned ?? false).then(() => {
            api.info({
                message: `Aktualność została przypięta`,
                description: 'Pomyślnie przypięto aktualność!',
                placement: 'top'
            });

            if (refresh)
                refresh();
        })
            .catch((e: any) => {
                if (e.message === ErrorMessages.forbidden)
                    api.error({
                        message: `Błąd`,
                        description: 'Twoje uprawnienia nie pozwalają na przypięcie aktualności!',
                        placement: 'top'
                    });
                else if (e.message === ErrorMessages.notFound)
                    api.error({
                        message: `Błąd`,
                        description: 'Ta aktualność nie istnieje!',
                        placement: 'top'
                    })
                else
                    auth.handleLogout();
            });
    }

    const saveCategory = () => {
        TrainingService.updateTrainingCategory(id, newCategoryId)
            .then(() => {
                api.info({
                    message: `Kategoria zmieniona pomyślnie`,
                    description: 'Oprogramowanie zostało przypisane do nowej kategorii!',
                    placement: 'top'
                });

                if (refresh)
                    refresh()

                setEditing(false);
            })
            .catch((e: any) => {
                if (e.message === ErrorMessages.trainingDoesNotExists)
                    api.error({
                        message: `Błąd`,
                        description: 'Ten poradnik nie istnieje!',
                        placement: 'top'
                    });
                else if (e.message === ErrorMessages.categoryDoesNotExists)
                    api.error({
                        message: `Błąd`,
                        description: 'Ta kategoria nie istnieje!',
                        placement: 'top'
                    })
                else
                    auth.handleLogout();
            });
    }

    const saveNewsCategory = () => {
        NewsService
            .updateNewsCategory(id, newNewsCategoryId)
            .then(() => {
                api.info({
                    message: `Kategoria zmieniona pomyślnie`,
                    description: 'Oprogramowanie zostało przypisane do nowej kategorii!',
                    placement: 'top'
                });

                if (refresh)
                    refresh()

                setEditing(false);
            })
            .catch((e: any) => {
                if (e.message === ErrorMessages.newsDoesNotExists)
                    api.error({
                        message: `Błąd`,
                        description: 'Ta aktualność nie istnieje!',
                        placement: 'top'
                    });
                else if (e.message === ErrorMessages.categoryDoesNotExists)
                    api.error({
                        message: `Błąd`,
                        description: 'Ta kategoria nie istnieje!',
                        placement: 'top'
                    })
                else
                    auth.handleLogout();
            });
    }

    return (
        <RetoricCard
            className={`card__selector ${className}`}
            onClick={onClick ?? undefined}
        >
            <div>
                {withApproval && <div className="mb-8 flex flex-col w-full gap-0">
                    <div className="flex flex-row justify-between w-full">
                        <span className="text-lg">Status: {status ?
                            <span className="text-green-700">Zaakceptowany</span> :
                            <span className="text-orange-700">Oczekujący</span>}</span>
                        <div className="flex flex-row gap-4">
                            <Tooltip title="Zaakceptuj">
                                <CheckOutlined
                                    className="text-lg"
                                    onClick={() => isTraining ? changeTrainingStatus(id, true) : changeNewsStatus(id, true)}/>
                            </Tooltip>
                            <Tooltip title="Odrzuć">
                                <CloseOutlined
                                    className="text-lg"
                                    onClick={() => isTraining ? changeTrainingStatus(id, false) : changeNewsStatus(id, false)}/>
                            </Tooltip>
                            {(!isTraining && pinned) &&
                                <Tooltip title={'Odepnij'}>
                                    <PushpinTwoTone
                                        className="text-lg"
                                        onClick={() => pinNews(id, false)}/>
                                </Tooltip>
                            }
                            {(!isTraining && !pinned) &&
                                <Tooltip title={'Przypnij'}>
                                    <PushpinOutlined
                                        className="text-lg"
                                        onClick={() => pinNews(id, true)}/>
                                </Tooltip>
                            }
                            <Tooltip title="Edytuj">
                                <EditOutlined
                                    className="text-lg"
                                    onClick={() => auth.navigate(`/admin/${isTraining ? 'training' : 'news'}/edit/${id}`)}/>
                            </Tooltip>
                            <Tooltip title="Usuń">
                                <DeleteOutlined
                                    className="text-lg cursor-pointer" onClick={() => {
                                    const payload = {
                                        title: `Usuń ${isTraining ? 'poradnik' : 'aktualność'}`,
                                        open: true,
                                        setOpen: setNewsDeleteModal,
                                        yesAction: () => {
                                            isTraining ? deleteTraining(id) : deleteNews(id)
                                            setNewsDeleteModal(false);
                                        },
                                        noAction: () => setNewsDeleteModal(false),
                                    } as ConfirmationModalProps;

                                    payload.infoboxTitle = isTraining ? "Wybrany poradnik zostanie usunięty" : "Wybrana aktualność zostanie usunięta";
                                    const text = isTraining ?
                                        "Usunięty poradnik nie może zostać przywrócony"
                                        :
                                        "Usunięta aktualność nie może zostać przywrócona";
                                    payload.children = <>
                                        <p className="text-slate-600">{text}, czy jesteś pewien, że chcesz
                                            usunąć <i>"{title}"</i>?</p>
                                    </>

                                    setModelPayload(payload);
                                    setNewsDeleteModal(true);
                                }}/>
                            </Tooltip>
                        </div>
                    </div>
                    {isTraining &&
                        <div className="flex flex-row justify-between w-full" onMouseEnter={() => setDisplayEdit(true)}
                             onMouseLeave={() => setDisplayEdit(false)}>
                            {!editing && <div className="text-lg flex flex-row gap-2">{categoryName ?
                                <span className="text-slate-950">{categoryName}
                            </span> :
                                <span className="text-slate-600">Brak kategorii</span>}
                                {displayEdit && <Tooltip title="Zmień kategorię">
                                    <EditOutlined onClick={() => setEditing(true)}/>
                                </Tooltip>}
                            </div>}
                            {editing && <div className="text-lg flex flex-row gap-2">
                                <Select defaultValue={newCategoryId} allowClear style={{minWidth: 300}}
                                        onClear={() => setEditing(false)}
                                        onChange={updateCategoryId}>
                                    <Select.Option value={''}>Brak kategorii</Select.Option>
                                    {categories?.map((category) => <Select.Option value={category.id}
                                                                                  key={category.id}>{category.name}</Select.Option>)}
                                </Select>
                                <Tooltip title="Zapisz">
                                    <SaveOutlined onClick={() => saveCategory()}/>
                                </Tooltip>
                            </div>}
                        </div>}
                    {!isTraining &&
                        <div className="flex flex-row justify-between w-full mt-1" onMouseEnter={() => setDisplayEdit(true)}
                             onMouseLeave={() => setDisplayEdit(false)}>
                            {!editing && <div className="text-lg flex flex-row gap-2">{categoryName ?
                                <span className="text-slate-950">{categoryName}
                            </span> :
                                <span className="text-slate-600">Brak kategorii</span>}
                                {displayEdit && <Tooltip title="Zmień kategorię">
                                    <EditOutlined onClick={() => setEditing(true)}/>
                                </Tooltip>}
                            </div>}
                            {editing && <div className="text-lg flex flex-row gap-2">
                                <Select defaultValue={newNewsCategoryId} allowClear style={{minWidth: 300}}
                                        onClear={() => setEditing(false)}
                                        onChange={updateNewsCategoryId}>
                                    <Select.Option value={''}>Brak kategorii</Select.Option>
                                    {categories?.map((category) => <Select.Option value={category.id}
                                                                                  key={category.id}>{category.name}</Select.Option>)}
                                </Select>
                                <Tooltip title="Zapisz">
                                    <SaveOutlined onClick={() => saveNewsCategory()}/>
                                </Tooltip>
                            </div>}
                        </div>}
                </div>}
            </div>
            {contextHolder}
            <Skeleton loading={false} avatar active>
                <Meta
                    avatar={<Avatar src={avatarUrl} className={'h-16 w-16 lg:block hidden'}/>}
                    title={
                        <div className="flex lg:flex-row flex-col-reverse lg:justify-between gap-2">
                            <span>{title}</span>
                            {pinned && <span className="italic font-light text-slate-500 self-end"><PushpinOutlined/> Przypięta</span>}
                        </div>}
                    description={
                        <div className="flex flex-col self-stretch">
                            <div
                                dangerouslySetInnerHTML={{__html: shortDescription ? `<p>${shortDescription}</p>` : description}}></div>
                            {!isTraining && attachments && attachments.length > 0 &&
                                <AttachmentsDisplay attachments={attachments}/>}
                        </div>}
                />
            </Skeleton>
            <div>
                <span className="float-right mt-3">
                            <Avatar src={avatarUrl} size={32} className={'mobile-only'}/> <span
                    className="h-8 align-middle"
                >{author}, {DateFormatter(date)}</span>
                    </span>
            </div>
            <ConfirmationModal {...modelPayload} open={newsDeleteModal} setOpen={setNewsDeleteModal}/>
        </RetoricCard>
    );
}

export default NewsCard;
