import {Card} from "antd";
import {
    AppstoreAddOutlined,
    CalendarOutlined,
    ContainerOutlined,
    InfoCircleOutlined,
    RobotOutlined,
    SecurityScanOutlined
} from "@ant-design/icons";
import React from "react";
import {Footer} from "antd/es/layout/layout";
import AuthState, {User} from "../../types/AuthState";

export interface FooterNavProps {
    auth: AuthState;
    user: User;
}

export default function FooterNav({auth, user}: FooterNavProps) {
    return (<Footer className={'footer'}>
        <Card
            hoverable
            bordered={false}
            onClick={() => auth.navigate('/')}
            className={auth.route === '/' ? 'active-overline' : ''}
            cover={<ContainerOutlined className={'footer__card-icon'}/>}
        >
        </Card>
        {user?.isAiEnabled &&
            <Card
                hoverable
                bordered={false}
                onClick={() => auth.navigate('/aiassistant')}
                className={auth.route === '/aiassistant' ? 'active-overline' : ''}
                cover={<RobotOutlined className={'footer__card-icon'}/>}
            >
            </Card>}
        <Card
            hoverable
            bordered={false}
            onClick={() => auth.navigate('/information')}
            className={auth.route === '/information' ? 'active-overline' : ''}
            cover={<InfoCircleOutlined className={'footer__card-icon'}/>}
        >
        </Card>
        <Card
            hoverable
            bordered={false}
            onClick={() => auth.navigate('/modules')}
            className={auth.route.startsWith('/modules') ? 'active-overline' : ''}
            cover={<AppstoreAddOutlined className={'footer__card-icon'}/>}
        >
        </Card>
        <Card
            hoverable
            bordered={false}
            onClick={() => auth.navigate('/calendar')}
            className={auth.route === '/calendar' ? 'active-overline' : ''}
            cover={<CalendarOutlined className={'footer__card-icon'}/>}
        >
        </Card>
        <Card
            hoverable
            bordered={false}
            onClick={() => auth.navigate('/training')}
            className={auth.route === '/training' ? 'active-overline' : ''}
            cover={<SecurityScanOutlined className={'footer__card-icon'}/>}
        >
        </Card>
    </Footer>);
}
