import {CalendarEvent, CalendarEventType, CalendarTitleMapper} from "../screens/UserView/Calendar/RetoricCalendar";
import dayjs from "dayjs";
import localeDayJs from 'dayjs/locale/pl';

export const GenerateInitials = (name: string) => {
    const splits = name.split(' ');

    return splits[0].charAt(0) + splits[splits.length - 1].charAt(0);
}

export const CalendarEventTypeFormatter = (t: CalendarEventType) => {
    const type = CalendarTitleMapper(t);

    if (type.length <= 5) return type.toUpperCase();

    return type.replaceAll(/[aeiou]/gi, '').toUpperCase();
}

export const DayFormatter = (date: dayjs.Dayjs) => {
    const today = dayjs().hour(0).minute(0).second(0).millisecond(0);

    if(today.isSame(date, 'day')) return 'Dzisiejsze wydarzenia';
    if(today.add(1, 'day').isSame(date, 'day')) return 'Jutrzejsze wydarzenia';
    if(today.subtract(1, 'day').isSame(date, 'day')) return 'Wczorajsze wydarzenia';

    return `Wydarzenia z ${date.locale(localeDayJs).format('DD MMMM YYYY')}`;
}

export const DurationMinFormatter = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
    const duration = end.diff(start, 'minute');

    if(duration === 0) return "0 minut";
    if(duration === 1) return "1 minuta";
    if(duration <= 4 || (duration >= 21 && (duration % 10) >= 2 && (duration % 10) <= 4)) return `${duration} minuty`;

    return `${duration} minut`;
}

export const ShortDurationMinFormatter = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
    const duration = end.diff(start, 'minute');

    return `${duration} m`;
}

export const ShortDurationWholeDayFormatter = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
    const duration = end.diff(start, 'day');

    return `${duration + 1}d`;

}

export const ShortDurationFormatter = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
    const duration = end.diff(start, 'minute');

    if(duration < 60) return `${duration}m`;
    if(duration % 60 === 0 && duration < 1440) return `${duration / 60}g`;
    if(duration % 1440 === 0 && duration >= 1440) return `${duration / 60 / 24}d`;

    if(duration < 1440) return `${Math.floor(duration / 60)}g ${duration % 60}m`;
    return `${Math.floor(duration / 60 / 24)}d ${Math.floor(duration / 60) % 24}g ${duration % 60}m`;
}

export const ShortDurationNumberFormatter = (duration: number) => {

    if(duration < 60) return `${duration}m`;
    if(duration % 60 === 0 && duration < 1440) return `${duration / 60}g`;
    if(duration % 1440 === 0 && duration >= 1440) return `${duration / 60 / 24}d`;

    if(duration < 1440) return `${Math.floor(duration / 60)}g ${duration % 60}m`;
    return `${Math.floor(duration / 60 / 24)}d ${Math.floor(duration / 60) % 24}g ${duration % 60}m`;
}

export const MixedDurationFormatter = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
    const duration = end.diff(start, 'minute');

    if (duration < 60) return DurationMinFormatter(start, end);

    return ShortDurationFormatter(start, end);
}

export const TimeSpanFormatter = (event: CalendarEvent, currentDay: dayjs.Dayjs) => {
    const days = event.date.end.diff(event.date.start, 'day');
    if(event.date.isWholeDay) {
        const diff = event.date.start.diff(currentDay, 'day');

        return <div className="flex flex-row gap-2 justify-center items-center">
        <span className="text-gray-700">
            ({days + 1} dni)
        </span>
            <div className="flex flex-col justify-end items-end">
                <span>{event.date.start.locale(localeDayJs).format("DD.MM.YYYY")}</span>
                <span>{event.date.end.locale(localeDayJs).format("DD.MM.YYYY")}</span>
            </div>
        </div>
    }

    const sameDay = event.date.start.isSame(event.date.end, 'day');

    if(sameDay) return <div className="flex flex-row gap-2 justify-center items-center">
        <span className="text-gray-700">
            ({MixedDurationFormatter(event.date.start, event.date.end)})
        </span>
        <div className="flex flex-col justify-end items-end">
            <span>{event.date.start.locale(localeDayJs).format("HH:mm")}</span>
            <span>{event.date.end.locale(localeDayJs).format("HH:mm")}</span>
        </div>
    </div>

    return <div className="flex flex-row gap-2 justify-center items-center">
        <span className="text-gray-700">
            ({MixedDurationFormatter(event.date.start, event.date.end)})
        </span>
        <div className="flex flex-col justify-end items-end">
            <span>{event.date.start.locale(localeDayJs).format("DD.MM.YYYY HH:mm")}</span>
            <span>{event.date.end.locale(localeDayJs).format("DD.MM.YYYY HH:mm")}</span>
        </div>
    </div>
}
