export const ThemeColours = {
    'linkedin': '#0077b5',
    'facebook': '#3b5998',
    'twitter': '#1da1f2',
    'youtube': '#ff0000',
    'instagram': '#c32aa3',
    'email': '#ea4335',
    'github': '#333333',
    'offwhite': '#FBFBFB',
    'brandColor': {
        50: '#fff0f2',
        100: '#ffdde3',
        200: '#ffc1cb',
        300: '#ff96a7',
        400: '#ff5a75',
        500: '#ff274b',
        600: '#fb072f',
        700: '#d40124',
        800: '#ae0622',
        900: '#900c22',
        950: '#49000c',
    },
    'brandColor2': {
        '50': '#fef1f5',
        '100': '#fee5ed',
        '200': '#feccdd',
        '300': '#ffadc7',
        '400': '#fe6894',
        '500': '#f83c6e',
        '600': '#e81a47',
        '700': '#ca0c30',
        '800': '#a70d28',
        '900': '#8b1025',
        '950': '#550210',
    },
    'brandColor3': {
        '50': '#fbf4fa',
        '100': '#f8ebf5',
        '200': '#f2d8ed',
        '300': '#e9b8de',
        '400': '#da8cc6',
        '500': '#cb69ae',
        '600': '#b74b92',
        '700': '#9c3a78',
        '800': '#823263',
        '900': '#6d2e55',
        '950': '#421631',
    },
    'brandColor4': {
        '50': '#fcf6f0',
        '100': '#f9e8db',
        '200': '#f2cfb6',
        '300': '#e9ae88',
        '400': '#df8558',
        '500': '#d86537',
        '600': '#d1522e',
        '700': '#a73c27',
        '800': '#863226',
        '900': '#6d2c21',
        '950': '#3a1410',
    },
    'active': '#fb072f',
};
