import {Divider} from "antd";
import React from "react";
import {StaticAsset} from "../../types/StaticAsset";
import {StaticService} from "../../services/StaticService";

export interface AttachmentsDisplayProps {
    attachments: StaticAsset[];
}

export default function AttachmentsDisplay({attachments}: AttachmentsDisplayProps) {
    return (
        <div>
            <Divider/>
            <ul>
                <h2 className="mt-4 text-slate-800 text-md">Załączniki</h2>
                {attachments.map(x => <li key={x.id} className="text-sm"><span
                    onClick={() => StaticService.openAttachment(x.name)}
                    className="text-sm hover:text-red-600 cursor-pointer">{x.friendlyName}</span></li>)}
            </ul>
        </div>
    )
}
