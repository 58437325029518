import React, {useEffect, useState} from 'react';
import './RetoricCalendar.css';
import RetoricBreadcrumbs from "../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import {Select, Tabs} from "antd";
import locations from "../../../extensions/Locations";
import dayjs from "dayjs";
import localeDayJs from 'dayjs/locale/pl';
import CalendarDayView from "./CalendarDayView/CalendarDayView";
import {faker} from '@faker-js/faker';
import CalendarWeekView from "./CalendarWeekView/CalendarWeekView";
import CalendarMonthView from "./CalendarMonthView/CalendarMonthView";
import RetoricButton from "../../../designsystems/RetoricButton/RetoricButton";
import {TableOutlined, LayoutOutlined, CalendarOutlined} from "@ant-design/icons";
import CreateMeetingModal, {CreateMeetingModalPayload} from "./CreateMeetingModal/CreateMeetingModal";
import CalendarMonthMobileView from "./CalendarMonthMobileView/CalendarMonthMobileView";
import {WEEKLY_ENABLED} from "../../../FeatureFlags";

export enum CalendarEventType {
    Meeting = 0,
    Training = 1,
    Holiday = 2,
    Delegation = 3,
    Other = 4
}

export enum CalendarEventLocationType {
    Online = 0,
    InPerson = 1,
    None = 2
}

export enum CalendarPriority {
    None = 0,
    Low = 10,
    Medium = 20,
    High = 30,
    Critical = 40
}

export const CalendarPriorityColourMapper = (priority: CalendarPriority) => {
    switch (priority) {
        case CalendarPriority.None:
            return "default";
        case CalendarPriority.Low:
            return "success";
        case CalendarPriority.Medium:
            return "processing";
        case CalendarPriority.High:
            return "warning";
        case CalendarPriority.Critical:
            return "error";
    }
}

export const CalendarPriorityMapper = (priority: CalendarPriority) => {
    switch (priority) {
        case CalendarPriority.None:
            return "Brak";
        case CalendarPriority.Low:
            return "Niski";
        case CalendarPriority.Medium:
            return "Średni";
        case CalendarPriority.High:
            return "Wysoki";
        case CalendarPriority.Critical:
            return "Krytyczny";
    }

}

export const CalendarTitleMapper = (type: CalendarEventType) => {
    switch (type) {
        case CalendarEventType.Meeting:
            return "Spotkanie";
        case CalendarEventType.Training:
            return "Szkolenie";
        case CalendarEventType.Holiday:
            return "Urlop";
        case CalendarEventType.Delegation:
            return "Delegacja";
        case CalendarEventType.Other:
            return "Inne";
    }
}

export const CalendarLocationMapper = (type: CalendarEventLocationType) => {
    switch (type) {
        case CalendarEventLocationType.Online:
            return "Online";
        case CalendarEventLocationType.InPerson:
            return "Stacjonarnie";
        case CalendarEventLocationType.None:
            return "Brak";
    }
}

// export const PastelColours = ["#f992ad", "#fbbcee", "#fab4c8", "#f78ecf", "#cfb9f7", "#e0cefd", "#a480f2", "#d4b0f9", "#c580ed"];
export const PastelColours = [
    "#f7c7b8",
    "#ffd8c0",
    "#ffe4cc",
    "#f9edc7",
    "#e2f0cb",
    "#d4f3ef",
    "#c2e2f0",
    "#d9d0e3",
    "#f1cfe3",
    "#fbd3e3",
    "#fcdede",
    "#fdebd1",
    "#f9f3cf",
    "#e5e8ea",
    "#f2f2f2",
    "#d7d7d7"
];


export interface CalendarEvent {
    colour: string;
    date: {
        start: dayjs.Dayjs;
        end: dayjs.Dayjs;
        isWholeDay: boolean;
    };
    description: {
        short: string;
        long: string;
    };
    eventType: CalendarEventType;
    id: string;
    location: {
        type: CalendarEventLocationType;
        link?: string;
        address?: string;
    };
    organiser: {
        id: string;
        name: string;
        colour: string;
    };
    attendees: {
        id: string;
        name: string;
        colour: string;
    }[]
    title: string;
    priority: CalendarPriority;
}

export interface CalendarEventRequest {
    date: {
        start: Date;
        end: Date;
        isWholeDay: boolean;
    };
    description: string;
    eventType: CalendarEventType;
    location: {
        type: CalendarEventLocationType;
        link?: string;
        address?: string;
    };
    attendees: string[];
    title: string;
    priority: CalendarPriority;
}

export interface CalendarProps {
    events: CalendarEvent[];
}

export interface CalendarMonthProps {
    modalState: boolean;
}

export interface CalendarMonthMobileProps {
    // events: CalendarEvent[];
    currentDay: dayjs.Dayjs;
    setCurrentDay: (day: dayjs.Dayjs) => void;
    setActiveTab: (tab: string) => void;
}

export interface CalendarDayProps {
    // events: CalendarEvent[];
    currentDay: dayjs.Dayjs;
    setCurrentDay: (day: dayjs.Dayjs) => void;
    isMobile: boolean;
    modalState: boolean;
}

export const FormatMonthDate = (date: dayjs.Dayjs) => {
    const d = date.locale(localeDayJs);
    const month = d.format('MMMM');

    return <span className={"capitalize"}>{month} {d.format("YYYY")}</span>;
}

function RetoricCalendar() {
    const [currentMonth, setCurrentMonth] = useState(dayjs());
    const [currentDay, setCurrentDay] = useState(dayjs().hour(0).minute(0).second(0).millisecond(0));
    const [activeTab, setActiveTab] = useState(localStorage.getItem('calendar-view') || 'day-view');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [createMeetingModalOpen, setCreateMeetingModalOpen] = useState(false);
    const [modalPayload, setModalPayload] = useState({} as CreateMeetingModalPayload)

    function createMeeting() {
        const now = dayjs();

        const current = activeTab === 'day-view' ? currentDay : currentMonth;

        const start = current.hour(now.hour()).minute(0).second(0).millisecond(0).add(1, 'hour');
        const end = current.hour(now.hour()).minute(0).second(0).millisecond(0).add(90, 'minutes');

        setModalPayload({start, end});
        setCreateMeetingModalOpen(true);
    }

    const updateDefaultViewMode = (value: string) => {
        setActiveTab(value);
        localStorage.setItem('calendar-view', value);
    }

    useEffect(() => {
        if(!WEEKLY_ENABLED && activeTab === 'week-view') {
            updateDefaultViewMode('day-view');
        }

        const handleResize = () => {
            const im = window.innerWidth < 768;
            setIsMobile(im);

            if (im && activeTab === 'week-view') {
                updateDefaultViewMode('day-view');
            }

            if(im && activeTab === 'month-view') {
                updateDefaultViewMode('month-view-mobile');
            }

            if(!im && activeTab === 'month-view-mobile') {
                updateDefaultViewMode('month-view');
            }
        }

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, [activeTab]);

    const options = [
        {
            label: 'Dzień',
            key: 'day-view',
            children: <CalendarDayView
                currentDay={currentDay}
                isMobile={isMobile}
                modalState={createMeetingModalOpen}
                setCurrentDay={setCurrentDay}
            />
        },
        // {
        //     label: 'Tydzień',
        //     key: 'week-view',
        //     children: <CalendarWeekView events={events.filter(x => x.date.start.isSame(currentMonth, 'week') || (x.date.isWholeDay))}/>
        // },
        {
            label: 'Miesiąc',
            key: 'month-view',
            children: <CalendarMonthView modalState={createMeetingModalOpen} />
        },
        {
            label: 'Miesiąc',
            key: 'month-view-mobile',
            children: <CalendarMonthMobileView currentDay={currentDay}
                                               setCurrentDay={setCurrentDay}
                                               setActiveTab={setActiveTab}/>
        }
    ]

    return (
        <div className="content">
            <RetoricBreadcrumbs locations={locations['calendar']}>Kalendarz</RetoricBreadcrumbs>
            <div className={'flex flex-row w-full'}>
                <Tabs
                    defaultActiveKey="week-view"
                    activeKey={activeTab}
                    renderTabBar={() => <div
                        className="w-full flex lg:flex-row flex-col lg:justify-end justify-center items-center gap-2 mb-2">
                        <Select size="large"
                                              defaultValue={activeTab}
                                              value={activeTab}
                                              onChange={(v: string) => updateDefaultViewMode(v)}
                                              className="min-w-[150px]">
                            <Select.Option value={'day-view'}><LayoutOutlined/> Dzień</Select.Option>
                            {!isMobile && WEEKLY_ENABLED && <Select.Option value={'week-view'}><TableOutlined/> Tydzień</Select.Option>}
                            {!isMobile && <Select.Option value={'month-view'}><CalendarOutlined/> Miesiąc</Select.Option>}
                            {isMobile && <Select.Option value={'month-view-mobile'}><CalendarOutlined/> Miesiąc</Select.Option>}
                        </Select>
                        <RetoricButton type={'primary'} outline size={'md'} className="min-w-[150px]"
                                       onClick={createMeeting}>Nowe wydarzenie</RetoricButton>
                    </div>}
                    onChange={(key) => setActiveTab(key)}
                    centered
                    className={'w-full'}
                    items={options}
                />
            </div>
            <CreateMeetingModal open={createMeetingModalOpen} setOpen={setCreateMeetingModalOpen}
                                payload={modalPayload}/>
        </div>
    );
}

export default RetoricCalendar;
