import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {ConfigProvider} from "antd";
import {ProvideAuth} from "./extensions/Auth";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <ProvideAuth>
        <ConfigProvider theme={{
            token: {
                colorBgContainer: '#F5F5F5',
                colorPrimary: '#49000c',
            },
        }}>
            <App/>
        </ConfigProvider>
    </ProvideAuth>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
