import React, {useEffect, useState} from 'react';
import './TrainingEdit.css';
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {Input, notification, Select} from "antd";
import {convertToHTML} from 'draft-convert';
import {TrainingService} from "../../../../services/TrainingService";
import TextArea from "antd/es/input/TextArea";
import Infobox from "../../../../designsystems/Infobox/Infobox";
import RetoricButton from "../../../../designsystems/RetoricButton/RetoricButton";
import RetoricBreadcrumbs from "../../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../../extensions/Locations";
import {EntryCategory} from "../TrainingCategoriesModal/TrainingCategoriesModal";
import {useParams} from "react-router-dom";
import {useAuth} from "../../../../extensions/Auth";
import {NewsCardProps} from "../../../../components/NewsCard/NewsCard";
import {EditorState} from "draft-js";
import {AdminScopes} from "../../../../types/Scopes";
import {ErrorMessages} from "../../../../extensions/ErrorMessages";
import {FromHtmlConfig, HtmlConverter} from "../../News/NewsEdit/NewsEdit";
import {uploadCallback} from "../../../UserView/News/NewsAdd/NewsAdd";
import {Attachment} from "../../../../types/StaticAsset";
import {BACKEND_BASE} from "../../../../services/LoginService";
import AttachmentsDragger from "../../../../components/AttachmentsDragger/AttachmentsDragger";

function TrainingEdit() {
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    const auth = useAuth();
    const [api, contextHolder] = notification.useNotification();
    const [title, setTitle] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [description, setDescription] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [categories, setCategories] = useState([] as EntryCategory[]);
    const [data, setData] = useState({} as NewsCardProps);
    const [attachments, setAttachments] = useState([] as Attachment[]);
    const [fileList, setFileList] = useState([] as any[]);

    let {id} = useParams();

    const locations = [...Locations['admin/training'], {
        title: `Edycja: ${data.title}`,
        path: `/admin/training/edit/${id}`
    }]

    useEffect(() => {
        auth.verifyAuth(AdminScopes.TRAINING);

        if (!id) {
            auth.navigate('/training/manage')
            return
        }

        TrainingService.getCategories().then((r) => {
            setCategories(r);
        }).finally(() => {
            TrainingService.getTrainingCourse(id!).then((r) => {
                setData(r);
                setTitle(r.title || '');
                setDescription(r.description || '');
                setShortDescription(r.shortDescription ?? '');
                setCategoryId(r.categoryId ?? '');
                setEditorState(EditorState.createWithContent(HtmlConverter(r.description || '')));
                setAttachments(r.attachments?.map(x => {
                    return {
                        uploadId: x.id,
                        asset: x
                    }
                }) ?? []);
                setFileList(r.attachments?.map(x => {
                    return {
                        uid: x.id,
                        name: x.friendlyName,
                        status: 'done',
                        url: `${BACKEND_BASE}/static/${x.name}`
                    }
                }) ?? []);
            });
        });
    }, [id, auth]);

    const updateTitle = (e: any) => {
        setTitle(e.target.value);
    }

    const updateShortDescription = (e: any) => {
        setShortDescription(e.target.value);
    }

    const updateCategoryId = (e: any) => {
        setCategoryId(e);
    }

    const updateEditorState = (editorState: EditorState) => {
        const description = convertToHTML(FromHtmlConfig)(editorState.getCurrentContent());
        setEditorState(editorState);
        setDescription(description);
    }

    const publish = () => {
        if (!title || !description || !shortDescription) {
            api.error({
                message: `Uzupełnij tytuł, skrót i treść`,
                description: 'Tytuł, skrót i treść nie mogą być puste!',
                placement: 'top'
            });
            return;
        }

        if (title.length > 70) {
            api.error({
                message: `Zbyt długi tytuł`,
                description: 'Tytuł może mieć maksymalnie 70 znaków!',
                placement: 'top'
            });
            return;
        }

        const payload: NewsCardProps = {
            ...data,
            title,
            description,
            shortDescription,
            categoryId: categoryId.length === 0 ? undefined : categoryId,
            attachments: attachments.map(x => x.asset)
        }

        TrainingService.editTraining(payload)
            .then(() => {
                api.info({
                    message: `Zaktualizowano poradnik`,
                    description: 'Poradnik został zaktualizowany!',
                    placement: 'top'
                });
            }).catch((e) => {
            if (e.message === ErrorMessages.notFound)
                api.error({
                    message: `Błąd`,
                    description: 'Wystąpił błąd, nie znaleziono poradnika!',
                    placement: 'top'
                });
            else
                auth.handleLogout();
        });
    }

    return (
        <div className="content">
            <RetoricBreadcrumbs locations={locations}>Edytuj poradnik</RetoricBreadcrumbs>
            {contextHolder}
            <Infobox title="Edytowanie poradników"
                     className="self-stretch"
                     rows={[
                         'W tej sekcji możesz edytować istniejący poradnik dla swoich współpracowników. Opcja edycji jest tylko dostępna dla Administratora Poradników.'
                     ]}
            />
            <div className="grid lg:grid-cols-3 grid-cols-1 gap-4 justify-stretch min-w-full">
                <Input size="large" placeholder="Tytuł" value={title} onChange={updateTitle}
                       defaultValue={data.title}
                       className="bg-offwhite-important rounded-none placeholder:text-gray-500 lg:col-span-2"
                       maxLength={70}/>
                <Select value={categoryId} style={{width: '100%'}} allowClear defaultValue={data.categoryId} size={'large'}
                        onChange={updateCategoryId}>
                    <Select.Option value={''}>Brak kategorii</Select.Option>
                    {categories.map((category) => <Select.Option value={category.id}
                                                                 key={category.id}>{category.name}</Select.Option>)}
                </Select>
            </div>
            <TextArea size="large" placeholder="Skrót" value={shortDescription}
                      onChange={updateShortDescription}
                      defaultValue={data.shortDescription}
                      className="bg-offwhite-important rounded-none placeholder:text-gray-500" rows={3}
                      maxLength={500}/>
            <Editor
                editorState={editorState}
                locale={'pl'}
                toolbar={{
                    image: {
                        uploadCallback: uploadCallback,
                        uploadEnabled: true,
                        previewImage: true,
                        alt: {present: true, mandatory: false},
                        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg,image/svg+xml,image/webp',
                    }
                }}
                wrapperClassName="flex-1 self-stretch border border-gray-200 bg-offwhite"
                editorClassName="px-4 bg-offwhite min-h-[20vh]"
                onEditorStateChange={updateEditorState}
            />
            <AttachmentsDragger attachments={attachments} setAttachments={setAttachments} fileList={fileList}
                                setFileList={setFileList}/>
            <RetoricButton type={'primary'} onClick={publish} className="min-w-[200px]">Zapisz
                poradnik</RetoricButton>
        </div>
    );
}

export default TrainingEdit;
