import React, {useEffect, useState} from 'react';
import './UserHolidays.css';
import {DatePicker, Empty, message, Radio, Select, Space, Table, Tag, Tooltip} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import {
    CarOutlined,
    CheckOutlined,
    CloseOutlined,
    DownloadOutlined,
    QuestionOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/pl';
import 'dayjs/plugin/updateLocale';
import {HolidayDescriptionMap, HolidayStatus} from "../../../../extensions/DateFormatter";
import RetoricBreadcrumbs from "../../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../../extensions/Locations";
import {HolidayUserEntry} from "../../../../types/AuthState";
import ManagerService from "../../../../services/ManagerService";

export const ColorMap: any = {
    [HolidayStatus.Accepted]: 'green',
    [HolidayStatus.Pending]: 'orange',
    [HolidayStatus.Rejected]: 'red',
    [HolidayStatus.Completed]: 'blue',
}

function UserHolidays() {
    const [messageApi, contextHolder] = message.useMessage();
    const [holidayList, setHolidayList] = useState<HolidayUserEntry[]>([]);
    const [selectedStatus, setSelectedStatus] = useState(-1);
    const [upcomingOnly, setUpcomingOnly] = useState(false);


    const updateStatus = (id: string, status: HolidayStatus) => {
        ManagerService
            .updateHolidayStatus(id, status)
            .then(x => {
                messageApi.success('Zaktualizowano status');

                const holidays = [...holidayList];
                const holiday = holidays.find(x => x.id === id);
                if (holiday) holiday.status = status;

                setHolidayList(holidays);
            })
            .catch(e => {
                messageApi.error('Nie udało się zaktualizować statusu');
            });
    }

    const columns: ColumnsType<HolidayUserEntry> = [
        {
            title: "Imię",
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: "Nazwisko",
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: "Email",
            dataIndex: 'email',
            key: 'email',
            render: (text) => <a href={`mailto:${text}`}>{text}</a>
        },
        {
            title: 'Dział',
            dataIndex: 'organisation',
            key: 'organisation',
        },
        {
            title: 'Stanowisko',
            dataIndex: 'position',
            key: 'position',
        },
        {
            title: 'Data rozpoczęcia',
            dataIndex: 'startDate',
            key: 'startDate',
            render: (text) => dayjs(text).format('DD.MM.YYYY'),
        },
        {
            title: 'Data zakończenia',
            dataIndex: 'endDate',
            key: 'endDate',
            render: (text) => dayjs(text).format('DD.MM.YYYY'),
        },
        {
            title: 'Zastępstwo',
            dataIndex: 'substitution',
            key: 'substitution',
            render: (text) => <p>{text ? text : <span className="text-gray-700">Brak</span>}</p>,
        },
        {
            title: 'Długość urlopu (przydział)',
            key: 'duration',
            render: (_, record) => <span>{record.duration} <span
                className="font-light">({record.holidayAllowance})</span></span>
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            render: (text) => <Tag color={ColorMap[text]}>{HolidayDescriptionMap(text)}</Tag>,
        },
        {
            title: 'Akcje',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip title="Urlop odbyty">
                        <CarOutlined onClick={() => updateStatus(record.id, HolidayStatus.Completed)}/>
                    </Tooltip>
                    <Tooltip title="Urlop zaakceptowany">
                        <CheckOutlined onClick={() => updateStatus(record.id, HolidayStatus.Accepted)}/>
                    </Tooltip>
                    <Tooltip title="Urlop odrzucony">
                        <CloseOutlined onClick={() => updateStatus(record.id, HolidayStatus.Rejected)}/>
                    </Tooltip>
                    <Tooltip title="Oczekujący na akceptację">
                        <QuestionOutlined onClick={() => updateStatus(record.id, HolidayStatus.Pending)}/>
                    </Tooltip>
                    <Tooltip title="Pobierz formularz">
                        <DownloadOutlined
                            onClick={() => ManagerService.downloadForm(record.id)}/>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        ManagerService
            .getHolidayRequests(selectedStatus, upcomingOnly)
            .then(x => {
                const entries = [] as HolidayUserEntry[];

                for (const holidayUser of x) {
                    for (const holiday of holidayUser.holidayEntries) {
                        entries.push({
                            id: holiday.id,
                            name: holidayUser.name,
                            email: holidayUser.email,
                            firstName: holidayUser.firstName,
                            lastName: holidayUser.lastName,
                            holidayAllowance: holidayUser.holidayAllowance,
                            startDate: dayjs(holiday.startDate),
                            endDate: dayjs(holiday.endDate),
                            duration: holiday.duration,
                            substitution: holiday.substitution,
                            status: holiday.status,
                            organisation: holiday.organisation,
                            position: holiday.position,
                            forYear: holiday.forYear,
                            city: holiday.city
                        });
                    }
                }

                setHolidayList(entries);
            })
            .catch(e => {
                messageApi.error('Nie udało się pobrać danych');
            });

        return () => {
        }
    }, [selectedStatus, upcomingOnly]);

    return (
        <div className="content">
            <RetoricBreadcrumbs locations={Locations['manager/holidays']}>Lista urlopów</RetoricBreadcrumbs>
            <div className="min-w-full flex lg:flex-row flex-col lg:justify-between justify-stretch px-4 lg:px-0 gap-4">
                <Select size="large"
                        defaultValue={-1}
                        value={selectedStatus}
                        onChange={(v: number) => setSelectedStatus(v)}
                        className="lg:min-w-[250px] min-w-[150px]">
                    <Select.Option value={-1}>Dowolny status</Select.Option>
                    <Select.Option
                        value={HolidayStatus.Pending}>{HolidayDescriptionMap(HolidayStatus.Pending)}</Select.Option>
                    <Select.Option
                        value={HolidayStatus.Accepted}>{HolidayDescriptionMap(HolidayStatus.Accepted)}</Select.Option>
                    <Select.Option
                        value={HolidayStatus.Rejected}>{HolidayDescriptionMap(HolidayStatus.Rejected)}</Select.Option>
                    <Select.Option
                        value={HolidayStatus.Completed}>{HolidayDescriptionMap(HolidayStatus.Completed)}</Select.Option>
                </Select>
                <Radio.Group size={'large'} className="self-center" value={upcomingOnly}
                             onChange={(e) => setUpcomingOnly(e.target.value)}>
                    <Radio.Button value={false}>Wszystkie</Radio.Button>
                    <Radio.Button value={true}>Nadchodzące</Radio.Button>
                </Radio.Group>
            </div>
            <Table columns={columns} dataSource={holidayList} rowKey={'id'}
                   scroll={{x: 768}}
                   className="min-w-full"
                   locale={{emptyText: <Empty description={'Brak danych'}/>}}/>
            {contextHolder}
        </div>
    );
}

export default UserHolidays;
