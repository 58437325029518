export const ErrorMessages = {
    emailEmpty: 'email_empty',
    userNotFound: 'user_not_found',
    userLimitExceeded: 'user_limit_exceeded',
    validationFailed: 'validation_failed',
    categoryExists: 'category_exists_already',
    categoryDoesNotExists: 'category_does_not_exist',
    softwareDoesNotExists: 'software_does_not_exist',
    newsDoesNotExists: 'news_does_not_exist',
    trainingDoesNotExists: 'training_does_not_exist',
    idNotFound: 'id_not_found',
    nameExists: 'name_exists',
    nameTooShort: 'name_too_short',
    nameTooLong: 'name_too_long',
    updateFailed: 'update_failed',
    passwordSame: 'password_same',
    notFound: 'not_found',
    badRequest: 'bad_request',
    internalServerError: 'internal_server_error',
    forbidden: 'forbidden',
    moFile: 'no_file',
    budgetExceeded: 'budget_exceeded',
}
