const Locations: any = {
    'admin': [
        {
            title: "Ustawienia administracyjne",
            path: "/admin"
        }
    ],
    'admin/aiassistant': [
        {
            title: "Ustawienia administracyjne",
            path: "/admin"
        },
        {
            title: "Asystent AI",
            path: "/admin/aiassistant"
        }
    ],
    'admin/aiassistant/config': [
        {
            title: "Ustawienia administracyjne",
            path: "/admin"
        },
        {
            title: "Asystent AI",
            path: "/admin/aiassistant"
        },
        {
            title: "Konfiguracja AI",
            path: "/admin/aiassistant/config"
        }
    ],
    'admin/info': [
        {
            title: "Ustawienia administracyjne",
            path: "/admin"
        },
        {
            title: "Informacje",
            path: "/admin/info"
        }
    ],
    'admin/info/new': [
        {
            title: "Ustawienia administracyjne",
            path: "/admin"
        },
        {
            title: "Informacje",
            path: "/admin/info"
        },
        {
            title: "Dodaj",
            path: "/admin/info/new"
        }
    ],
    'admin/news': [
        {
            title: "Ustawienia administracyjne",
            path: "/admin"
        },
        {
            title: "Aktualności",
            path: "/admin/news"
        }
    ],
    'admin/news/new': [
        {
            title: "Ustawienia administracyjne",
            path: "/admin"
        },
        {
            title: "Aktualności",
            path: "/admin/news"
        },
        {
            title: "Dodaj",
            path: "/admin/news/add"
        }
    ],
    'admin/signalist': [
        {
            title: "Ustawienia administracyjne",
            path: "/admin"
        },
        {
            title: "Zarządzanie Signalist",
            path: "/admin/signalist"
        },
    ],
    'admin/signalist/config': [
        {
            title: "Ustawienia administracyjne",
            path: "/admin"
        },
        {
            title: "Zarządzanie Signalist",
            path: "/admin/signalist"
        },
        {
            title: "Konfiguracja Signalist",
            path: "/admin/signalist/config"
        }
    ],
    'admin/signalist/new': [
        {
            title: "Ustawienia administracyjne",
            path: "/admin"
        },
        {
            title: "Zarządzanie Signalist",
            path: "/admin/signalist"
        },
        {
            title: "Dodaj",
            path: "/admin/signalist/new"
        }
    ],
    'admin/software': [
        {
            title: "Ustawienia administracyjne",
            path: "/admin"
        },
        {
            title: "Oprogramowanie",
            path: "/admin/software"
        }
    ],
    'admin/software/new': [
        {
            title: "Ustawienia administracyjne",
            path: "/admin"
        },
        {
            title: "Oprogramowanie",
            path: "/admin/software"
        },
        {
            title: "Dodaj",
            path: "/admin/software/new"
        }
    ],
    'admin/training': [
        {
            title: "Ustawienia administracyjne",
            path: "/admin"
        },
        {
            title: "Poradniki",
            path: "/admin/training"
        }
    ],
    'admin/training/new': [
        {
            title: "Ustawienia administracyjne",
            path: "/admin"
        },
        {
            title: "Poradniki",
            path: "/admin/training"
        },
        {
            title: "Dodaj",
            path: "/admin/training/new"
        }
    ],
    'admin/users': [
        {
            title: "Ustawienia administracyjne",
            path: "/admin"
        },
        {
            title: "Użytkownicy",
            path: "/admin/users"
        }
    ],
    'admin/users/new': [
        {
            title: "Ustawienia administracyjne",
            path: "/admin"
        },
        {
            title: "Użytkownicy",
            path: "/admin/users"
        },
        {
            title: "Dodaj",
            path: "/admin/users/new"
        }
    ],
    'aiassistant': [
        {
            title: "Asystent AI",
            path: "/aiassistant"
        }
    ],
    'aiassistant/settings': [
        {
            title: "Asystent AI",
            path: "/aiassistant"
        },
        {
            title: "Ustawienia",
            path: "/aiassistant/settings"
        }
    ],
    'calendar': [
        {
            title: "Kalendarz",
            path: "/calendar"
        }
    ],
    'delegations': [
        {
            title: "Moduły",
            path: "/modules"
        },
        {
            title: "Delegacje",
            path: "/modules/delegations"
        }
    ],
    'holiday': [
        {
            title: "Moduły",
            path: "/modules"
        },
        {
            title: "Urlop",
            path: "/modules/holiday"
        }
    ],
    'info': [
        {
            title: "Informacje",
            path: "/info"
        }
    ],
    'manager': [
        {
            title: "Panel menadżera",
            path: "/admin"
        }
    ],
    'manager/holidays': [
        {
            title: "Panel menadżera",
            path: "/admin"
        },
        {
            title: "Lista urlopów",
            path: "/admin/holidays"
        }
    ],
    'modules': [
        {
            title: "Moduły",
            path: "/modules"
        }
    ],
    'news': [
        {
            title: "Aktualności",
            path: "/"
        }
    ],
    'news/new': [
        {
            title: "Aktualności",
            path: "/"
        },
        {
            title: "Dodaj",
            path: "/news/new"
        }
    ],
    'settings': [
        {
            title: "Ustawienia",
            path: "/settings"
        }
    ],
    'software': [
        {
            title: "Moduły",
            path: "/modules"
        },
        {
            title: "Oprogramowanie",
            path: "/modules/software"
        }
    ],
    'training': [
        {
            title: "Poradniki",
            path: "/training"
        }
    ],
    'training/new': [
        {
            title: "Poradniki",
            path: "/training"
        },
        {
            title: "Dodaj",
            path: "/training/new"
        }
    ],
}

export default Locations;
