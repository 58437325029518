import React, {useEffect, useState} from 'react';
import './InfopageList.css';
import {Empty, Input, notification, Select, Space, Table, Tooltip} from "antd";
import {ColumnsType} from "antd/es/table";
import {useAuth} from "../../../../extensions/Auth";
import {AdminScopes, GUID_EMPTY} from "../../../../types/Scopes";
import RetoricButton from "../../../../designsystems/RetoricButton/RetoricButton";
import RetoricCard from "../../../../designsystems/RetoricCard/RetoricCard";
import RetoricBreadcrumbs from "../../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../../extensions/Locations";
import {InfopageService} from "../../../../services/InfopageService";
import {Infopage, InfopageCategory, InfopageType} from "../../../../types/InfopageModels";
import {FormatDateSimple} from "../../../../extensions/DateFormatter";
import {CloseOutlined, DeleteOutlined, EditOutlined, FormOutlined, SaveOutlined} from "@ant-design/icons";
import ConfirmationModal, {ConfirmationModalProps} from "../../../../designsystems/ConfirmationModal/ConfirmationModal";
import {ErrorMessages} from "../../../../extensions/ErrorMessages";
import InfopageDrawer from "../InfopageDrawer/InfopageDrawer";
import InfoCategoriesModal from "../InfoCategoriesModal/InfoCategoriesModal";

export const InfopageTypeMapper = (type: InfopageType) => {
    switch (type) {
        case InfopageType.Basic:
            return 'Strona tekstowa';
        case InfopageType.Changelog:
            return 'Rejester zmian';
        case InfopageType.Training:
            return 'Wykaz szkoleń';
        case InfopageType.PhoneExtensions:
            return 'Zbiór kontaktów';
        default:
            return 'Nieznany';
    }
}

function InfopageList() {
    const [infopageList, setInfopageList] = useState([] as Infopage[]);
    const [categories, setCategories] = useState([] as InfopageCategory[]);
    const [api, contextHolder] = notification.useNotification();
    const [infopageDeleteModal, setInfopageDeleteModal] = useState(false);
    const [modelPayload, setModelPayload] = useState({} as ConfirmationModalProps);
    const [editRow, setEditRow] = useState({} as Infopage);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedInfopage, setSelectedInfopage] = useState({} as Infopage);
    const [modalOpen, setModalOpen] = useState(false);
    const auth = useAuth();

    const getCategoryName = (id: string) => {
        const category = categories.find(x => x.id === id);
        return category?.title ?? 'Brak kategorii';
    }

    const openDrawer = (record: Infopage) => {
        setDrawerOpen(true);
        setSelectedInfopage(record);
    }

    const closeDrawer = () => {
        setDrawerOpen(false)
        setSelectedInfopage({} as Infopage)
    }

    const deleteInfopage = (id: string) => {
        InfopageService.deleteInfopage(id)
            .then((x) => {
                const page = infopageList.find(x => x.id === id);
                const newList = infopageList.filter(x => x.id !== id);
                setInfopageList(newList);

                api.info({
                    message: `Strona informacyjna została usunięta`,
                    description: `Strona informacyjna ${page?.title} została usunięta z systemu`,
                    placement: 'top'
                });
            })
            .catch(x => {
                let message = "Brak uprawnień do usunięcia strony informacyjnej!";

                if (x.message === ErrorMessages.notFound) {
                    message = "Strona informacyjna nie istnieje!"
                }

                api.error({
                    message: `Błąd`,
                    description: message,
                    placement: 'top'
                });
                return;
            });
    }

    const saveInfopage = () => {
        InfopageService.updateInfopage(editRow)
            .then((x) => {

                const page = infopageList.find(x => x.id === editRow.id);
                const newList = infopageList.map(x => x.id === editRow.id ? editRow : x);
                setInfopageList(newList);
                setEditRow({} as Infopage);

                api.info({
                    message: `Strona informacyjna została zaktualizowana`,
                    description: `Strona informacyjna ${page?.title} została zaktualizowana`,
                    placement: 'top'
                });
            })
            .catch(x => {
                let message = "Brak uprawnień do usunięcia strony informacyjnej!";

                if (x.message === ErrorMessages.notFound) {
                    message = "Strona informacyjna nie istnieje!"
                } else if (x.message === ErrorMessages.validationFailed) {
                    message = "Formularz zawiera błędy!";
                } else if (x.message === ErrorMessages.badRequest) {
                    message = "Wystąpił nieznany błąd!"
                }

                api.error({
                    message: `Błąd`,
                    description: message,
                    placement: 'top'
                });
            })
    }

    const columns: ColumnsType<Infopage> = [
        {
            title: 'Tytuł',
            dataIndex: 'title',
            key: 'title',
            render: (value, record) =>
                <>
                    {editRow.id === record.id ?
                        <Input value={editRow.title} onChange={(e) => setEditRow({...editRow, title: e.target.value})}
                               className="rounded-none"/> :
                        <span className="cursor-pointer block" onClick={() => openDrawer(record)}>{value}</span>}
                </>
        },
        {
            title: 'Widoczność strony',
            dataIndex: 'isHidden',
            key: 'isHidden',
            render: (value, record) => <>
                {editRow.id === record.id ?
                    <Select value={editRow.isHidden} onChange={(e) => setEditRow({...editRow, isHidden: e})}
                            className="rounded-none w-full">
                        <Select.Option value={false}>Widoczna</Select.Option>
                        <Select.Option value={true}>Ukryta</Select.Option>
                    </Select> :
                    <span className="cursor-pointer block"
                          onClick={() => openDrawer(record)}>{value ? 'Ukryta' : 'Widoczna'}</span>}
            </>
        },
        {
            title: 'Kategoria',
            dataIndex: 'categoryId',
            key: 'categoryId',
            responsive: ['md'],
            render: (value, record) => <>
                {editRow.id === record.id ?
                    <Select value={editRow.categoryId} onChange={(e) => setEditRow({...editRow, categoryId: e})}
                            className="rounded-none w-full">
                        <Select.Option value={GUID_EMPTY}>Brak kategorii</Select.Option>
                        {categories.map(x => <Select.Option value={x.id} key={x.id}>{x.title}</Select.Option>)}
                    </Select> :
                    <span className="cursor-pointer block"
                          onClick={() => openDrawer(record)}>{getCategoryName(value)}</span>}
            </>
        },
        {
            title: 'Typ',
            dataIndex: 'type',
            key: 'type',
            render: (value, record) => <span className="cursor-pointer block"
                                             onClick={() => openDrawer(record)}>{InfopageTypeMapper(value)}</span>
        },
        {
            title: 'Utworzono',
            dataIndex: 'createdAt',
            key: 'createdAt',
            responsive: ['md'],
            render: (value, record) => <span className="cursor-pointer block"
                                             onClick={() => openDrawer(record)}>{FormatDateSimple(value)}</span>
        },
        {
            title: 'Ostatnio zmodyfikowano',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            responsive: ['md'],
            render: (value, record) => <span className="cursor-pointer block"
                                             onClick={() => openDrawer(record)}>{FormatDateSimple(value)}</span>
        },
        {
            title: 'Akcje',
            key: 'action',
            responsive: ['lg'],
            render: (_, record) => (
                <Space size="middle">
                    {editRow.id === record.id &&
                        <Tooltip title="Zapisz zmiany">
                            <SaveOutlined onClick={() => saveInfopage()}/>
                        </Tooltip>}
                    {editRow.id === record.id &&
                        <Tooltip title="Anuluj">
                            <CloseOutlined onClick={() => setEditRow({} as Infopage)}/>
                        </Tooltip>}
                    {editRow.id !== record.id && <Tooltip title="Szybka edycja">
                        <EditOutlined onClick={() => setEditRow(record)}/>
                    </Tooltip>}
                    {editRow.id !== record.id && <Tooltip title="Pełna edycja">
                        <FormOutlined onClick={() => auth.navigate(`/admin/info/edit/${record.id}`)}/>
                    </Tooltip>}
                    {editRow.id !== record.id && <Tooltip title="Usuń stronę">
                        <DeleteOutlined onClick={() => {
                            const payload = {
                                title: 'Usuń stronę informacyjną',
                                open: true,
                                setOpen: setInfopageDeleteModal,
                                yesAction: () => {
                                    deleteInfopage(record.id)
                                    setInfopageDeleteModal(false);
                                },
                                noAction: () => setInfopageDeleteModal(false),
                            } as ConfirmationModalProps;

                            payload.infoboxTitle = "Wybrana strona zostanie usunięta"
                            payload.children = <>
                                <p className="text-slate-600">Usunięty strona nie może zostać przywrócona, czy jesteś
                                    pewien, że chcesz usunąć <i>"{record.title}"</i>?</p>
                            </>

                            setModelPayload(payload);
                            setInfopageDeleteModal(true);
                        }}/>
                    </Tooltip>}
                </Space>
            ),
        }
    ];

    useEffect(() => {
        auth.verifyAuth(AdminScopes.INFOPAGE);

        InfopageService.getInfopageCategories().then(x => {
            setCategories(x)
        }).finally(() => {
            InfopageService.getAllInfopages().then(x => {
                setInfopageList(x);
            });
        });

        return () => {
        };
    }, [auth]);

    return (
        <div className="content">
            <RetoricBreadcrumbs locations={Locations['admin/info']}>Zarządzanie stronami info</RetoricBreadcrumbs>
            <RetoricCard className={'flex flex-col w-full bg-offwhite'}>
                {contextHolder}
                <div className="flex lg:flex-row flex-col justify-between">
                    <Space className="flex-1 mb-4 lg:justify-start justify-center">
                        <RetoricButton type={'primary'} outline onClick={() => setModalOpen(true)}
                                       className="mr-4 min-w-[150px]">Zarządzaj kategoriami</RetoricButton>
                        <RetoricButton outline onClick={() => auth.navigate('/admin/info/new')}
                                       className="lg:hidden min-w-[150px]">Dodaj</RetoricButton>
                    </Space>
                    <div className={'infopages__new'}></div>
                </div>
                <Table columns={columns} dataSource={infopageList} rowKey={'id'}
                       locale={{emptyText: <Empty description={'Brak danych'}/>}}/>
            </RetoricCard>
            <InfopageDrawer closeDrawer={closeDrawer} drawerOpen={drawerOpen}
                            selectedInfopage={selectedInfopage}
                            categories={categories}/>
            <ConfirmationModal {...modelPayload} open={infopageDeleteModal} setOpen={setInfopageDeleteModal}/>
            <InfoCategoriesModal open={modalOpen} setOpen={setModalOpen} refresh={() => null}/>
        </div>
    );
}

export default InfopageList;
