import {AutoComplete, Input} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import React from "react";

export interface SearchbarProps {
    searchValue: string;
    setSearchValue: (value: string) => void;
    handleSearch: (value: string) => void;
    icon?: any;
    logo?: any;
    title: string;
}

export default function Searchbar({searchValue, setSearchValue, handleSearch, icon, logo, title}: SearchbarProps) {
    return (
        <div className={'searchbar'}>
            <img src={logo} alt={'Logo wyszukiwarki Google'}/>
            <AutoComplete
                className="h-fit"
                popupClassName="site-search"
                popupMatchSelectWidth={500}
                value={searchValue}
                onChange={setSearchValue}
                notFoundContent={searchValue.length > 0 && <span className={'google-search'}
                                                                 onClick={() => handleSearch(searchValue)}>{icon} <span
                    className="inline">Wyszukaj "{searchValue}" w {title}...</span></span>}
                onKeyUp={(e) => e.key === 'Enter' && handleSearch(searchValue)}
                onSelect={handleSearch}
            >
                <Input className={'content-searchbar'} size="large"
                       placeholder="Wpisz szukaną frazę..."
                       suffix={<SearchOutlined
                           onClick={() => searchValue.length > 0 && handleSearch(searchValue)}
                           style={{color: '#555'}}/>}/>
            </AutoComplete>
        </div>
    )
}
