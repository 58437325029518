import React, {useEffect} from 'react';
import {Descriptions, Drawer, Space} from "antd";
import {ScopeMapper} from "../UsersList/UsersList";
import {User} from "../../../../types/AuthState";
import {useAuth} from "../../../../extensions/Auth";
import RetoricButton from "../../../../designsystems/RetoricButton/RetoricButton";

export interface UserDrawerProps {
    selectedUser: User;
    drawerOpen: boolean;
    closeDrawer: () => void;
    users: User[];
}

function UserDrawer({selectedUser, drawerOpen, closeDrawer, users}: UserDrawerProps) {
    const auth = useAuth();
    const [manager, setManager] = React.useState<User>();

    useEffect(() => {
        setManager(users.find(x => x.id === selectedUser.managerId));
    }, [users, selectedUser]);

    return (
        <Drawer title="Informacje o użytkowniku" placement="right" onClose={closeDrawer} open={drawerOpen}
                extra={
                    <Space>
                        <RetoricButton onClick={() => auth.navigate(`/admin/users/edit/${selectedUser.email}`)}>
                            Edytuj
                        </RetoricButton>
                    </Space>
                }>
            <Descriptions title={selectedUser.name} bordered>
                <Descriptions.Item label="Imię" span={3}>{selectedUser.firstName}</Descriptions.Item>
                <Descriptions.Item label="Nazwisko" span={3}>{selectedUser.lastName}</Descriptions.Item>
                <Descriptions.Item label="Email" span={3}>{selectedUser.email}</Descriptions.Item>
                <Descriptions.Item label="Dział" span={3}>{selectedUser.organisation}</Descriptions.Item>
                <Descriptions.Item label="Stanowisko" span={3}>{selectedUser.position}</Descriptions.Item>
                <Descriptions.Item label="Przydział urlopu" span={3}>{selectedUser.holidayAllowance}</Descriptions.Item>
                <Descriptions.Item label="Dostęp do AI?" span={3}>{selectedUser.isAiEnabled ? 'Tak' : 'Nie'}</Descriptions.Item>
                <Descriptions.Item label="Bezpośredni przełożony"
                                   span={3}>{manager ? <>{manager?.firstName} {manager?.lastName} ({manager?.email})</> :
                    <span>Brak manadżera</span>}</Descriptions.Item>
                <Descriptions.Item label="Uprawnienia" span={3}>
                    {selectedUser?.scopes?.length! > 0 && <ul>{selectedUser.scopes?.map((x, key) => <li
                        key={`permission-${selectedUser.name}-${x}`}>{ScopeMapper(x)}</li>)}</ul>}
                    {selectedUser?.scopes?.length! === 0 && <span>Brak uprawnień</span>}
                </Descriptions.Item>
            </Descriptions>
        </Drawer>
    );
}

export default UserDrawer;
