import React, {useEffect, useState} from 'react';
import './TrainingAdd.css';
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {EditorState} from "draft-js";
import {Input, notification, Select} from "antd";
import {convertToHTML} from 'draft-convert';
import {TrainingService} from "../../../../services/TrainingService";
import TextArea from "antd/es/input/TextArea";
import Infobox from "../../../../designsystems/Infobox/Infobox";
import RetoricButton from "../../../../designsystems/RetoricButton/RetoricButton";
import RetoricBreadcrumbs from "../../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../../extensions/Locations";
import {EntryCategory} from "../../../AdminView/Training/TrainingCategoriesModal/TrainingCategoriesModal";
import {uploadCallback} from "../../News/NewsAdd/NewsAdd";
import {FromHtmlConfig} from "../../../AdminView/News/NewsEdit/NewsEdit";
import AttachmentsDragger from "../../../../components/AttachmentsDragger/AttachmentsDragger";
import {Attachment} from "../../../../types/StaticAsset";
import {NewsService} from "../../../../services/NewsService";

function TrainingAdd() {
    const [isAdminView, setIsAdminView] = useState(false);

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    const [api, contextHolder] = notification.useNotification();
    const [title, setTitle] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [description, setDescription] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [categories, setCategories] = useState([] as EntryCategory[]);
    const [attachments, setAttachments] = useState([] as Attachment[]);
    const [fileList, setFileList] = useState([] as any[]);

    const updateTitle = (e: any) => {
        setTitle(e.target.value);
    }

    const updateShortDescription = (e: any) => {
        setShortDescription(e.target.value);
    }

    const updateCategoryId = (e: any) => {
        setCategoryId(e);
    }

    const updateEditorState = (editorState: EditorState) => {
        const description = convertToHTML(FromHtmlConfig)(editorState.getCurrentContent());
        setEditorState(editorState);
        setDescription(description);
    }

    const publish = () => {
        if (!title || !description || !shortDescription) {
            api.error({
                message: `Uzupełnij tytuł, skrót i treść`,
                description: 'Tytuł, skrót i treść nie mogą być puste!',
                placement: 'top'
            });
            return;
        }

        if (title.length > 70) {
            api.error({
                message: `Zbyt długi tytuł`,
                description: 'Tytuł może mieć maksymalnie 70 znaków!',
                placement: 'top'
            });
            return;
        }

        const data = {
            title,
            description,
            shortDescription,
            categoryId: categoryId.length === 0 ? null : categoryId,
            attachments: attachments.map(x => x.asset)
        }

        TrainingService.addTraining(data)
            .then(() => {
                api.info({
                    message: `Dodano poradnik`,
                    description: 'Poradnik został dodany!',
                    placement: 'top'
                });
                setTitle('');
                setDescription('');
                setShortDescription('');
                setCategoryId('');
                setEditorState(EditorState.createEmpty());
                setAttachments([]);
                setFileList([]);
            }).catch((e) => {
            api.error({
                message: `Błąd`,
                description: 'Wystąpił błąd podczas dodawania poradnika!',
                placement: 'top'
            });
        });
    }

    useEffect(() => {
        const path = window.location.pathname;

        if (path.includes('admin'))
            setIsAdminView(true);

        return () => {
        };
    }, []);

    useEffect(() => {
        TrainingService
            .getCategories()
            .then((r) => {
                setCategories(r);
            });

        return () => {
        };
    }, []);

    return (
        <div className="content">
            <RetoricBreadcrumbs locations={isAdminView ? Locations['admin/training/new'] : Locations['training/new']}>Dodaj
                poradnik</RetoricBreadcrumbs>
            {contextHolder}
            <Infobox title="Dodawanie poradników"
                     className="self-stretch"
                     rows={[
                         'W tej sekcji możesz dodać poradnik dla swoich współpracowników. Po dodaniu, poradnik musi zostać zaakceptowany przez jednego z administratorów zanim pojawi się w sekcji poradników.'
                     ]}
            />
            <div className="grid lg:grid-cols-3 grid-cols-1 gap-4 justify-stretch min-w-full">
                <Input size="large" placeholder="Tytuł" value={title} onChange={updateTitle}
                       className="bg-offwhite-important rounded-none placeholder:text-gray-500 lg:col-span-2"
                       maxLength={70}/>
                <Select value={categoryId} style={{width: '100%'}} allowClear size={'large'}
                        onChange={updateCategoryId}>
                    <Select.Option value={''}>Brak kategorii</Select.Option>
                    {categories.map((category) => <Select.Option value={category.id}
                                                                 key={category.id}>{category.name}</Select.Option>)}
                </Select>
            </div>
            <TextArea size="large" placeholder="Skrót" value={shortDescription}
                      onChange={updateShortDescription}
                      className="bg-offwhite-important rounded-none placeholder:text-gray-500" rows={3}
                      maxLength={500}/>
            <Editor
                editorState={editorState}
                locale={'pl'}
                toolbar={{
                    image: {
                        uploadCallback: uploadCallback,
                        uploadEnabled: true,
                        previewImage: true,
                        alt: {present: true, mandatory: false},
                        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg,image/svg+xml,image/webp',
                    }
                }}
                wrapperClassName="flex-1 self-stretch border border-gray-200 bg-offwhite"
                editorClassName="px-4 bg-offwhite min-h-[20vh]"
                onEditorStateChange={updateEditorState}
            />
            <AttachmentsDragger attachments={attachments} setAttachments={setAttachments} fileList={fileList}
                                setFileList={setFileList}/>
            <RetoricButton type={'primary'} onClick={publish} className="min-w-[200px]">Dodaj
                poradnik</RetoricButton>
        </div>
    );
}

export default TrainingAdd;
