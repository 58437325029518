import React, {useEffect, useState} from 'react';
import './InfoEdit.css';
import {useAuth} from "../../../../extensions/Auth";
import {AdminScopes} from "../../../../types/Scopes";
import {InfopageEditProps} from "./InfoEdit";
import {DatePicker, Form, Input, notification, Popconfirm, Space, Table, Tooltip} from "antd";
import {InfopageChangelog, InfopageChangelogEntry} from "../../../../types/InfopageModels";
import RetoricButton from "../../../../designsystems/RetoricButton/RetoricButton";
import {FormatDateSimple} from "../../../../extensions/DateFormatter";
import {ColumnsType} from "antd/es/table";
import {CloseOutlined, DeleteOutlined, EditOutlined, SaveOutlined} from "@ant-design/icons";
import {InfopageService} from "../../../../services/InfopageService";
import {ErrorMessages} from "../../../../extensions/ErrorMessages";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import RetoricCard from "../../../../designsystems/RetoricCard/RetoricCard";
import pl from "antd/es/date-picker/locale/pl_PL";

function ChangelogInfopageEdit({infopage}: InfopageEditProps) {
    const auth = useAuth();
    const [api, contextHolder] = notification.useNotification();
    const [editRow, setEditRow] = useState({} as InfopageChangelogEntry);
    const [form] = Form.useForm();
    const [entries, setEntries] = useState([] as InfopageChangelogEntry[]);

    const refreshData = () => {
        InfopageService.getInfopageById(infopage.id).then(x => {
            const p = x as InfopageChangelog;

            setEntries(p.entries || [])
        })
    }

    useEffect(() => {
        auth.verifyAuth(AdminScopes.INFOPAGE);
        const ip = infopage as InfopageChangelog;

        setEntries(ip.entries || [])

        return () => {
        };
    }, [auth, infopage]);

    const deleteRow = (id: string) => {
        InfopageService.deleteChangelogEntry(infopage.id, id)
            .then((x) => {
                api.info({
                    message: `Usunięto wpis`,
                    description: 'Wpis w rejestrze zmian został usunięty!',
                    placement: 'top'
                });

                const p = infopage as InfopageChangelog;
                p.entries = p.entries.filter(x => x.id !== id);

                setEntries(p.entries || [])
            })
            .catch((e) => {
                let error = "Twoje uprawnienia nie pozwalają na usunięcie wpisu!"

                if (e.message === ErrorMessages.notFound) {
                    error = 'Nie znaleziono wpisu w rejestrze!'
                }

                api.error({
                    message: `Wystąpił błąd`,
                    description: error,
                    placement: 'top'
                });
            });
    }

    const updateRow = (id: string) => {
        InfopageService.updateChangelogEntry(infopage.id, id, editRow)
            .then(() => {
                api.info({
                    message: `Zaktualizowano wpis`,
                    description: 'Wpis w rejestrze został zaktualizowany!',
                    placement: 'top'
                });

                const p = {...infopage as InfopageChangelog};
                const entryIndex = p.entries.findIndex(x => x.id === id);

                if (entryIndex !== -1)
                    p.entries[entryIndex] = editRow;

                p.entries.sort((a, b) => {
                    const dateA = new Date(a.date);
                    const dateB = new Date(b.date);

                    return dateB.getTime() - dateA.getTime()
                });

                setEntries(p.entries || [])
                setEditRow({} as InfopageChangelogEntry);
            })
            .catch((e) => {
                let error = "Twoje uprawnienia nie pozwalają na aktualizowanie wpisów!"

                if (e.message === ErrorMessages.notFound) {
                    error = 'Nie znaleziono wpisu w rejestrze zmian!'
                }

                api.error({
                    message: `Wystąpił błąd`,
                    description: error,
                    placement: 'top'
                });
            });
    }

    const addRow = () => {
        const formValues = form.getFieldsValue();

        if (!formValues.title || !formValues.description || !formValues.date) {
            api.error({
                message: `Uzupełnij wymagane pola`,
                description: 'Wszystkie pola są wymagane!',
                placement: 'top'
            });
            return;
        }

        const payload: InfopageChangelogEntry = {
            id: '',
            title: formValues.title,
            description: formValues.description,
            date: editRow.date
        }

        InfopageService.addChangelogEntry(infopage.id, payload)
            .then(() => {
                api.info({
                    message: `Dodano wpis`,
                    description: 'Wpis do rejestru zmian został dodany!',
                    placement: 'top'
                });

                refreshData();
                form.resetFields();
            })
            .catch((e) => {
                let error = "Twoje uprawnienia nie pozwalają na dodawanie wpisów!"

                if (e.message === ErrorMessages.notFound) {
                    error = 'Nie znaleziono strony!'
                }

                if (e.message === ErrorMessages.validationFailed) {
                    error = 'Formularz zawiera błędy!'
                }

                api.error({
                    message: `Wystąpił błąd`,
                    description: error,
                    placement: 'top'
                });
            });
    }

    const columns: ColumnsType<InfopageChangelogEntry> = [
        {
            title: 'Temat',
            dataIndex: 'title',
            key: 'title',
            render: (value, record) =>
                <>
                    {editRow.id === record.id ?
                        <Input value={editRow.title} onChange={(e) => setEditRow({...editRow, title: e.target.value})}
                               className="rounded-none bg-offwhite"/> :
                        <span className="block">{value}</span>}
                </>
        },
        {
            title: 'Data',
            dataIndex: 'date',
            key: 'date',
            render: (value, record) =>
                <>
                    {editRow.id === record.id ?
                        <DatePicker value={dayjs(editRow.date)}
                                    className="rounded-none bg-offwhite"
                                    locale={pl}
                                    onChange={(e) => setEditRow({...editRow, date: e?.toDate() ?? new Date()})}/> :
                        <span className="block">{FormatDateSimple(value)}</span>}
                </>
        },
        {
            title: 'Zmiany',
            dataIndex: 'description',
            key: 'description',
            width: '70%',
            render: (text: string, record: InfopageChangelogEntry) => (
                <>
                    {editRow.id === record.id ?
                        <TextArea value={editRow.description}
                                  rows={text.split('\n').length}
                                  onChange={(e) => setEditRow({...editRow, description: e.target.value})}
                                  className="rounded-none bg-offwhite"/>
                        :
                        <ul>
                            {text?.split('\n')?.map((change: string) => (
                                <li key={change}>{change}</li>
                            ))}
                        </ul>}
                </>
            ),
        },
        {
            title: 'Akcje',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, record) => (
                <Space size="middle">
                    {editRow.id === record.id &&
                        <Tooltip title="Zapisz zmiany">
                            <SaveOutlined onClick={() => updateRow(record.id)}/>
                        </Tooltip>}
                    {editRow.id === record.id &&
                        <Tooltip title="Anuluj">
                            <CloseOutlined onClick={() => setEditRow({} as InfopageChangelogEntry)}/>
                        </Tooltip>}
                    {editRow.id !== record.id &&
                        <Tooltip title="Edytuj">
                            <EditOutlined onClick={() => setEditRow(record)}/>
                        </Tooltip>}

                    {editRow.id !== record.id &&
                        <Popconfirm
                            placement="top"
                            title={`Czy jesteś pewien?`}
                            description={`Czy na pewno chcesz usunąć wpis ${record.title}?`}
                            onConfirm={() => deleteRow(record.id)}
                            okText="Tak"
                            okButtonProps={{className: "bg-brandColor-950 hover:bg-brandColor-800"}}
                            cancelText="Nie"
                        >
                            <Tooltip title="Usuń">
                                <DeleteOutlined/>
                            </Tooltip>
                        </Popconfirm>}
                </Space>
            ),
        },
    ];

    return (
        <div className="min-h-full flex flex-col justify-stretch items-stretch gap-4">
            {contextHolder}
            <RetoricCard className="min-h-full min-w-full flex flex-col justify-stretch items-center self-stretch">
                <Table dataSource={[...entries]} columns={columns}
                       pagination={{position: ['bottomCenter'], pageSize: 5}}
                       rowKey={'id'}
                       className="flex-1 self-stretch"
                       locale={{emptyText: "Brak danych"}}
                />
            </RetoricCard>
            <RetoricCard className="bg-offwhite">
                <h4 className="lg:text-xl text-lg text-slate-600 text-center lg:text-left tracking-wide self-stretch mb-4">
                    Dodaj nowy wpis do rejestru</h4>
                <Form
                    layout={'vertical'}
                    form={form}
                    className={"min-w-full grid grid-cols-1 lg:grid-cols-3 gap-4"}
                >
                    <Form.Item label="Tytuł" name="title"
                               rules={[{required: true, message: "Tytuł jest wymagany"}]}>
                        <Input size="large" placeholder="Tytuł zmiany"
                               className="bg-offwhite rounded-none"/>
                    </Form.Item>
                    <Form.Item label="Data i godzina" name="date"
                               rules={[{required: true, message: "Data jest wymagana"}]}>
                        <DatePicker value={dayjs(editRow.date)}
                                    placeholder={'Wybierz datę'}
                                    locale={pl}
                                    className="bg-offwhite rounded-none w-full"
                                    size={'large'}
                                    onChange={(e) => setEditRow({...editRow, date: e?.toDate() ?? new Date()})}/>
                    </Form.Item>
                    <Form.Item label="Opis" name="description"
                               rules={[{required: true, message: "Opis jest wymagany"}]}>
                        <TextArea size="large" placeholder="Opis zmiany" rows={1}
                                  className="bg-offwhite rounded-none"/>
                    </Form.Item>
                </Form>
                <RetoricButton type={'primary'} onClick={addRow} className="min-w-[200px]">Dodaj
                    wpis</RetoricButton>
            </RetoricCard>
        </div>
    );
}

export default ChangelogInfopageEdit;
