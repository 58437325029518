import {Menu, MenuProps} from "antd";
import React, {useEffect, useState} from "react";
import {
    CodeOutlined,
    ContainerOutlined,
    InfoCircleOutlined,
    IssuesCloseOutlined,
    LeftCircleOutlined,
    MenuOutlined,
    RightCircleOutlined,
    RobotOutlined,
    SecurityScanOutlined,
    SettingOutlined,
    UserOutlined
} from '@ant-design/icons';
import {useAuth} from "../../extensions/Auth";
import './NavAdminMenu.css'
import {getWindowDimensions} from "../../screens/UserView/Info/InfoDynamic/InfoDynamic";
import {AdminScopes} from "../../types/Scopes";

export default function NavAdminMenu() {
    const auth = useAuth();
    const [menuItems, setMenuItems] = useState([] as MenuProps['items']);
    const [openKeys, setOpenKeys] = useState([] as string[]);
    const [current, setCurrent] = useState('invalid');
    const [isMobile, setIsMobile] = React.useState(getWindowDimensions().width < 1024);
    const [collapsed, setCollapsed] = useState(localStorage.getItem('admin-collapsed') === 'true');

    const items: MenuProps['items'] = [];

    const onClick: MenuProps['onClick'] = e => {
        if (e.key === 'collapse')
            updateCollapsed(!collapsed);
        else {
            auth.navigate(`/admin/${e.key}`);
        }
    };

    const updateCollapsed = (newState: boolean) => {
        setCollapsed(newState);
        localStorage.setItem('admin-collapsed', newState.toString());
    }

    useEffect(() => {
        const path = window.location.pathname.split('/');

        if (path[1] === 'admin' && path.length > 2) {
            if (path.length === 4)
                setCurrent(path.slice(2).join('/'));
            else
                setCurrent(path[2]);
        }

        if (auth.user?.scopes?.includes(AdminScopes.SYSTEM)) {
            items.push({
                label: 'Ustawienia systemu',
                key: '',
                icon: <SettingOutlined/>,
            })
        }

        if (auth.user?.scopes?.includes(AdminScopes.USERS)) {
            items.push({
                label: 'Użytkownicy',
                key: 'users-group',
                icon: <UserOutlined/>,
                popupOffset: [-220, 45],
                children: [
                    {
                        label: 'Lista użytkowników',
                        key: 'users',
                    },
                    {
                        label: 'Dodaj',
                        key: 'users/new'
                    }
                ]
            })
        }

        if (auth.user?.scopes?.includes(AdminScopes.NEWS)) {
            items.push({
                label: 'Aktualności',
                key: 'news-group',
                icon: <ContainerOutlined/>,
                popupOffset: [-220, 45],
                children: [
                    {
                        label: 'Lista aktualności',
                        key: 'news',
                    },
                    {
                        label: 'Dodaj',
                        key: 'news/new'
                    }
                ]
            })
        }

        if (auth.user?.scopes?.includes(AdminScopes.SOFTWARE)) {
            items.push({
                label: 'Oprogramowanie',
                key: 'software-group',
                icon: <CodeOutlined/>,
                popupOffset: [-220, 45],
                children: [
                    {
                        label: 'Lista oprogramowania',
                        key: 'software',
                    },
                    {
                        label: 'Dodaj',
                        key: 'software/new'
                    }
                ]
            })
        }

        if (auth.user?.scopes?.includes(AdminScopes.TRAINING)) {
            items.push({
                label: 'Poradniki',
                key: 'training-group',
                icon: <SecurityScanOutlined/>,
                popupOffset: [-220, 45],
                children: [
                    {
                        label: 'Lista poradników',
                        key: 'training',
                    },
                    {
                        label: 'Dodaj',
                        key: 'training/new'
                    }
                ]
            })
        }

        if (auth.user?.scopes?.includes(AdminScopes.INFOPAGE)) {
            items.push({
                label: 'Infostrony',
                key: 'info-group',
                icon: <InfoCircleOutlined/>,
                popupOffset: [-220, 45],
                children: [
                    {
                        label: 'Lista stron',
                        key: 'info',
                    },
                    {
                        label: 'Dodaj',
                        key: 'info/new'
                    }
                ]
            })
        }

        if (auth.user?.scopes?.includes(AdminScopes.SYSTEM) && auth.user?.scopes?.includes(AdminScopes.USERS)) {
            items.push({
                label: 'Asystent AI',
                key: 'aiassistant-group',
                icon: <RobotOutlined/>,
                popupOffset: [-220, 45],
                children: [
                    {
                        label: 'Raport AI',
                        key: 'aiassistant',
                    },
                    {
                        label: 'Konfiguracja',
                        key: 'aiassistant/config'
                    }
                ]
            })
        }

        if (auth.user?.scopes?.includes(AdminScopes.SIGNALIST)) {
            items.push({
                label: 'Signalist',
                key: 'signalist-group',
                icon: <IssuesCloseOutlined/>,
                popupOffset: [-220, 45],
                children: [
                    {
                        label: 'Lista zgłoszeń',
                        key: 'signalist',
                    },
                    {
                        label: 'Konfiguracja',
                        key: 'signalist/config',
                    },
                    {
                        label: 'Dodaj',
                        key: 'signalist/new',
                    }
                ]
            })
        }

        setMenuItems(items);
        setOpenKeys(items.map((x: any) => x.key));

        function handleResize() {
            setIsMobile(getWindowDimensions().width < 1024);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [auth]);

    return (
        <div className={collapsed ? '' : 'lg:min-w-[250px] min-w-full flex flex-row justify-end pr-3 lg:pr-0'}>
            <Menu onClick={onClick}
                  overflowedIndicatorPopupClassName={'admin-nav'}
                  selectedKeys={[current]}
                  defaultOpenKeys={openKeys}
                  overflowedIndicator={<MenuOutlined/>}
                  className={'admin-nav max-w-[100px] lg:max-w-full'}
                  rootClassName={'admin-nav'}
                  mode={isMobile ? 'horizontal' : 'inline'}
                  inlineCollapsed={collapsed || isMobile}
                  items={[...menuItems!, {
                      label: collapsed ? 'Otwórz menu' : 'Zamknij menu',
                      key: 'collapse',
                      className: 'desktop',
                      icon: collapsed ? <RightCircleOutlined/> : <LeftCircleOutlined/>
                  }]}/>
        </div>)
}
