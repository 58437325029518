import locale from "antd/es/date-picker/locale/pl_PL";
import {Avatar, Calendar, DatePicker, message, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import {
    CalendarEvent,
    CalendarMonthMobileProps,
    FormatMonthDate
} from "../RetoricCalendar";
import dayjs from "dayjs";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import CalendarService from "../../../../services/CalendarService";

export default function CalendarMonthMobileView({currentDay, setCurrentDay, setActiveTab}: CalendarMonthMobileProps) {
    const [currentMonth, setCurrentMonth] = useState(dayjs());
    const [events, setEvents] = useState([] as CalendarEvent[]);

    const getEvents = (day: dayjs.Dayjs) => {
        const start = day.date(1).hour(0).minute(0).second(0).millisecond(0);
        const end = day.endOf('month').hour(23).minute(59).second(59).millisecond(999);

        CalendarService
            .getEvents(start.toDate(), end.toDate())
            .then(x => {
                setEvents(x);
            })
            .catch(x => {
                message.error("Wystąpił błąd podczas pobierania wydarzeń");;
            });
    }

    const onDayClick = (date: dayjs.Dayjs) => {
        setCurrentDay(date);
        setActiveTab('day-view');
    }

    useEffect(() => {
        getEvents(currentMonth);
    }, [currentMonth]);

    return (<div>
        <Calendar
            locale={locale}
            value={currentMonth}
            onChange={(date) => setCurrentMonth(date)}
            headerRender={({value, type, onChange, onTypeChange}) =>
                <div className="w-full flex lg:flex-row flex-col justify-between gap-2 items-center">
                    <div className="text-lg font-light">
                        {FormatMonthDate(value)}
                    </div>
                    <div className="flex flex-row gap-2">
                        <Tooltip title="Poprzedni miesiąc">
                            <button onClick={() => setCurrentMonth(currentMonth.subtract(1, 'month').date(1))}>
                                <LeftOutlined/></button>
                        </Tooltip>
                        <DatePicker
                            locale={locale}
                            value={value}
                            picker="month"
                            allowClear={false}
                            onChange={(x) => setCurrentMonth(x!)}/>
                        <Tooltip title="Następny miesiąc">
                            <button onClick={() => setCurrentMonth(currentMonth.add(1, 'month').date(1))}>
                                <RightOutlined/></button>
                        </Tooltip>
                    </div>
                </div>
            }
            cellRender={(value) => {
                const dayEvents = events.filter(x =>
                    x.date.start.isSame(value, 'day') ||
                    (x.date.isWholeDay && value.isAfter(x.date.start) && value.isBefore(x.date.end.add(1, 'day'))));

                if (dayEvents.length > 0) {
                    return <button className="flex flex-col justify-center items-center w-full h-full" onClick={() => onDayClick(value)}>
                        <Avatar size={"small"}>{dayEvents.length}</Avatar>
                    </button>
                }

                return null;
            }}
        />
    </div>)
}
