import React, {useEffect} from "react";
import {Route, Routes} from "react-router-dom";
import NavManagerMenu from "../../components/NavManagerMenu/NavManagerMenu";
import UsersList from "./Users/UsersList/UsersList";
import {LoginService} from "../../services/LoginService";
import {useAuth} from "../../extensions/Auth";
import UserHolidays from "./Holidays/UserHolidays/UserHolidays";
import HolidayCalendar from "./Holidays/HolidayCalendar/HolidayCalendar";

const routes = [
    {
        path: '/',
        element: <UsersList/>
    },
    {
        path: '/holidays',
        element: <UserHolidays/>
    },
    {
        path: '/holidays/calendar',
        element: <HolidayCalendar/>
    },
]
export default function ManagerView() {
    const auth = useAuth();

    useEffect(() => {
        LoginService
            .getUser()
            .then((x: any) => {
                if(!x.isManager) auth.navigate('/');
            })
            .catch(() => auth.navigate('/'));
    }, []);
    return (
        <div className="content">
            <div className="flex lg:flex-row flex-col self-stretch gap-4">
                <NavManagerMenu/>
                <div className="flex-1 self-stretch min-w-0">
                    <Routes>
                        {routes.map((route, i) => (
                            <Route key={i} path={route.path} element={route.element}/>
                        ))}
                    </Routes>
                </div>
            </div>
        </div>
    )
}
