import React, {useEffect} from 'react';
import './InfoDynamic.css';
import {Tabs} from "antd";
import PhoneNumbersInfopage from "../InfopageTemplates/PhoneNumbers/PhoneNumbersInfopage";
import BasicInfopage from "../InfopageTemplates/Basic/BasicInfopage";
import TrainingInfopage from "../InfopageTemplates/Training/TrainingInfopage";
import ChangelogInfopage from "../InfopageTemplates/Changelog/ChangelogInfopage";
import {Infopage, InfopageCategory} from "../../../../types/InfopageModels";
import {useParams} from "react-router-dom";

const PageTypeMapper = (infopage: Infopage) => {
    switch (infopage.type) {
        case 0:
            return <PhoneNumbersInfopage infopage={infopage}/>;
        case 1:
            return <BasicInfopage infopage={infopage}/>;
        case 2:
            return <TrainingInfopage infopage={infopage}/>;
        case 3:
            return <ChangelogInfopage infopage={infopage}/>;
        default:
            return <div/>;
    }
}

export function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

export interface InfoDynamicProps {
    category: InfopageCategory
}

interface DynamicPage {
    label: string,
    key: string,
    children: JSX.Element
}

function InfoDynamic({category}: InfoDynamicProps) {
    const [pages, setPages] = React.useState([] as DynamicPage[]);
    const [isMobile, setIsMobile] = React.useState(getWindowDimensions().width < 768);
    const [activeTab, setActiveTab] = React.useState('');
    const {catId, pageId} = useParams();

    const updateStateAndHistory = (id: string) => {
        setActiveTab(id);
        window.history.pushState({}, '', `/information/${category.id.toLowerCase()}/${id}`);
    }

    const updateActiveTab = (id: string) => {
        setActiveTab(id);
        updateStateAndHistory(id);
    }

    useEffect(() => {
        setPages(category.infopages.map(x => {
            return {
                label: x.title,
                key: x.id.toString(),
                children: PageTypeMapper(x)
            }
        }));

        if (category.infopages.length > 0) {
            let id = category.infopages[0].id.toString();

            if (pageId) {
                const exists = category.infopages.find(y => y.id.toString() === pageId);

                id = exists ? pageId : category.infopages[0].id.toString();
            }

            updateStateAndHistory(id);
        }

        function handleResize() {
            setIsMobile(getWindowDimensions().width < 768);
        }

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [category]);

    return (
        <div className="infogeneral">
            <Tabs defaultActiveKey={activeTab}
                  tabBarStyle={isMobile ? {} : {width: 300}} items={pages}
                  activeKey={activeTab}
                  onChange={updateActiveTab}
                  tabPosition={isMobile ? 'top' : 'left'}/>
        </div>
    );
}

export default InfoDynamic;
