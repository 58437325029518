import React, {useEffect, useState} from 'react';
import './SignalistNewReport.css';
import {useAuth} from "../../../../extensions/Auth";
import {AdminScopes} from "../../../../types/Scopes";
import {DatePicker, Form, Input, notification, Radio, Select, Tooltip} from "antd";
import RetoricCard from "../../../../designsystems/RetoricCard/RetoricCard";
import RetoricButton from "../../../../designsystems/RetoricButton/RetoricButton";
import RetoricBreadcrumbs from "../../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../../extensions/Locations";
import {AuthorType, ReportOperatorRequest, Reports} from "../../../../types/Signalist";
import SignalistService from "../../../../services/SignalistService";
import TextArea from "antd/es/input/TextArea";
import {Attachment} from "../../../../types/StaticAsset";
import {RedoOutlined} from "@ant-design/icons";
import pl from "antd/es/date-picker/locale/pl_PL";
import ReportConfirmationModal from "./ReportConfirmationModal/ReportConfirmationModal";
import Infobox from "../../../../designsystems/Infobox/Infobox";
import SignalistAttachmentsDragger
    from "../SignalistAttachmentsDragger/SignalistAttachmentsDragger";

const AuthorMapper = (author: AuthorType) => {
    if (author === AuthorType.User) return 'użytkownika';

    return 'operatora';
}

function SignalistNewReport() {
    const auth = useAuth();
    const [api, contextHolder] = notification.useNotification();
    const [categories, setCategories] = useState([] as Reports[]);
    const [types, setTypes] = useState([] as Reports[]);
    const [attachments, setAttachments] = useState([] as Attachment[]);
    const [fileList, setFileList] = useState([] as any[]);
    const [reportMode, setReportMode] = useState(0);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [confirmModalPayload, setConfirmModalPayload] = useState({} as ReportOperatorRequest);
    const [form] = Form.useForm();

    const updateReportMode = (mode: number) => {
        setReportMode(mode);
        form.setFieldValue('reportingMode', mode);
        form.setFieldValue('name', '');
    }

    const addReport = (values: any) => {
        const request: ReportOperatorRequest = {
            ...values,
            createdAt: values.createdAt?.toDate()?.toISOString(),
            attachments: attachments.map(x => x.asset)
        }

        SignalistService.addReport(request)
            .then(x => {
                api.success({
                    message: 'Dodano zgłoszenie',
                    description: 'Nowe zgłoszenie zostało dodane do systemu',
                    placement: 'top'
                })
                setConfirmModalPayload(request);
                setConfirmModalOpen(true);
                form.resetFields();
            })
            .catch(x => {
                api.error({
                    message: 'Wystąpił błąd',
                    description: 'Nie udało się dodać zgłoszenia',
                    placement: 'top'
                })
            });
    }

    const submitReport = () => {
        form.validateFields().then((values) => {
            addReport(values)
        });
    }

    const newId = () => {
        SignalistService.getReportId().then((x) => {
            form.setFieldValue('id', x.replaceAll('"', ''));
        });
    }

    const newPassword = () => {
        SignalistService.getPassword().then((x) => {
            form.setFieldValue('password', x);
            form.setFieldValue('passwordConfirmation', x);
        });
    }

    useEffect(() => {
        auth.verifyAuth(AdminScopes.SIGNALIST);

        SignalistService.getReportCategories()
            .then(x => {
                setCategories(x);
            })
            .catch(err => {
                api.error({
                    message: 'Wystąpił błąd',
                    description: 'Nie udało się pobrać kategorii zgłoszeń',
                    placement: 'top'
                })
            })
            .then(x => {
                SignalistService.getTypes()
                    .then(x => {
                        setTypes(x);
                    })
                    .catch(err => {
                        api.error({
                            message: 'Wystąpił błąd',
                            description: 'Nie udało się pobrać typów osób zgłaszających',
                            placement: 'top'
                        })
                    })
            });

        form.setFieldValue('reportingMode', reportMode);

        return () => {};
    }, []);

    return (
        <div>
            {contextHolder}
            <RetoricBreadcrumbs locations={Locations['admin/signalist/new']}>Dodaj zgłoszenie</RetoricBreadcrumbs>
            <RetoricCard className="bg-offwhite mt-4 lg:px-8 flex flex-col gap-4">
                <h2 className="text-xl tracking-wider font-light">Podaj szczegóły zgłoszenia</h2>
                <Infobox title={'Zgłoszenie operatora'}>
                    <div className="text-slate-600 infobox flex flex-col gap-1 text-justify">
                        <p className="mb-1 font-medium">Jeżeli zgłoszenie dotarło do Ciebie innym środkiem komunikacji,
                            poza systemem Signalist, możesz wprowadziċ je do systemu za pomocą tego formularza.</p>
                    </div>
                </Infobox>
                <Form
                    onFinish={submitReport}
                    form={form}
                    layout="vertical">
                    <Form.Item label="Identyfikator zgłoszenia" required name="id"
                               rules={[{required: true, message: "Identyfikator zgłoszenia jest wymagany!"}, {
                                   pattern: new RegExp('^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$'),
                                   message: 'Identyfikator ma nieodpowiedni format, skorzystaj z generatora by wygenerować nowy identyfikator!'
                               }]}>
                        <Input className="rounded-none" placeholder="7eef403d-b1e9-4358-..." size="large"
                               suffix={<Tooltip title="Wygeneruj nowy identyfikator">
                                   <RedoOutlined className="hover:text-brandColor-500" onClick={newId}/>
                               </Tooltip>}
                        />
                    </Form.Item>
                    <Form.Item label="Data wpłynięcia" name="createdAt">
                        <DatePicker locale={pl} className="rounded-none w-full" showTime popupClassName={'date-picker'}
                                    placeholder="Data wpłynięcia zgłoszenia..." size="large"/>
                    </Form.Item>
                    <Form.Item label="Tytuł zgłoszenia" required name="title"
                               rules={[{required: true, message: "Tytuł zgłoszenia jest wymagany!"}]}>
                        <Input className="rounded-none" placeholder="Tytuł zgłoszenia..." size="large"/>
                    </Form.Item>
                    <Form.Item label="Tryb zgłoszenia" required name="reportingMode"
                               tooltip={"Tryb zgłoszenia określa, czy zgłoszenie jest anonimowe, czy imienne. W przypadku zgłoszenia imiennego, dane zgłaszającego będą widoczne dla operatora."}
                               rules={[{required: true, message: "Tryb zgłoszenia jest wymagany!"}]}>
                        <Radio.Group size="large" value={reportMode} onChange={(e) => updateReportMode(e.target.value)}>
                            <Radio value={0}>Anonimowe</Radio>
                            <Radio value={1}>Imienne</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {reportMode === 1 &&
                        <Form.Item label={"Imię i nazwisko"} required name="name"
                                   rules={[{required: true, message: "Imię i nazwisko są wymagane!"}]}>
                            <Input className="rounded-none" placeholder="Imię i nazwisko..." size="large"/>
                        </Form.Item>}
                    <Form.Item label="Kategoria" required name="categoryId"
                               rules={[{required: true, message: "Kategoria jest wymagana!"}]}>
                        <Select placeholder={"Wybierz kategorię"} size="large">
                            {categories.map((x) => <Select.Option value={x.id}
                                                                  key={x.id}>{x.title}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Status osoby zgłaszającej" required name="reportingStatusId"
                               rules={[{required: true, message: "Status osoby zgłaszającej jest wymagany!"}]}>
                        <Select placeholder={"Wybierz status"} size="large">
                            {types.map((x) => <Select.Option value={x.id}
                                                                         key={x.id}>{x.title}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item label={<span>Hasło
                        <Tooltip title="Wygeneruj losowe hasło">
                                <RedoOutlined className="hover:text-brandColor-600 ml-2" onClick={newPassword}/>
                        </Tooltip></span>} required name="password"
                               rules={[{required: true, message: "Hasło jest wymagane!"}]}>
                        <Input.Password className="rounded-none" placeholder="Hasło..." size="large"/>
                    </Form.Item>
                    <Form.Item label="Powtórz hasło" required name="passwordConfirmation"
                               rules={[{required: true, message: "Powtórz hasło"}]}>
                        <Input.Password className="rounded-none" placeholder="Powtórz hasło..." size="large"/>
                    </Form.Item>
                    <Form.Item label="Treść zgłoszenia" required name="description"
                               rules={[{required: true, message: "Treść zgłoszenia jest wymagana!"}]}>
                        <TextArea rows={6} placeholder={"Treść zgłoszenia..."}></TextArea>
                    </Form.Item>
                    <Form.Item>
                        <SignalistAttachmentsDragger attachments={attachments} setAttachments={setAttachments}
                                            fileList={fileList} setFileList={setFileList}/>
                    </Form.Item>
                    <Form.Item className="flex justify-center">
                        <RetoricButton htmlType="submit"
                                       className="lg:min-w-[200px] lg:self-center self-stretch min-w-full mt-4">Utwórz
                            zgłoszenie</RetoricButton>
                    </Form.Item>
                </Form>
            </RetoricCard>
            <ReportConfirmationModal open={confirmModalOpen} setOpen={setConfirmModalOpen} payload={confirmModalPayload}/>
        </div>
    );
}

export default SignalistNewReport;
